/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import firebase from "firebase";

import { connect } from "react-redux";

import ClipLoader from "react-spinners/ClipLoader";

function setGoogleUser(user, license) {

  let googleUser = {
    displayName: user.displayName,
    email: user.email,
    uid: user.uid,
    photoUrl: user.photoURL,
    license: license
  }

  var storageUser = JSON.stringify(googleUser);
 
  localStorage.setItem('user', storageUser);

  return storageUser;
}

const Admin = (props) => {

  const mainContent = React.useRef(null);
  const location = useLocation();

  const [user, setUser] = useState(null)

  const [authWasListened, setAuthWasListened] = useState(false);

  //let match = useRouteMatch();

  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

  const parseMaximumEnterprice = (stripeRole) => {
    //If enterprice license, the stripe role will contain enterprice_50
    try {
      var stripeLicense = stripeRole.split("_")[1];
      return stripeLicense;
    } catch (error) {
      alert("Something went wrong when parsing your license. Please contact the Apptopdf support.")
      return 0;
    }
  }

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
  }, [location]);

  React.useEffect(async () => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(authUser => {
      if (authUser) {
        firebase.auth().currentUser.getIdToken(true).then(idToken => {
          firebase.auth().currentUser.getIdTokenResult().then(decodedToken => {
            var stripeRole = decodedToken.claims.stripeRole;

            var license = {
              isPremiumUser: false,
              licenseInformation: "Basic",
              maximumFileUploadSize: 300,
              maximumAccountInvites: 0
            }

            if (stripeRole) {
              if (stripeRole === "premiumLicense") {
                license.isPremiumUser = true;
                license.licenseInformation = "Premium";
                license.maximumFileUploadSize = 2048;
                license.maximumAccountInvites = 0

              } else if (stripeRole === "businessLicense") {
                license.isPremiumUser = true;
                license.licenseInformation = "Business";
                license.maximumFileUploadSize = 2048;
                license.maximumAccountInvites = 20

              } else {
                //Enterpirce license?
                var enterpriceLicense = parseMaximumEnterprice(stripeRole);

                if (enterpriceLicense) {

                  if (enterpriceLicense[0] === "enterpriceLicense") {
                    license.isPremiumUser = true;
                    license.licenseInformation = "Enterprice: " + enterpriceLicense[1] + " users";
                    license.maximumFileUploadSize = 2048;
                    license.maximumAccountInvites = enterpriceLicense[1];
                  }
                }

              }

            }

            setUser(setGoogleUser(authUser, license));
            setAuthWasListened(true);
          })
        })
      }
      else {
        localStorage.setItem('user', null);
        setUser(null);
        setAuthWasListened(true);
      }

    })

    return unregisterAuthObserver;

  }, []);



  const getRoutes = (routes) => {

    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  /*if (!props.authState.loggedIn) {
    return <Redirect to="/auth/login" />;
  }*/

  if (!authWasListened) {
    return (
      <div style={style}>
        <ClipLoader color={"#123abc"} loading={"Authenticating"} />
      </div>
    );
  }
  if (authWasListened && user) {
    return (
      <>
        <Sidebar
          {...props}
          routes={routes}
          logo={{
            innerLink: "/admin/templates",
            imgSrc: require("../assets/img/brand/apptopdflogo.svg").default,
            imgAlt: "...",
          }}
        />
        <div className="main-content bg-secondary" ref={mainContent}>
          <AdminNavbar
            {...props}
            brandText={getBrandText(props.location.pathname)}
            user={JSON.parse(user)}
          />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/templates" />
          </Switch>
          <Container fluid className="bg-secondary">
            <AdminFooter />
          </Container>
        </div>
      </>)
  }

  return <Redirect to="/auth/login" />;


  /*return (
    !user ?
      <Redirect to="/auth/login" />
      :
      <>
        <Sidebar
          {...props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/brand/argon-react.png").default,
            imgAlt: "...",
          }}
        />
        <div className="main-content" ref={mainContent}>
          <AdminNavbar
            {...props}
            brandText={getBrandText(props.location.pathname)}
          />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/index" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
  );*/
};

//export default Admin;

const mapStateToProps = state => ({
  ...state
});
export default connect(
  mapStateToProps,
  {}
)(Admin);
