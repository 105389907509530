export function getCurrentVariableSpaceFromStep(steps) {


    /**
     * Returns the name of the variable.
     * @param {string} variablePropName - The name of the variable.
     * @returns {string} The name of the variable or an empty string if it's undefined.
     */
    let getVariableName = (variablePropName) => {
        if (variablePropName === undefined) {
            return "";
        }

        return variablePropName;
    }

    let getElements = (elements) => {
        if (elements === undefined) {
            return [];
        }

        var flattenedElements = elements.map((element) => {
            return element.element;
        })

        return mapToStepElements(flattenedElements);
    }

    /**
     * Maps an array of table columns to a new array of objects with specific properties.
     * @param {Array} columns - The array of table columns to map.
     * @returns {Array} - The new array of objects with specific properties.
     */
    let mapTableColumns = (columns) => {
        var mappedColumns = columns.map((column) => {
            return {
                "ColumnName": column.columnName,
                "InputType": column.inputType,
                "Variable": getVariableName(column.variable),
                "ScriptValue": getVariableName(column.scriptValue)
            }
        });

        return mappedColumns;
    }

    /**
     * Maps an array of elements to an array of mapped elements with additional properties.
     * @param {Array} elements - The array of elements to map.
     * @returns {Array} - The array of mapped elements.
     */
    let mapToStepElements = (elements) => {
        //Sort the elements to get an index based array

        if (elements.length > 1) {
            //If the is only one element, no posY will be set

            elements.sort((a, b) => {
                return a.posY - b.posY;
            });
        }

        var mappedElements = elements.map((element, index) => {

            var mappedElement = {
                "ElementIndex": index,
                "ElementType": element.elementType,
                "InputType": element.inputType,
                "VariableOutputName": element.variableOutputName
            }

            if(element.elementType === "DropDown") {
                mappedElement.DataSource = getVariableName(element.variable);
            }

            if (element.elementType === "TableView") {
                mappedElement.DataSource = getVariableName(element.variable);
                mappedElement.Columns = mapTableColumns(element.columns);
            }

            if (element.elementType === "ListControl") {
                mappedElement.Elements = mapToStepElements(element.elements);
                mappedElement.Variable = getVariableName(element.variable);
            }

            return mappedElement;
        });

        return mappedElements;
    }

    /**
     * Maps a user step to a step object with the required format.
     *
     * @param {Object} userStep - The user step object to map.
     * @param {number} userStep.index - The index of the user step.
     * @param {Array} userStep.controls - The controls of the user step.
     * @returns {Object} - The mapped step object.
     */
    let mapToUserStep = (userStep) => {
        return {
            "StepIndex": userStep.index,
            "StepType": "UserStep",
            "Elements": mapToStepElements(userStep.controls)
        }
    }

    /**
     * Maps an assignment step to a step object with the required properties.
     * @param {Object} assignmentStep - The assignment step to map.
     * @param {number} assignmentStep.index - The index of the assignment step.
     * @param {string} assignmentStep.value - The value of the assignment step.
     * @returns {Object} - The mapped step object.
     */
    let mapToAssignmentStep = (assignmentStep) => {
        return {
            "StepIndex": assignmentStep.index,
            "StepType": "AssignmentStep",
            "ScriptValue": assignmentStep.value
        }
    }

    /**
     * Maps a step object to a postable template object.
     * @param {Object} step - The step object to map.
     * @returns {Object} - The postable template object.
     */
    let mapToPostableTemplate = (step) => {

        var stepToPost = {};

        if (step.type == "assignmentStep") {
            stepToPost = mapToAssignmentStep(step);
        } else if (step.type == "userStep") {
            stepToPost = mapToUserStep(step);
        }

        return stepToPost;

    }

    /**
     * Array of postable templates generated from the input steps array.
     * @type {Array}
     */
    var stepsToPost = steps.map((step) => {
        return mapToPostableTemplate(step);
    });

    /**
     * Options for making a POST request with JSON data.
     * @typedef {Object} RequestOptions
     * @property {string} method - The HTTP method to use for the request.
     * @property {Object} headers - The headers to include in the request.
     * @property {string} headers.Content-Type - The content type of the request body.
     * @property {string} body - The JSON-encoded request body.
     */

    /**
     * The options for making a POST request with JSON data.
     * @type {RequestOptions}
     */
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stepsToPost)
    };

    /**
     * The URL for the development environment's variable space API.
     * @type {string}
     */
    var devUrl ='http://localhost:7071/api/GetVariableSpace';

    /**
     * The production URL for retrieving variable space data.
     * @type {string}
     */
    var prodUrl = 'https://apptopdf-scriptingfunction.azurewebsites.net/api/GetVariableSpace';

    return fetch(prodUrl, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
        }
        throw new Error('Something went wrong');
    })
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            alert("Failed to load variable space. We are sorry for the inconvenience. Please contact AppToPDF.")
            return { Variables: "Error loading variables!" };
        });

}