import { FormGroup, Label, Input } from 'reactstrap';

const getVariableOutputName = (element) => {
    if (element.variableOutputName != undefined && element.variableOutputName !== "") {
      return (
        <h5>Variable output name: <strong>{element.variableOutputName}</strong></h5>
      )
    }
  
    return null;
  }

const getTextAlignmentClass = (element) => {

    if (element.alignment === "Center")
        return "text-center";
    if (element.alignment === "Right")
        return "text-right";

    return "text-left";
}

const CheckBoxInputElement = ({element}) => {
    return (
        <div className={getTextAlignmentClass(element)}>
            {getVariableOutputName(element)}
            <FormGroup check style={{paddingRight: "25px",paddingLeft: "25px"}}>
              <Label style={{color: "black"}} check>
                <Input disabled={true} readOnly={true} checked={element.defaultValue} type="checkbox" id={element.id} />{' '}
                {element.prompt}
              </Label>
            </FormGroup>
        </div>
    );
}

export default CheckBoxInputElement