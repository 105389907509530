import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody
} from "reactstrap";

import FrostScriptEditor from "../steps/FrostScriptEditor";

const LabeledTextEditor = ({ labeledTextElement, updateElement, variableSpace }) => {
    return (
        <>
            <FormGroup>
                <Label for="labelEditor">Label</Label>
                <FrostScriptEditor
                    id={"lableEditor"}
                    variableSpace={variableSpace.getVariables()}
                    boundProperty={labeledTextElement.label}
                    onChange={e => updateElement("label", e)}
                    maxLines={3}
                    includeMethodSnippets={false}
                />

            </FormGroup >
            <FormGroup>
                <Label for="staticTextEditor">Text</Label>
                <FrostScriptEditor
                    id={"staticTextEditor"}
                    variableSpace={variableSpace.getVariables()}
                    boundProperty={labeledTextElement.textValue}
                    onChange={e => updateElement("textValue", e)}
                    maxLines={15}
                    includeMethodSnippets={false}
                />

            </FormGroup >
            <FormGroup>

                <Label for="alignment">Text Alignment</Label>
                <Input
                    type="select"
                    name="alignment"
                    id="alignment"
                    value={labeledTextElement.alignment}
                    onChange={e => updateElement("alignment", e.target.value)}>
                    <option value="Left">Left</option>
                    <option value="Right">Right</option>
                    <option value="Center">Center</option>
                </Input>
            </FormGroup >

            <FormGroup>
                <Label for="fontSize">Font Size: <b><i>Standard 9, Title 18</i></b></Label>
                <Input
                    type="select"
                    name="fontSize"
                    id="fontSize"
                    value={labeledTextElement.fontSize}
                    onChange={e => updateElement("fontSize", parseInt(e.target.value))}>
                    <option value={7}>7</option>
                    <option value={9}>9</option>
                    <option value={12}>12</option>
                    <option value={16}>16</option>
                    <option value={20}>20</option>
                    <option value={24}>24</option>
                </Input>
            </FormGroup >
            <FormGroup>
                <Label for="fontStyle">Font Style</Label>
                <Input
                    type="select"
                    name="fontStyle"
                    id="fontStyle"
                    value={labeledTextElement.fontStyle}
                    onChange={e => updateElement("fontStyle", e.target.value)}>
                    <option value="normal">Normal</option>
                    <option value="Bold">Bold</option>
                    <option value="Italic">Italic</option>
                </Input>
            </FormGroup >
            <FormGroup>
                <Label for="textColor">Text Color</Label>
                <Input
                    type="color"
                    name="textColor"
                    value={labeledTextElement.textColor}
                    onChange={e => updateElement("textColor", e.target.value)}
                    id="textColor"
                    placeholder="color placeholder" />
            </FormGroup >
        </>

    );
}

export default LabeledTextEditor;