export default class pdfFieldMapping {
    /**
     * Creates a new instance of PdfFieldMapping.
     * @constructor
     * @param {number} pageNumber - The page number of the PDF field.
     * @param {string} fieldName - The name of the PDF field.
     * @param {string} variableName - The name of the variable.
     * @param {string} variableType - The type of the variable.
     * @param {boolean} manuallyAssignedValue - Indicates if the variable is set manually.
     * @param {string} selectedVariable - The selected variable from the mapping view.
     * @param {string} selectedValue - The value of the selected variable.
     */
    constructor(pageNumber, fieldName, variableName, variableType, manuallyAssignedValue, selectedVariable, selectedValue) {
        this.pageNumber = pageNumber
        this.fieldName = fieldName;
        this.variableName = variableName;

        // This is for filtering what kind of types should be visible
        this.variableType = variableType;

        // Is this variable set manually (just a value)
        this.manuallyAssignedValue = manuallyAssignedValue;

        // This is the variable selected from the mapping view
        this.selectedVariable = selectedVariable;

        // This is used if "manuallyAssignedValue" is true. It´s the value on the field.
        this.selectedValue = selectedValue;
      }
 }
