// reactstrap components
import React, { useState, useEffect } from "react";

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; //Example style, you can use another

import {
    Button,
    FormGroup,
    Modal,
    ModalBody,
    Label,
    Input
} from "reactstrap";

const ImportTemplateModal = ({ isOpen, toggle, addTemplate }) => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [importedTemplate, setImportedTemplate] = useState("");
    const [choosenTemplateName, setChoosenTemplateName] = useState("");


    let validateTemplate = (template) => {
        if (!template.hasOwnProperty("templateName"))
            return false;


        if (!template.hasOwnProperty("createdAt"))
            return false;


        if (!template.hasOwnProperty("lastUpdatedAt"))
            return false;


        if (!template.hasOwnProperty("mainGrid"))
            return false;


        if (!template.mainGrid.hasOwnProperty("elements"))
            return false;

        if (!template.hasOwnProperty("pageFooter"))
            return false;


        if (!template.pageFooter.hasOwnProperty("elements"))
            return false;

        if (!template.hasOwnProperty("pageHeader"))
            return false;

        if (!template.pageHeader.hasOwnProperty("elements"))
            return false;

        return true;
    }

    let selectFile = async (e) => {

        if (e.target.files.length === 0) {
            //This only happens if you select a file, opens the selector again and clicks cancel.
            setImportedTemplate("");
            return;
        }

        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {

            try {
                var template = JSON.parse(e.target.result);

                if (validateTemplate(template)) {
                    setImportedTemplate(template)
                } else {
                    alert("The file you imported is not matching our templates structures!");
                    setSelectedFile(null);
                    setImportedTemplate("");
                }
            } catch (error) {
                alert("The file you imported is corrupt!");
                setSelectedFile(null);
                setImportedTemplate("");
            }

        };
    }

    let save = () => {

        if (!validateTemplate(importedTemplate)) {
            alert("Invalid template");
        }
        var originalName = importedTemplate.templateName;

        if (choosenTemplateName !== "") {
            importedTemplate.templateName = choosenTemplateName;
        }

        addTemplate(importedTemplate, originalName);
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={isOpen}
            toggle={toggle}>
            <div className=" modal-header">
                <h5 className=" modal-title" id="modal-title-default">
                    IMPORT TEMPLATE
                </h5>
                <button
                    aria-label="Close"
                    className=" close"
                    onClick={() => toggle()}
                    type="button"
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            {
                <ModalBody>
                    <FormGroup>
                        <Label>CHOOSE NAME FOR IMPORTED TEMPLATE</Label>
                        <Input
                            name="templateName"
                            type={"text"}
                            value={choosenTemplateName}
                            onChange={(e) => setChoosenTemplateName(e.target.value)}
                        />
                    </FormGroup >

                    <FormGroup>
                        <Label>CHOOSE TEMPLATE FILE</Label>
                        <input
                            name="chooseFile"
                            type="file"
                            accept=".apptopdf"
                            value={selectedFile}
                            onChange={(e) => selectFile(e)}
                        />
                    </FormGroup >

                </ModalBody >
            }
            <div className=" modal-footer">
                <Button
                    color="primary"
                    onClick={() => save()}
                    disabled={importedTemplate === ""}
                >
                    IMPORT TEMPLATE
                </Button>

                <Button
                    className=" ml-auto"
                    color="link"
                    onClick={() => toggle()}
                    type="button"
                >
                    CANCEL
                </Button>
            </div>
        </Modal>
    );
}

export default ImportTemplateModal;