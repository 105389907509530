import { FormGroup, Label, Input } from 'reactstrap';

const getTextAlignmentClass = (element) => {

  if (element.alignment === "Center")
      return "text-center";
  if (element.alignment === "Right")
      return "text-right";

  return "text-left";
}

const getIncludePromptInPdf = (element) => {
  if (element.includePromptInPdf) {
    return (
      <h5>Include prompt in PDf: <strong>Yes</strong></h5>
    )
  }
  return (
    <h5>Include prompt in PDf: <strong>No</strong></h5>
  )
}

const ScriptElement = ({element}) => {
    return (
        <div className={getTextAlignmentClass(element)}>
        <FormGroup>
          <h5>Prompt: <strong>{element.textValue}</strong></h5>
          {getIncludePromptInPdf(element)}
          <Input type="textarea" name="text" readOnly="readOnly" value={element.scriptValue} style={{color: 'black'}} placeholder="Script EG: {table1 + table2} kr" />
        </FormGroup>
        </div>
    );
}

export default ScriptElement