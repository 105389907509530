import firebaseConfig from "firebaseConfig";
import firebase from "firebase";

export const resetPasswordAction = (email) => async dispatch => {
  firebaseConfig
    .auth()
    .sendPasswordResetEmail(email)
    .then(function (result) {
        dispatch({ type: "login", payload: false });
    })
    .catch(function (error) {
      alert(error);
    });
};
