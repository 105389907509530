import React, { useState, useEffect } from 'react';

import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody,
    Table
} from "reactstrap";

import VariableDropDownComponent from "./VariableDropDownComponent";

const DropdownEditor = ({ dropDownElement, updateElement, variableSpace }) => {

    let addRow = () => {
        var copiedDropDownItems = [...dropDownElement.values];

        var nameIndex = (copiedDropDownItems.length + 1);

        copiedDropDownItems.push({ largeText: "Option " + nameIndex, smallText: "Select option " + nameIndex, value: nameIndex });

        updateElement("values", copiedDropDownItems);
    }

    let getDropDownRows = (element) => {

        if (element.variable === undefined || element.variable === "") {
            return (
                <>
                    <Table >
                        <thead>
                            <tr>
                                <th>Index</th>
                                <th>Large text</th>
                                <th>Small text</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {mapDropDownRows(element.values)}
                        </tbody>
                    </Table>
                    <button onClick={e => addRow()} className="btn btn-primary">Add Row</button>
                </>
            );
        }

        return (
            <h5>WILL BE RESOLVED IN RUNTIME</h5>
        )
    }

    let updateDropDownRow = (propertyName, index, value) => {
        var copiedDropDownItems = [...dropDownElement.values];

        copiedDropDownItems[index][propertyName] = value;

        updateElement("values", copiedDropDownItems);
    }

    let deleteRow = (index) => {

        if (dropDownElement.values.length === 1) {
            alert("You need to have at least one row as dropdown source!");
            return;
        }

        var copiedDropDownItems = [...dropDownElement.values];

        copiedDropDownItems.splice(index, 1);

        updateElement("values", copiedDropDownItems);
    }

    let mapDropDownRows = (dropDownRows) => {

        return dropDownRows.map((dropDownRow, index) => {
            return (
                <tr>
                    <th scope="row">{index + 1}</th>
                    <td>
                        <Input
                            placeholder="Text"
                            type="text"
                            value={dropDownRow.largeText}
                            id={"largeText" + index}
                            onChange={e => updateDropDownRow("largeText", index, e.target.value)} />
                    </td>
                    <td>
                        <Input
                            placeholder="Text"
                            type="text"
                            value={dropDownRow.smallText}
                            id={"smallText" + index}
                            onChange={e => updateDropDownRow("smallText", index, e.target.value)} />
                    </td>
                    <td>
                        <Input
                            placeholder="0"
                            type="number"
                            value={dropDownRow.value}
                            id={"variable" + index}
                            onChange={e => updateDropDownRow("value", index, e.target.value)} />
                    </td>
                    <td> <button onClick={e => deleteRow(index)} className="btn btn-warning">x</button></td>
                </tr>
            )
        })
    }

    return (
        <>
            <FormGroup>
                <Label for="titleEditor">Prompt</Label>
                <Input
                    placeholder="Text"
                    type="text"
                    value={dropDownElement.prompt}
                    id="titleEditor"
                    onChange={e => updateElement("prompt", e.target.value)} />
            </FormGroup >
            <FormGroup>
                <Label for="dropDown">Variable</Label>
                <VariableDropDownComponent
                    selectedVariable={dropDownElement.variable}
                    avaliableTableVariables={variableSpace.getColumnSources()}
                    onSelectedItem={e => updateElement("variable", e)}
                    dropDownSource={true}
                />
            </FormGroup >
            <FormGroup>
                <Label for="alignment">Text Alignment</Label>
                <Input
                    type="select"
                    name="alignment"
                    id="alignment"
                    value={dropDownElement.alignment}
                    onChange={e => updateElement("alignment", e.target.value)}>
                    <option value="Left">Left</option>
                    <option value="Right">Right</option>
                    <option value="Center">Center</option>
                </Input>
            </FormGroup >
            <FormGroup>
                <Label for="variableEditor">Variable output name (must be unique)</Label>
                <Input
                    placeholder="Checkbox1"
                    type="text"
                    value={dropDownElement.variableOutputName}
                    name="variableEditor"
                    id="variableEditor"
                    onChange={e => updateElement("variableOutputName", e.target.value)} />
            </FormGroup >
            <FormGroup>
                <Label for="source">Drop down items</Label>

                {getDropDownRows(dropDownElement)}

            </FormGroup >
        </>

    );
}

export default DropdownEditor;