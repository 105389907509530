const getTextAlignmentClass = (element) => {

    if (element.alignment === "Center")
        return "text-center";
    if (element.alignment === "Right")
        return "text-right";

    return "text-left";
}

const TitleElement = ({element}) => {
    return (
        <div className={getTextAlignmentClass(element)}>
            <h2 className="editable-label clickable-label">{element.textValue}</h2>
        </div>
    );
}

export default TitleElement