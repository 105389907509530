import { FormGroup, Label, Input } from 'reactstrap';

const getTextAlignmentClass = (element) => {

  if (element.alignment === "Center")
      return "text-center";
  if (element.alignment === "Right")
      return "text-right";

  return "text-left";
}

const getVariableOutputName = (element) => {
  if (element.variableOutputName != undefined && element.variableOutputName !== "") {
    return (
      <h5>Variable output name: <strong>{element.variableOutputName}</strong></h5>
    )
  }

  return null;
}


const SignatureInputElement = ({element}) => {
    return (
        <div className={getTextAlignmentClass(element)}>
        {getVariableOutputName(element)}
        <FormGroup>
          <h5>Prompt: <strong>{element.textValue}</strong></h5>
          <Input type="textarea" name="text" readOnly="readOnly" style={{color: 'black'}} placeholder="This will be signed by the app user" />
        </FormGroup>
        </div>
    );
}

export default SignatureInputElement