import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  Table,
  Badge,
} from "reactstrap";

import ClipLoader from "react-spinners/ClipLoader";
import firebaseApp from "firebaseConfig";
import firebase from "firebase";
import { useHistory, useRouteMatch, Route, Switch } from 'react-router-dom';


function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const InvitedUsers = ({ user, myAccountName, maximumUsers, isEnterpriceLicense }) => {

  const [userToAdd, setUserToAdd] = useState("");
  const [userToAddInvalidEmailMessage, setUerToAddInvalidEmailMessage] = useState("");

  const [showAddUserButton, setShowAddUserButton] = useState(false);

  const [downloadingCompleted, setdownloadingCompleted] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState([]);

  const history = useHistory();

  useEffect(() => {

    const unregisterFirebaseQuerist = firebaseApp.firestore().collection("AccountInvites").where("sharedAccountId", "==", user.uid)
      .get()
      .then((querySnapshot) => {

        const data = querySnapshot.docs.map(
          doc => {
            let invite = {
              data: doc.data(),
              id: doc.id
            }
            return invite
          });;

        //console.log("Outgoing invites: " + JSON.stringify(data));

        setInvitedUsers(data);

        setdownloadingCompleted(true);

      });

    return unregisterFirebaseQuerist;

  }, []);

  const inviteUser = (email) => {
    //TODO: Send invite to firebase
    setdownloadingCompleted(false);

    var inviteTime = new firebase.firestore.Timestamp.fromDate(new Date());

    const accountInvite = {
      sharedAccountId: user.uid,
      sharedAccountDisplayName: myAccountName,
      invitedEmailAddress: email,
      createdAt: inviteTime,
      status: "pending"
    }

    firebaseApp.firestore().collection("AccountInvites").add(accountInvite)
      .then((docRef) => {

        //console.log("Document written with ID: ", docRef.id);

        let invite = {
          data: accountInvite,
          id: docRef.id
        }

        invitedUsers.push(invite);
        setInvitedUsers(invitedUsers);

        setdownloadingCompleted(true);

      }).catch((error) => {

        setdownloadingCompleted(true);

        alert(error);

      });

    setUserToAdd("");
    setShowAddUserButton(false);
  }

  const reInviteUser = (index) => {
    //TODO: Send invite to firebase
    setdownloadingCompleted(false);


    var invite = invitedUsers[index].data;
    var inviteTime = new firebase.firestore.Timestamp.fromDate(new Date());
    invite.createdAt = inviteTime;
    invite.status = "pending";

    firebaseApp.firestore().collection("AccountInvites").doc(invitedUsers[index].id).set(invite).then(() => {

      invitedUsers[index].data = invite;

      setInvitedUsers(invitedUsers)

      setdownloadingCompleted(true);

    }).catch((error) => {
      alert("Error updating invite: " + error);
    });

    setUserToAdd("");
    setShowAddUserButton(false);
  }

  const removeAccess = (index, email, prompt, invitationId) => {

    if (prompt)
      var remove = window.confirm("Are you sure you want to remove access for: " + email + "?");

    if (remove) {

      setdownloadingCompleted(false);

      firebaseApp.firestore().collection("AccountInvites").doc(invitationId).delete().then(() => {

        invitedUsers.splice(index, 1);

        setInvitedUsers(invitedUsers)

        setdownloadingCompleted(true);

      }).catch((error) => {
        alert("Error removing document: " + error);
      });
    }
  }

  const sendInvite = () => {
    inviteUser(userToAdd);
  }

  const getStatus = (status, email, index, invitationId) => {

    var colorClass = "";
    var actionButtonText = "";
    var actionButtonClass = "";
    var actionButtonFunction = "";
    var removeDecilinedInvite = "";

    if (status === "approved") {

      colorClass = "bg-success";
      actionButtonText = "Remove access";
      actionButtonClass = "btn-secondary float-right";
      actionButtonFunction = removeAccess;

    } else if (status === "pending") {

      colorClass = "bg-info";
      actionButtonText = "Remove invite";
      actionButtonClass = "btn-secondary float-right";
      actionButtonFunction = removeAccess;

    } else if (status === "declined") {

      return (
        <>
          <td>
            <Badge color="" className="badge-dot mr-4">
              <i className={"bg-warning"} />
              {status}
            </Badge>
          </td>
          <td>
            <Button
              className="btn-secondary"
              href="#"
              onClick={(e) => removeAccess(index, email, true, invitationId)}
              size="sm"
            >
              Remove Invite
            </Button>
            <Button
              className={"btn btn-primary"}
              href="#"
              onClick={(e) => reInviteUser(index)}
              size="sm"
            >
              {"Resend invite"}
            </Button>

          </td>
          <td className="text-right"></td>

        </>
      )

    } else {
      return "Something has gone wrong with this invite, please contact our support and we will fix ASAP";
    }


    if (!downloadingCompleted) {
      return (
        <Card className="bg-white shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="12">
                <h3 className="mb-0">INVITED USERS</h3>
                <small>Users invited to your account. They can se and execute your templates but not edit them</small>

              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <ClipLoader color={"#123abc"} loading={"Authenticating"} />
          </CardBody>
        </Card>
      );
    }

    return (
      <>
        <td>
          <Badge color="" className="badge-dot mr-4">
            <i className={colorClass} />
            {status}
          </Badge>
        </td>
        <td className="text-right">
          {removeDecilinedInvite}
          <Button
            className={actionButtonClass}
            href="#"
            onClick={(e) => actionButtonFunction(index, email, true, invitationId)}
            size="sm"
          >
            {actionButtonText}
          </Button>

        </td>

      </>
    )
  }

  const getInviteUserButton = () => {
    if (showAddUserButton) {
      return <Button
        className="float-right"
        color="success"
        href="#"
        onClick={(e) => sendInvite()}
        size="sm">
        Invite
      </Button>;
    } else {
      return null;
    }
  }


  function onChangeInvitedUserEmail(value) {
    setUserToAdd(value);

    if (validateEmail(value)) {
      setShowAddUserButton(true);
      setUerToAddInvalidEmailMessage("");
    } else {
      setShowAddUserButton(false);
      setUerToAddInvalidEmailMessage("Please enter a valid email");
    }
  };

  const mapDate = (timeStamp) => {
    var date = timeStamp.toDate().toDateString();
    return date;
  }

  const getInviteUserRow = () => {

    if(!isEnterpriceLicense){
      if (invitedUsers.length !== 0) {
        return <div style={{ marginLeft: "15px" }}>
          <h4>You need to have at least Business license to invite users to your account</h4><br></br>
          <small>You had the Business version before but downgraded. All the accounts you invited will have the status "invalid" and all PDFs shared by this account will be generated with a watermark stamp on them</small><br></br><br></br>
   
        </div>
      }

      return <>
        <h4>You need to have a enterprice license to invte users to your account</h4><br></br>   
      </>
    }

    if (invitedUsers.length >= maximumUsers) {
      return <>
        <h4>Maximum users reached ({maximumUsers}), please contact <a href="mailto:support@apptopdf.com">support@apptopdf.com</a> to get more users</h4><br></br>
      </>
    }

    return (
      <tr id="invite-new-email">
        <td>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              <strong>New invite:</strong> {maximumUsers - invitedUsers.length} users left to invite
            </label>
            <Input
              className="form-control-alternative"
              value={userToAdd}
              onChange={e => onChangeInvitedUserEmail(e.target.value)}
              id="input-invite-email"
              placeholder="E-mail to invite"
              type="email"
            />
            {userToAddInvalidEmailMessage}

          </FormGroup>
        </td>
        <td>
          {getInviteUserButton()}
        </td>
      </tr>
    );
  }

  if (invitedUsers.lenght === 0) {
    return (
      <Card className="bg-white shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="12">
              <h3 className="mb-0">INVITED USERS</h3>
              <small>Users invited to your account. They can se and execute your templates but not edit them</small>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              New invite
            </label>
            <Input
              className="form-control-alternative"
              value={userToAdd}
              onChange={e => onChangeInvitedUserEmail(e.target.value)}
              id="input-email-invite"
              placeholder="E-mail to invite"
              type="email"
            />
            {userToAddInvalidEmailMessage}

          </FormGroup>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card className="bg-white shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">INVITED USERS</h3>
            <small>Users invited to your account. They can se and execute your templates but not edit them</small>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Table className="align-items-center table-flush" responsive>

          <thead className="thead-light">
            <tr id="invite-header">
              <th scope="col">Email address</th>
              <th scope="col">Invited at</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>


          <tbody>

            {invitedUsers.map((invitedUser, index) => (
              <tr id={"user" + index} key={index}>
                <td>{invitedUser.data.invitedEmailAddress}</td>
                <td>{mapDate(invitedUser.data.createdAt)}</td>
                {getStatus(invitedUser.data.status, invitedUser.data.invitedEmailAddress, index, invitedUser.id)}
              </tr>
            ))}

          </tbody>
          <tfoot>
            {getInviteUserRow()}
          </tfoot>
        </Table>

      </CardBody>
    </Card>
  );
};

export default InvitedUsers;