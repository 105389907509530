const getTextAlignmentClass = (element) => {

  if (element.alignment === "Center")
    return "text-center";
  if (element.alignment === "Right")
    return "text-right";

  return "text-left";
}

let getLabelTextStyle = (element, linkText) => {
  var style = element.fontStyle;

  if (style === "Bold") {
    return <b>{linkText}</b>
  } else if (style === "Italic") {
    return <i>{linkText}</i>
  } else if (style === "BoldItalic") {
    return <b><i>{linkText}</i></b>
  }

  return linkText;
}

const LinkElement = ({ element }) => {
  return (
    <div className={getTextAlignmentClass(element)}>
      <h4>Link text:</h4>
      <h5 style={{ fontSize: element.fontSize + "px", color: element.textColor, "text-decoration": "underline" }} className="editable-label clickable-label">{getLabelTextStyle(element, element.linkText)}</h5>
      <h4>Pdf-link:</h4>
      <h5 style={{ fontSize: element.fontSize + "px", color: element.textColor, "text-decoration": "underline" }} className="editable-label clickable-label">{getLabelTextStyle(element, element.pdfLink)}</h5>
      <h4>iOS-link:</h4>
      <h5 style={{ fontSize: element.fontSize + "px", color: element.textColor }} className="editable-label clickable-label">{getLabelTextStyle(element, element.iosLink)}</h5>
      <h4>Android-link:</h4>
      <h5 style={{ fontSize: element.fontSize + "px", color: element.textColor }} className="editable-label clickable-label">{getLabelTextStyle(element, element.androidLink)}</h5>
    </div>
  );
}

export default LinkElement