import { FormGroup, Label, Input } from 'reactstrap';

const getVariableOutputName = (element) => {
  if (element.variableOutputName != undefined && element.variableOutputName !== "") {
    return (
      <h5>Variable output name: <strong>{element.variableOutputName}</strong></h5>
    )
  }

  return null;
}

const getTextAlignmentClass = (element) => {

  if (element.alignment === "Center")
    return "text-center";
  if (element.alignment === "Right")
    return "text-right";

  return "text-left";
}

let getDropDownItems = (values) => {
  return values.map((dropDownValue) => {
    return (
      <option value={dropDownValue.largeText}>{dropDownValue.largeText}</option>
    );
  });
}

const DropDownElement = ({ element }) => {
  return (
    <div className={getTextAlignmentClass(element)}>
      {getVariableOutputName(element)}
      <h5 style={{color: "black"}} for="alignment">{element.prompt}</h5>
      <Input
        disabled={true}
        readOnly={true}
        type="select"
        name="dropDown"
        id="dropDown">
        {getDropDownItems(element.values)}
      </Input>
    </div>
  );
}

export default DropDownElement