import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody
} from "reactstrap";

import FrostScriptEditor from "../steps/FrostScriptEditor";

const TitleEditor = ({ variableSpace, titleElement, updateElement }) => {

    const setAnchorLevel = (anchorLevel) => {

        let selectedAncorLevel = 0;
        if(!isNaN(anchorLevel)){
            selectedAncorLevel = parseInt(anchorLevel);
        }
        updateElement("anchorLevel", selectedAncorLevel);
    }

    
    return (
        <>
            <FormGroup>
                <Label for="titleEditor">Text</Label>
                <FrostScriptEditor
                    id={"titleEditor"}                
                    variableSpace={variableSpace.getVariables()}
                    boundProperty={titleElement.textValue}
                    onChange={e => updateElement("textValue", e)}
                    maxLines={2}
                    includeMethodSnippets={false}
                />
            </FormGroup >
            <FormGroup>

                <Label for="alignment">Text Alignment</Label>
                <Input
                    type="select"
                    name="alignment"
                    id="alignment"
                    value={titleElement.alignment}
                    onChange={e => updateElement("alignment", e.target.value)}>
                    <option value="Left">Left</option>
                    <option value="Right">Right</option>
                    <option value="Center">Center</option>
                </Input>
            </FormGroup >
            <FormGroup>

            <Label for="anchorLevel">Anchor level (0 default)</Label>
            <Input
                type="number"
                name="anchorLevel"
                id="anchorLevel"
                value={titleElement.anchorLevel}
                onChange={e => setAnchorLevel(e.target.value)}>
            </Input>
            </FormGroup >
        </>
    );
}

export default TitleEditor;