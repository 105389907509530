import React, { useState, useEffect } from "react";

import {
    Button,
    FormGroup,
    InputGroup,
    Modal,
    ModalBody,
    Label,
    Input
} from "reactstrap";

/**
 * Renders a modal that allows the user to select a step type.
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Whether the modal is open or not.
 * @param {function} props.toggle - Function to toggle the modal.
 * @param {function} props.selectStepType - Function to select the step type.
 * @returns {JSX.Element} - The SelectTemplateTypeModal component.
 */
const SelectTemplateTypeModal = ({ isOpen, toggle, selectStepType }) => {

    const [selectedStepType, setSelectedStepType] = useState("userStep");

    return (
        <div>
            <Modal
                className="modal-dialog-centered"
                isOpen={isOpen}
                toggle={toggle}>
                <div className=" modal-header">
                    <h5 className=" modal-title" id="modal-title-default">
                        Choose step type
                    </h5>
                    <button
                        aria-label="Close"
                        className=" close"
                        onClick={() => toggle()}
                        type="button"
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                {
                    <ModalBody>
                        <FormGroup>
                            <Label for="valueEditor">Step type</Label>
                            <Input
                                type="select"
                                name="stepType"
                                id="stepType"
                                value={selectedStepType}
                                onChange={e => setSelectedStepType(e.target.value)}>
                                <option value="userStep">User Step</option>
                                <option value="assignmentStep">Assignment Step</option>
                                <option value="pdfFormStep">Hantverkarformuläret 17</option>
                            </Input>
                        </FormGroup >
                    </ModalBody >
                }
                <div className=" modal-footer">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => selectStepType(selectedStepType)}>
                        Choose step type
                    </Button>
                    <Button
                        className=" ml-auto"
                        color="link"
                        onClick={() => toggle()}
                        type="button"
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default SelectTemplateTypeModal;