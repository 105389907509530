/**
 * Represents a design-time variable space.
 * @class
 */
class DesignTimeVariableSpace {

    constructor(variableSpace) {
        this.variableSpace = variableSpace;
    }

    /**
     * Checks if the given variable matches the format of a dropdown source.
     * @param {object} variable - The variable to check.
     * @returns {boolean} - True if the variable matches the format of a dropdown source, false otherwise.
     */
    matchingDropDownSource(variable) {

        var value = variable.Value;
        if (!Array.isArray(value))
            return false;

        if (value.length === 0)
            return false;

        if (value[0].Variables === undefined)
            return false;

        if (value[0].Variables.hasOwnProperty("largetext") && value[0].Variables.hasOwnProperty("smalltext") && value[0].Variables.hasOwnProperty("values"))
            return true;

        return false;
    }

    /**
     * Checks if the given variable has an array value with at least one object containing the properties "path", "width", and "height".
     * @param {object} variable - The variable to check.
     * @returns {boolean} - True if the variable has an array value with at least one object containing the properties "path", "width", and "height", false otherwise.
     */
    matchingImageSource(variable) {

        var value = variable.Value;
        if (!Array.isArray(value))
            return false;

        if (value.length === 0)
            return false;

        if (value[0].Variables === undefined)
            return false;

        if (value[0].Variables.hasOwnProperty("path") && value[0].Variables.hasOwnProperty("width") && value[0].Variables.hasOwnProperty("height"))
            return true;

        return false;
    }

    matchingListSource(variable) {

        var value = variable.Value;
        if (!Array.isArray(value))
            return false;

        if (value.length === 0)
            return false;

        if (value[0].Variables === undefined)
            return false;

        return true;
    }

    /**
     * Determines if the given variable matches the signature source.
     * @param {Object} variable - The variable to check.
     * @returns {boolean} - True if the variable matches the signature source, false otherwise.
     */
    matchingSignatureSource(variable) {

        var value = variable.Value;

        if (Array.isArray(value))
            return false;

        if (value.Variables === undefined)
            return false;

        if (value.Variables.hasOwnProperty("path") && value.Variables.hasOwnProperty("width") && value.Variables.hasOwnProperty("height"))
            return true;

        return false;
    }

    /**
     * Returns an array of table variable sources from the current variable space.
     * @returns {Array} An array of table variable sources.
     */
    getTableSources() {

        var designTimeSources = [];

        if (this.variableSpace === undefined || this.variableSpace.Variables === undefined)
            return designTimeSources;

        var tableSources = Object.keys(this.variableSpace.Variables)
            .filter((k, i) => this.variableSpace.Variables[k].VariableType === "Table");

        designTimeSources = tableSources.map((variableName, index) => {
            return new tableVariableSource(this.variableSpace.Variables[variableName]);
        })

        return designTimeSources;
    }

    /**
     * Returns an array of dropDownSource objects for all array variables in the variableSpace that match the criteria.
     * @returns {Array<dropDownSource>} An array of dropDownSource objects.
     */
    getColumnSources() {

        var designTimeSources = [];

        if (this.variableSpace === undefined || this.variableSpace.Variables === undefined)
            return designTimeSources;

        var columnSources = Object.keys(this.variableSpace.Variables)
            .filter((k, i) => {
                if (this.variableSpace.Variables[k].VariableType === "Array") {
                    if (this.matchingDropDownSource(this.variableSpace.Variables[k])) {
                        return true;
                    }
                    return false;
                }
                return false;
            });

        var designTimeSources = columnSources.map((variableName, index) => {
            return new dropDownSource(this.variableSpace.Variables[variableName]);
        })

        return designTimeSources;
    }

    /**
     * Returns an array of dropdown sources for signature input.
     * @returns {Array} An array of dropdown sources for signature input.
     */
    getSignatureInputSources() {
        var designTimeSources = [];

        if (this.variableSpace === undefined || this.variableSpace.Variables === undefined)
            return designTimeSources;

        var signatureSources = Object.keys(this.variableSpace.Variables)
            .filter((k, i) => {
                if (this.matchingSignatureSource(this.variableSpace.Variables[k])) {
                    return true;
                }
                return false;
            });

        var designTimeSources = signatureSources.map((variableName, index) => {
            return new dropDownSource(this.variableSpace.Variables[variableName]);
        })

        return designTimeSources;
    }

    /**
     * Returns an array of dropdown sources for image inputs based on the variables in the current variable space.
     * @returns {Array} An array of dropdown sources for image inputs.
     */
    getImageInputSources() {
        var designTimeSources = [];

        if (this.variableSpace === undefined || this.variableSpace.Variables === undefined)
            return designTimeSources;

        var imageSources = Object.keys(this.variableSpace.Variables)
            .filter((k, i) => {
                if (this.variableSpace.Variables[k].VariableType === "Array") {
                    if (this.matchingImageSource(this.variableSpace.Variables[k])) {
                        return true;
                    }
                    return false;
                }
                return false;
            });

        var designTimeSources = imageSources.map((variableName, index) => {
            return new dropDownSource(this.variableSpace.Variables[variableName]);
        })

        return designTimeSources;
    }

    getIteratorVariables () {
        var designTimeSources = [];

        if (this.variableSpace === undefined || this.variableSpace.Variables === undefined)
            return designTimeSources;

        var listSources = Object.keys(this.variableSpace.Variables)
            .filter((k, i) => {
                if (this.variableSpace.Variables[k].VariableType === "Array") {
                    if (this.matchingListSource(this.variableSpace.Variables[k])) {
                        return true;
                    }
                    return false;
                }
                return false;
            });

        designTimeSources = listSources.map((variableName, index) => {
            return new listControlVariableSource(this.variableSpace.Variables[variableName]);
        })

        return designTimeSources;
    }

    getListControlSources() {

        var designTimeSources = [];

        if (this.variableSpace === undefined || this.variableSpace.Variables === undefined)
            return designTimeSources;

        var listSources = Object.keys(this.variableSpace.Variables)
            .filter((k, i) => {
                if (this.variableSpace.Variables[k].VariableType === "Array") {
                    if (this.matchingListSource(this.variableSpace.Variables[k])) {
                        return true;
                    }
                    return false;
                }
                return false;
            });

        designTimeSources = listSources.map((variableName, index) => {
            return new listControlVariableSource(this.variableSpace.Variables[variableName]);
        })

        return designTimeSources;
    }

    /**
     * Returns the variables in the current variable space.
     * @returns {string} The variables in the current variable space.
     */
    getVariables() {
        if (this.variableSpace === undefined || this.variableSpace.Variables === undefined)
            return "";

        return this.variableSpace.Variables;
    }

}

/**
 * Represents a table variable source.
 * @class
 */
class tableVariableSource {

    constructor(tableSource) {
        this.name = tableSource.Name;
        this.columnDefinitions = tableSource.Value.ColumnDefinitions;
    }

}

/**
 * Represents a dropdown source.
 */
class dropDownSource {

    constructor(dropDownSources) {
        this.name = dropDownSources.Name;
    }

}

class listControlVariableSource {
    constructor(listSource) {
        this.name = listSource.Name;
        this.variables = listSource.Value.map((variable, index) => {
            return new listSourceVariable(variable[index]);
        });
    }
}

class listSourceVariable {
    constrcutor(variable) {
        this.name = variable.Name;
        this.value = variable.Value;
        this.variableType = variable.VariableType;
    }
}

export { DesignTimeVariableSpace };