/**
 * HantVerkarForm17StepEditor component for editing a specific step in a custom PDF form.
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Whether the modal is open or not.
 * @param {function} props.toggle - Function to toggle the modal.
 * @param {Object} props.hantVerkarForm17Step - The step data to edit.
 * @param {function} props.saveChanges - Function to save changes made to the step data.
 * @param {function} props.deleteStep - Function to delete the step.
 * @returns {JSX.Element} The HantVerkarForm17StepEditor component.
 */
// reactstrap components
import React, { useState, useEffect } from "react";

import ReactJson from 'react-json-view'


import { getCurrentVariableSpaceFromStep } from '../../StepVariableSpaceResolver';

import VariableDropDownComponent from "../VariableDropDownComponent";

import {
    Button,
    FormGroup,
    InputGroup,
    Modal,
    ModalBody,
    Label,
    Input,
    Form,
    Table,
    Row,
    Col,
    ButtonGroup
} from "reactstrap";
import { DesignTimeVariableSpace } from "../../DesignTimeVariableSpace";

const HantVerkarForm17StepEditor = ({ isOpen, toggle, hantVerkarForm17Step, saveChanges, deleteStep }) => {

    const [currentHantverkarForm17StepDataModel, setCurrentHantverkarForm17StepDataModel] = useState({ ...hantVerkarForm17Step });

    const [variableSpace, setVariableSpace] = useState({});

    const [designTimeVariableSpace, setDesignTimeVariableSpace] = useState({});

    const [resolvedVariableSpace, setResolvedVariableSpace] = useState(false);


    useEffect(() => {

        var actionRef = setCurrentHantverkarForm17StepDataModel(hantVerkarForm17Step);
        setStepName(hantVerkarForm17Step.step.name);

        getCurrentVariableSpaceFromStep(hantVerkarForm17Step.previousSteps)
            .then(data => {
                setResolvedVariableSpace(true);
                setVariableSpace(data);
                setDesignTimeVariableSpace(new DesignTimeVariableSpace(data))
            });

        return actionRef;
    }, [hantVerkarForm17Step])

    useEffect(() => {
    }, [currentHantverkarForm17StepDataModel])

    const [stepName, setStepName] = useState("")

    let updateSelectedVariable = (index, value) => {
        var assigned = { ...currentHantverkarForm17StepDataModel };

        assigned.step.fieldMappings[index].selectedVariable = value;

        assigned.step.fieldMappings[index].selectedValue = null;

        setCurrentHantverkarForm17StepDataModel(assigned);
    }

    /**
     * Updates the selected value of a field mapping at the specified index in the current HantVerkarForm17StepDataModel.
     * @param {number} index - The index of the field mapping to update.
     * @param {any} value - The new value to assign to the selectedValue property of the field mapping.
     */
    let updateVariableManualValue = (index, value) => {
        var assigned = { ...currentHantverkarForm17StepDataModel };

        assigned.step.fieldMappings[index].selectedVariable = null;

        assigned.step.fieldMappings[index].selectedValue = value;

        setCurrentHantverkarForm17StepDataModel(assigned);
    }

    /**
     * Updates the manually assigned variable for a given index in the current HantVerkarForm17StepDataModel.
     * @param {number} index - The index of the field mapping to update.
     * @param {boolean|string} value - The new value for the manuallyAssignedValue property.
     */
    let updateIsManuallyAssignedVariable = (index, value) => {

        var assigned = { ...currentHantverkarForm17StepDataModel };

        var chooseManually = false;
        if (value === true || value === "true") {
            chooseManually = true;
        }

        assigned.step.fieldMappings[index].manuallyAssignedValue = chooseManually;

        assigned.step.fieldMappings[index].selectedVariable = null;

        assigned.step.fieldMappings[index].selectedValue = null;

        setCurrentHantverkarForm17StepDataModel(assigned);
    }

    /**
     * Saves the changes made to the current HantVerkarForm17 step data model.
     * Validates the field mappings to ensure that all columns with variable type (Choose variable) are mapped.
     * If the step index is not set, it sets it to 0.
     * Sets the step name to the value of the `stepName` variable.
     * Calls the `saveChanges` function with the updated step data model.
     */
    let save = () => {

        //Validate
        var assigned = { ...currentHantverkarForm17StepDataModel };

        var failedColumns = assigned.step.fieldMappings.filter((field) => { return (!field.manuallyAssignedValue && field.selectedVariable === null) });

        if (failedColumns.length > 0) {
            alert("Not all columns with variable type (Choose variable) are mapped. Please map them and try again");
            return;
        }

        if (currentHantverkarForm17StepDataModel.step.index === -1 || currentHantverkarForm17StepDataModel.step.index === undefined) {
            currentHantverkarForm17StepDataModel.step.index = 0;
        }

        currentHantverkarForm17StepDataModel.step.name = stepName;

        saveChanges(currentHantverkarForm17StepDataModel.step);
    }

    if (currentHantverkarForm17StepDataModel === undefined) {
        return null;
    }

    function onChange(newValue) {
        console.log("change", newValue);
    }

    let getLoadingCOntent = () => {
        <Row>
            <Col lg="12">
                <Label>Resolving variable space...</Label>
            </Col>
        </Row>

    }

    /**
     * Returns an array of dropdown sources for variables of the specified type.
     * @param {string} variableType - The type of variable to filter by.
     * @returns {Array<dropDownSource>} An array of dropdown sources for variables of the specified type.
     */
    let getFilteredVariables = (variableType) => {

        if (variableType === "Signature") {
            return designTimeVariableSpace.getSignatureInputSources();
        }

        var currentVariableSpace = [];

        var variables = designTimeVariableSpace.getVariables();

        var stringVariables = Object.keys(variables)
            .filter((k, i) => {
                if (variables[k].VariableType === variableType) {
                    return true;
                }
                return false;
            });

        var currentVariableSpace = stringVariables.map((variableName, index) => {
            return new dropDownSource(variables[variableName]);
        })

        return currentVariableSpace;
    }

    /**
     * Returns a component for editing the value of a variable.
     * @param {number} index - The index of the variable.
     * @param {Object} column - The column object containing information about the variable.
     * @param {boolean} chooseManually - Whether to choose the value manually or from a dropdown.
     * @returns {JSX.Element} - The component for editing the variable value.
     */
    let getEditVaraibleValueComponent = (index, column, chooseManually) => {
        if (chooseManually) {
            if (column.variableType === "Boolean") {
                return (
                    <Input
                        type="select"
                        name={"variableOutputName" + index}
                        id={"variableOutputName" + index}
                        value={column.selectedValue}
                        onChange={e => updateVariableManualValue(index, e.target.value)}>
                        <option value="false">False</option>
                        <option value="true">True</option>
                    </Input>
                )
            } else if (column.variableType === "Signature") {
                
            } else {
                return (
                    <Input
                        type="text"
                        name={"variableOutputName" + index}
                        id={"variableOutputName" + index}
                        key={"variableOutputName" + index}
                        value={column.selectedValue}
                        onChange={e => updateVariableManualValue(index, e.target.value)}>
                    </Input>
                )
            }
        } else {
            return (
                <VariableDropDownComponent
                    selectedVariable={column.selectedVariable}
                    avaliableVariables={getFilteredVariables(column.variableType)}
                    onSelectedItem={e => updateSelectedVariable(index, e)}
                    dropDownSource={true}
                    key={"variableOutputName" + index}
                />
            )
        }
    }

    let getSelctableCoumns = (index, column) => {
        if (column.variableType === "Signature") {
            return <Input
                type="select"
                name={"chooseManuallyAssigned" + index}
                id={"chooseManuallyAssigned" + index}
                key={"chooseManuallyAssigned" + index}
                value={column.manuallyAssignedValue}
                onChange={e => updateIsManuallyAssignedVariable(index, e.target.value)}>
                <option value={false}>Choose variable</option>
                <option value={true}>Ignore / Don't use</option>
            </Input>
        } else {
            return (
                <Input
                    type="select"
                    name={"chooseManuallyAssigned" + index}
                    id={"chooseManuallyAssigned" + index}
                    key={"chooseManuallyAssigned" + index}
                    value={column.manuallyAssignedValue}
                    onChange={e => updateIsManuallyAssignedVariable(index, e.target.value)}>
                    <option value={false}>Choose variable</option>
                    <option value={true}>Enter manually</option>
                </Input>
            )
        }
    }

    /**
     * Returns an array of table columns based on the currentHantverkarForm17StepDataModel.
     * @returns {Array} An array of table columns.
     */
    let getTableColumnData = () => {
        var tableColumns = currentHantverkarForm17StepDataModel.step.fieldMappings.map((column, index) => {
            return (
                <tr key={index}>
                    <td>
                        {column.variableName}
                    </td>
                    <td>
                        {column.variableType}
                    </td>
                    <td>
                        {getSelctableCoumns(index, column)}
                    </td>
                    <td>
                        {getEditVaraibleValueComponent(index, column, column.manuallyAssignedValue)}
                    </td>
                </tr>
            )
        })

        return tableColumns;
    }


    /**
     * Returns the content to be displayed in the HantVerkarForm17StepEditor component.
     * If resolvedVariableSpace is falsy, it returns the loading content.
     * Otherwise, it returns a table with column data.
     *
     * @returns {JSX.Element} The content to be displayed in the component.
     */
    let getContent = () => {
        if (!resolvedVariableSpace) {
            return getLoadingCOntent();
        }

        return (
            <Table>
                <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Data type
                        </th>
                        <th>
                            Variable type
                        </th>
                        <th>
                            Selected variable/value
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {getTableColumnData()}
                </tbody>
            </Table>
        )
    }

    return (
        <Modal
            className="modal-dialog-centered  modal-xl"
            isOpen={isOpen}
            toggle={toggle}>
            {
                <ModalBody>
                    <p>Map variables for Hantverkarformuläret-17</p>
                    {getContent()}
                    <FormGroup>
                        <Label for="variableSpace">Current variable space</Label>
                        <ReactJson src={variableSpace} collapsed={true} />
                    </FormGroup >
                </ModalBody >
            }
            <div className=" modal-footer">
                <ButtonGroup>
                    <Button
                        color="primary"
                        onClick={() => save()}>
                        SAVE CHANGES
                    </Button>
                    <Button
                        color="success"
                        onClick={() => toggle()}>
                        CANCEL
                    </Button>
                </ButtonGroup>
                <Button
                    className=" ml-auto"
                    color="link"
                    onClick={() => deleteStep(currentHantverkarForm17StepDataModel.step.index, "pdfFormStep")}
                    type="button"
                >
                    DELETE
                </Button>
            </div>
        </Modal>
    );
}

class dropDownSource {

    constructor(dropDownSources) {
        this.name = dropDownSources.Name;
    }

}

export default HantVerkarForm17StepEditor;