import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody,
    Row,
    Col,
    Form,
    Button
} from "reactstrap";

import React, { useState, useEffect } from "react";

import VariableDropDownComponent from "../VariableDropDownComponent";
import ReactJson from 'react-json-view'

import ChoosableGroupElements from './ChoosableGroupElements'

import App2PdfGridstack from "../../App2PdfGridstack"

import ContextualModal from "../../../Modal"

import FrostScriptEditor from "../../steps/FrostScriptEditor";

import ReactMediaLibraryWrapper from "../../../MediaGallery/ReactMediaLabraryWrapper";

import { DesignTimeVariableSpace } from "../../steps/DesignTimeVariableSpace";

import { v4 as uuidv4 } from 'uuid';

import { getVariableSubSpace } from "../../ElementEditors/listControl/getVariableSubSpace";


const ElementGroupEditor = ({ elementGroup, updateElement, toggle, saveChanges, itemId, variableSpace, currentSubControls, updateSubControls, previousSteps }) => {

    const [currentGroup, setCurrentGroup] = useState({ ...elementGroup });

    const [currentUserStepControls, setCurrentUserStepControls] = useState([...elementGroup.elements])

    const [modalOpen, setModalOpen] = useState(false);
    const toggleItemModal = () => setModalOpen(!modalOpen);

    const [currentModalItem, setCurrentModalItem] = useState({ elementType: "", textValue: "", pdfPosition: "" });
    const setModalElement = (currentModalElement) => setCurrentModalItem(currentModalElement);

    const [variableSubSpace, setVariableSubSpace] = useState(new DesignTimeVariableSpace(""));

    const [prevSteps, setPrevSteps] = useState([...previousSteps]);

    const [currentGroupIterator, setCurrentGroupIterator] = useState({
        variable: "",
        id: ""
    })

    const [isMediaShown, setIsMediaShown] = useState(false);

    React.useEffect(() => {
        var actionRef = mapCurrentVariableSpace(currentGroup.iterator);
        return actionRef;
    }, [currentGroup, currentUserStepControls])

    let save = () => {

        var groupToSave = { ...currentGroup };

        groupToSave.elements = [...currentUserStepControls];

        saveChanges(groupToSave, itemId);
    }

    const updateInputVariable = (variable) => {
        var elGroupVariable = { ...currentGroup };
        elGroupVariable.iterator = variable;
        setCurrentGroup(elGroupVariable);
    }

    let mapCurrentVariableSpace = (variable) => {

        let inputVariables = [];

        // Pop the previous subcontrol item
        inputVariables = updateSubControls(currentGroupIterator, false);

        if (variable === undefined) {
            variable = "";
        }

        if (variable !== "") {

            var tempCurrentListVariable = {
                variable: variable,
                id: uuidv4()
            };

            // Add the list control item
            setCurrentGroupIterator(tempCurrentListVariable)

            inputVariables = updateSubControls(tempCurrentListVariable, true);
        }

        var prueVariables = [];

        if (inputVariables.length > 0) {
            prueVariables = inputVariables.map((lst) => {
                return lst.variable;
            })
        }

        getVariableSubSpace(prevSteps, prueVariables)
            .then(data => {
                var designTimeVariableSpace = new DesignTimeVariableSpace(data);
                setVariableSubSpace(designTimeVariableSpace);
            });
    }

    const staticImageSelected = (image, staticImageControl) => {
        if (staticImageControl.element.elementType === "StaticImage") {
            staticImageControl.element.imagePath = image.thumbnailUrl;
            toggleItemModal();
        }
    }

    let updateCurrentControlGroup = (propertyName, value) => {
        var toEdit = { ...currentGroup };

        toEdit[propertyName] = value;

        setCurrentGroup(toEdit);
    }

    let addNewControl = (newElement) => {

        var controlsCopy = [...currentUserStepControls];

        var indexedControls = controlsCopy.filter(c => c.element.elementType === newElement.element.elementType);

        newElement.element.variableOutputName = newElement.element.elementType + (indexedControls.length + 1);

        controlsCopy.push(newElement);
        setCurrentUserStepControls(controlsCopy);
    }

    let updateElements = (item, id) => {

        var controlsCopy = [...currentUserStepControls];

        var indexToUpdate = controlsCopy.findIndex(c => c.id === id);

        controlsCopy[indexToUpdate].element = item;

        setCurrentUserStepControls(controlsCopy);

        toggleItemModal();
    }

    const updateHeaderElementWidhtHeightAndPosition = (changedItems) => {

        let items = [...currentUserStepControls];

        updateHeightAndPosition(items, changedItems);
    }

    const updateHeightAndPosition = (items, changedItems) => {
        if (items.length === 0) {
            //console.log("Items are empty...");
            return;
        }

        let newItems = [];
        items.map((item, index) => {

            newItems.push(item);

            changedItems.map((changed, changedIndex) => {

                if (item.id === changed.el.id) {
                    let toEdit = newItems[index];

                    toEdit.element.width = changed.w;
                    toEdit.element.height = changed.h;
                    toEdit.element.posX = changed.x;
                    toEdit.element.posY = changed.y;

                    newItems[index].element = toEdit.element;
                }
            })
        });

        items = newItems;

        return items;
    }

    const deleteGridItem = (itemId) => {

        //For now this is an item in the main grid
        let items = [...currentUserStepControls];

        deleteFromGrid(items, itemId);

        setCurrentUserStepControls(items);
    }

    const deleteFromGrid = (items, itemId) => {

        var indexToUpdate = 0;

        items.map((val, index) => {
            if (val.id === itemId) {
                indexToUpdate = index;
            }
        });

        var removed = items.splice(indexToUpdate, 1);
    }

    return (
        <>
            <ModalBody>
                <Row>
                    <Col lg="3">
                        <Form>
                            <FormGroup>

                                <Label for="elementGroupName">GROUP NAME</Label>
                                <Input
                                    placeholder="Text"
                                    type="text"
                                    value={currentGroup.name}
                                    id="elementGroupName"
                                    onChange={e => updateCurrentControlGroup("name", e.target.value)} />

                            </FormGroup >
                            <FormGroup>

                                <Label for="scriptEditor">RENDER IF STATEMENT</Label>
                                <FrostScriptEditor
                                    id={"scriptEditor"}
                                    variableSpace={variableSpace.getVariables()}
                                    boundProperty={currentGroup.renderIf}
                                    onChange={e => updateCurrentControlGroup("renderIf", e)}
                                    maxLines={15}
                                    includeMethodSnippets={true}
                                />

                            </FormGroup >
                            <FormGroup>

                                <Label for="smallText">ITERATOR VARIABLE</Label>
                                <VariableDropDownComponent
                                    selectedVariable={currentGroup.iterator}
                                    avaliableTableVariables={variableSpace.getIteratorVariables()}
                                    onSelectedItem={e => updateInputVariable(e)}
                                    dropDownSource={true} />

                            </FormGroup >
                        </Form>
                        <Label>CHOOSABLE CONTROLS</Label>
                        <Row>
                            <ChoosableGroupElements
                                addNewElement={addNewControl}
                            />
                        </Row>
                    </Col>
                    <Col lg="9">
                        <Label>CONTROLS</Label>
                        <App2PdfGridstack
                            gridElementId={uuidv4()}
                            items={currentUserStepControls}
                            toggleModal={toggleItemModal}
                            setModalElement={setModalElement}
                            onGridItemChanged={updateHeaderElementWidhtHeightAndPosition}
                            onDeleteItem={deleteGridItem}
                        />
                    </Col>

                </Row>

                <br></br>

                <Label for="variableSpace">Variable space</Label>
                <ReactJson src={variableSubSpace} collapsed={true} />

                <ContextualModal
                    isOpen={modalOpen}
                    toggle={toggleItemModal}
                    item={currentModalItem}
                    onSaveChanges={updateElements}
                    variableSpace={variableSubSpace}
                    showGallery={() => setIsMediaShown(true)}
                    currentSubControls={currentSubControls}
                    updateSubControls={updateSubControls}
                    previousSteps={previousSteps}>

                </ContextualModal>

            </ModalBody >
            <div className=" modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => save()}>
                    Save changes
                </Button>
                <Button
                    className=" ml-auto"
                    color="link"
                    onClick={() => toggle()}
                    type="button"
                >
                    Cancel
                </Button>
            </div>

            <ReactMediaLibraryWrapper
                imageSelected={(image) => staticImageSelected(image, currentModalItem)}
                isVisible={isMediaShown}
                onHidden={() => setIsMediaShown(false)}
            />
        </>
    );
}

export default ElementGroupEditor;