import {
    Table,
} from "reactstrap";

const getTextAlignmentClass = (element) => {

    if (element.alignment === "Center")
        return "text-center";
    if (element.alignment === "Right")
        return "text-right";

    return "text-left";
}

let getLabelTextStyle = (column) => {
    var style = column.labelFontStyle;

    if (style === "Bold") {
        return <b>{column.label}</b>
    } else if (style === "Italic") {
        return <i>{column.label}</i>
    } else if (style === "BoldItalic") {
        return <b><i>{column.label}</i></b>
    }

    return column.label;
}

let getValueTextStyle = (column) => {
    var style = column.valueFontStyle;

    if (style === "Bold") {
        return <b>{column.value}</b>
    } else if (style === "Italic") {
        return <i>{column.value}</i>
    } else if (style === "BoldItalic") {
        return <b><i>{column.value}</i></b>
    }

    return column.value;
}

const TextInfoGroup = ({ element }) => {
    return (
        <div>
            <div className={getTextAlignmentClass(element)}>
                <h2 style={{ marginLeft: 22, marginRight: 22, fontSize: element.promptFontSize + "px" }} className="editable-label clickable-label">{element.prompt}</h2>
            </div>
            <div style={{ "border-color": element.borderColor, "background": element.backgroundColor, "border-style": element.borderStyle, "border-radius": element.borderRadius + "px" }}>
                <Table borderless>
                    <thead>
                    </thead>
                    <tbody>
                        {element.textInfos.map((column, index) => {
                            return (
                                <tr
                                    key={"rowId-" + index}
                                    className="no-border"
                                >
                                    <td
                                        key={"label-" + index}
                                        style={{ color: column.labelColor, fontSize: element.textInfosFontSize + "px" }}
                                        className="text-left">
                                        {getLabelTextStyle(column)}
                                    </td>
                                    <td
                                        key={"value-" + index}
                                        style={{ color: column.valueColor, fontSize: element.textInfosFontSize + "px" }}
                                        className="text-right">
                                        {getValueTextStyle(column)}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default TextInfoGroup