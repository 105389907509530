import {
    FormGroup,
    Input,
    Button,
    ModalBody
} from "reactstrap";

import React, { useState, useEffect } from 'react';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

const StaticImageEditor = ({ staticImageElement, updateElement, toggle, saveChanges, itemId, showGallery }) => {

    const [isFilePicked, setIsSelected] = useState(false);

    let [loadingOverlay, setLoadingOverlay] = useState(false);

    const [selectedFile, setSelectedFile] = useState({
        currentImagePath: staticImageElement.imagePath,
        previewImagePath: staticImageElement.imagePath,
        selectedImagePath: "",
        selectedFile: {}
    });

    const loadingCss = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    height: 25px;
  `

    React.useEffect(() => {
        //console.log("Useeffect with object output");
    }, [selectedFile])

    const selectFile = (image) => {

        let newFile = Object.assign({}, selectedFile);

        newFile.previewImagePath = image.thumbnailUrl;
        newFile.selectedImagePath = image.thumbnailUrl;

        setSelectedFile(newFile);
        setIsSelected(true);
    }

    const save = () => {

        if (isFilePicked) {
            let newFile = Object.assign({}, staticImageElement);
            newFile.imagePath = selectedFile.selectedImagePath;
            newFile.fileName = selectedFile.selectedFile.name;
            newFile.fileType = selectedFile.selectedFile.type;

            saveChanges(newFile, itemId);
        }

        //Just close the modal
        toggle();
    }

    return (
        <>
            <ModalBody>

                <Button className="my-4" color="primary" type="button" onClick={() => {
                    showGallery();
                    setLoadingOverlay(true);                    
                    setTimeout(() => {
                        setLoadingOverlay(false);
                      }, "5000")
                }}
                >Select image</Button>
                <ClipLoader
                    loading={loadingOverlay}
                    cssOverride={loadingCss}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                <br></br>

                <img
                    alt="..."
                    className="img-thumbnail"
                    src={selectedFile.previewImagePath}
                />

            </ModalBody >
            <div className=" modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => save()}>
                    Save changes
                </Button>
                <Button
                    className=" ml-auto"
                    color="link"
                    onClick={() => toggle()}
                    type="button"
                >
                    Cancel
                </Button>
            </div>
        </>
    )


}

export default StaticImageEditor;