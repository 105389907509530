import React, { useState, useEffect, createRef, useRef } from "react";

import {
  Row,
  Col
} from "reactstrap";

import elementTypes from "../Templates/DesignElements/TypedTemplateElements";

import '../../../node_modules/gridstack/dist/gridstack.min.css';
import 'gridstack/dist/h5/gridstack-dd-native';
import '../../assets/css/app-to-pdf-designer.css'

import GridStack from 'gridstack/dist/gridstack-h5';

const deleteIconStyle = { width: "30px", marginTop: "auto", marginBottom: "auto", float: "right" };
const imageStyle = { width: "50px", marginTop: "auto", marginBottom: "auto", float: "left" };

const TypedItem = ({ element }) => elementTypes(element)


const App2PdfGridstack = ({ gridElementId, items, toggleModal, setModalElement, onGridItemChanged, onDeleteItem }) => {

  //const refs = useRef({})
  const gridRef = useRef()

  let itemClicked = function (item) {
    toggleModal();
    setModalElement(item);
  }

  /*if (Object.keys(refs.current).length !== items.length) {
    items.forEach(({ id }) => {
      refs.current[id] = refs.current[id] || createRef()
    })
  }*/


  useEffect(() => {

    let options = {
      minRow: 1, // don't collapse when empty
      cellHeight: 100,
      resizable: { handles: 'e, se, s, sw, w' },
      disableOneColumnMode: true,
      float: false,
      removable: '.trash', // drag-out delete class
      //acceptWidgets: function (el) { return true; } // function example, else can be simple: true | false | '.someClass' value
    };

    gridRef.current =
      gridRef.current ||
      GridStack.init(
        options,
        '#' + gridElementId
      );

    const grid = gridRef.current

    if (grid && Object.keys(grid._gsEventHandler).length === 0) {


      //console.log("Attaching event handler for added and change")

      grid.on('change', function (event, items) {

        //console.log("Changed items count: " + items.length);

        let changedItems = [];
        for (var i = 0; i < items.length; i++) {

          let changed = items[i];

          var newPosY = changed.y;
          var newPosX = changed.x;

          changedItems.push(changed);

          //console.log("Grid changed posX: " + newPosX + " posY: " + newPosY + " width: " + changed.w + " height: " + changed.h);
        }

        onGridItemChanged(changedItems);

      });

      grid.on('added', function (event, items) {

        //console.log("Added new grid item: " + items.length);

        let changedItems = [];
        for (var i = 0; i < items.length; i++) {

          let changed = items[i];

          var newPosY = changed.y;
          var newPosX = changed.x;

          changedItems.push(changed);

          //console.log("Grid added at posX: " + newPosX + " posY: " + newPosY + " width: " + changed.w + " height: " + changed.h);
        }

        onGridItemChanged(changedItems);

      });

    }

    //console.log("Recreating grid");

    grid.batchUpdate()
    grid.removeAll(false)
    items.forEach(({ id }) => grid.makeWidget(id))
    grid.commit()

  }, [items])

  let createGridItem = (item) => {
    return (
      <div id={item.id} key={item.id} gs-w={item.element.width} gs-min-w={item.element.minWidth} gs-min-h={item.element.minHeigth} gs-h={item.element.height} gs-x={item.element.posX} gs-y={item.element.posY} className={'grid-stack-item'}>
        <div className="grid-stack-item-content">
          <Row>

            <Col>
              {getTemplateImage(item.element.elementType)}
              <img
                className="clickable-label float-right trash"
                alt="..."
                src={
                  require("../../assets/img/elements-icons/trash.svg")
                    .default
                }
                style={deleteIconStyle}
                onClick={() => onDeleteItem(item.id)}
              ></img>
            </Col>


          </Row>
          <Row className="design-element-content">
            <Col onClick={() => itemClicked(item)} lg="12">
              <TypedItem {...item} />
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const getTemplateImage = (elementType) => {

    var imagePath = require("../../assets/img/elements-icons/title_element.svg").default;

    if (elementType === "StaticText")
      imagePath = require("assets/img/elements-icons/static_text_element.svg").default
    else if (elementType == "TextInput")
      imagePath = require("assets/img/elements-icons/text_input_element.svg").default
    else if (elementType == "TableView")
      imagePath = require("assets/img/elements-icons/table_element.svg").default
    else if (elementType == "StaticImage")
      imagePath = require("assets/img/elements-icons/static_image_element.svg").default
    else if (elementType == "ImageInput")
      imagePath = require("assets/img/elements-icons/image_input_element.svg").default
    else if (elementType == "PageBreak")
      imagePath = require("assets/img/elements-icons/page_break_element.svg").default
    else if (elementType == "SignatureInput")
      imagePath = require("assets/img/elements-icons/signature_element.svg").default
    else if (elementType == "ScriptElement")
      imagePath = require("assets/img/elements-icons/script_control_element.svg").default
    else if (elementType == "ElementGroup")
      imagePath = require("assets/img/elements-icons/group_element.svg").default
    else if (elementType == "LabeledText")
      imagePath = require("assets/img/elements-icons/labeledtext_element.svg").default
    else if (elementType == "CheckBox")
      imagePath = require("assets/img/elements-icons/checkbox_element.svg").default
    else if (elementType == "DropDown")
      imagePath = require("assets/img/elements-icons/dropdown_element.svg").default
    else if (elementType == "TextInfoGroup")
      imagePath = require("assets/img/elements-icons/textinfogroup_element.svg").default
    else if (elementType == "LinkControl")
      imagePath = require("assets/img/elements-icons/link_element.svg").default



    return (
      <img
        alt="..."
        src={
          imagePath
        }
        style={imageStyle}
      ></img>
    );
  }

  return (
    <div>
      <div id={gridElementId} className={`grid-stack controlled`} style={{ minHeight: "400px" }}>

        {items.map((item, i) => {
          return (
            createGridItem(item)
          )
        })}
      </div>
    </div>
  )
}

export default App2PdfGridstack;