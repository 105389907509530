import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody
} from "reactstrap";

const TableOfContentsEditor = ({ tableOfContentsElement, updateElement }) => {

    const updateBooleanField = (stateName, value) => {
        if (value === "true") {
            updateElement(stateName, true);
        } else {
            updateElement(stateName, false);
        }
    }

    return (
        <>
            <FormGroup>
                <Label for="textInputEditor">Text Value</Label>
                <Input
                    placeholder="Text"
                    type="text"
                    value={tableOfContentsElement.textValue}
                    id="textInputEditor"
                    onChange={e => updateElement("textValue", e.target.value)} />

            </FormGroup >

            <FormGroup>
                <Label for="pageNumberFormat">Page number format</Label>
                <Input
                    placeholder="Text"
                    type="text"
                    value={tableOfContentsElement.pageNumberFormat}
                    id="pageNumberFormat"
                    onChange={e => updateElement("pageNumberFormat", e.target.value)} />

            </FormGroup >

        </ >

    );
}

export default TableOfContentsEditor;