import {
    ModalBody,
    Button,
    Table,
    Input,
    Label,
    FormGroup
} from "reactstrap";

import React, { useState, useEffect } from 'react';
import VariableDropDownComponent from "./VariableDropDownComponent";
import ReactJson from 'react-json-view'
import FrostScriptEditor from "../steps/FrostScriptEditor";


const TableEditor = ({ tableControl, updateElement, toggle, saveChanges, itemId, variableSpace }) => {

    debugger;
    const [columns, setColumns] = useState(tableControl.columns);

    if (tableControl.tableSummary === undefined) {
        tableControl.tableSummary = { scriptValue: "" }
    }

    const [tableSum, setTableSum] = useState(tableControl.tableSummary)

    const [isTableCheckable, setIsTableCheckable] = useState(tableControl.checkTable)

    const [variableOutputName, setVariableOutputName] = useState(tableControl.variableOutputName)


    let isInitialReadOnly = (variable) => {

        if (variable === undefined || variable === "") {
            return false;
        }

        return true;
    }

    const [readOnlyColumns, setReadOnlyColumns] = useState(isInitialReadOnly(tableControl.variable))


    React.useEffect(() => {

    }, [columns, tableSum, isTableCheckable, variableOutputName])

    const addColumn = () => {
        let editedColumns = [...columns, { width: "", text: "Col " + (columns.length + 1), inputType: "TextInput" }];
        setColumns(editedColumns);
    }

    const deleteColumn = (index) => {
        if (columns.length == 1) {
            alert("A table needs at least one column");
            return;
        } else {
            let editedColumns = [...columns];
            editedColumns.splice(index, 1);
            setColumns(editedColumns);
        }
    }

    let mapFromVariableColumnDefinitionInputType = (columnType) => {
        if (columnType === "textcolumn") {
            return "TextInput";
        }
        else if (columnType === "dropdowncolumn") {
            return "DropDown";
        }
        else if (columnType === "numericcolumn") {
            return "NumericInput";
        }
        else if (columnType === "gpscolumn") {
            return "GpsInput";
        }
        else if (columnType === "imagecolumn") {
            return "ImageInput";
        }
        else if (columnType === "scriptcolumn") {
            return "Script";
        }
        else if (columnType === "datecolumn") {
            return "DateInput";
        }
        else if (columnType === "signaturecolumn") {
            return "SignatureInput";
        }
    }

    let getScriptValueEventually = (colVariable) => {
        if (colVariable.scriptvalue !== undefined && colVariable.scriptvalue.Value !== undefined) {
            return colVariable.scriptvalue.Value;
        }

        return "";
    }

    const setSelectedVariable = (variableName) => {
        if (variableName !== "") {
            // Rerender the table

            try {

                //If you choose "No variable".
                if (variableName === "No variable selected") {
                    tableControl.variable = "";
                    tableControl.fromVariable = false;
                    setReadOnlyColumns(false);
                    return;
                }

                var matchingVariable = variableSpace.getTableSources().find(d => d.name === variableName);

                if (matchingVariable === undefined) {
                    alert("An issue occoured when selecting variable, maybe someone deleted it while you where selecting it. Please reconfigure the assignment step where you assigned it.")
                    tableControl.variable = "";
                    tableControl.fromVariable = false;
                    return;
                }

                var columnDefinitions = matchingVariable.columnDefinitions;

                var columns = columnDefinitions.map((col, index) => {
                    return {
                        text: "Col " + (index + 1),
                        inputType: mapFromVariableColumnDefinitionInputType(col.Variables.columntype.Value),
                        columnName: col.Variables.columnname.Value,
                        scriptValue: getScriptValueEventually(col.Variables),
                        alignment: "Left",
                        width: "",
                        fromVariable: true
                    };
                })

                if (columns !== undefined && columns.length > 0) {

                    setColumns(columns);

                } else {

                    alert("You have no correct columns in your data source. Please read our documentation and try again.")

                    tableControl.variable = "";
                    tableControl.fromVariable = false;

                    return;

                }

                tableControl.variable = variableName;
                tableControl.fromVariable = true;
                setReadOnlyColumns(true);

            }
            catch (err) {
                alert("There is a missconfiguration in your data source. Please read our documentation and try again.")
            }
        } else {
            tableControl.variable = "";
            tableControl.fromVariable = false;
            setReadOnlyColumns(false);
        }
    }

    const updateVariableOutputName = (value) => {
        setVariableOutputName(value);
    }

    const updateFieldChanged = (name, index, value) => {

        let editedColumns = [...columns];

        editedColumns[index][name] = value;

        setColumns(editedColumns);
    }

    const updateTableSummary = (fieldName, value) => {

        var editedTableSum = { ...tableSum };

        editedTableSum[fieldName] = value;

        setTableSum(editedTableSum);
    }

    const updateIsTableCheckable = (value) => {
        if (value === "true") {
            setIsTableCheckable(true);
        } else {
            setIsTableCheckable(false);
        }
    }

    const save = () => {

        var totalWidth = 0;
        for (var i = 0; i < columns.length; i++) {
            if (columns[i].width) {
                totalWidth += parseInt(columns[i].width);
            }
        }

        //Only save if no columns have width or if the total width is 100%
        if (totalWidth === 0 || totalWidth === 100) {

            var columnsWithVariable = columns.filter(c => c.inputType === "DropDown");

            var validTable = true;
            debugger;
            if (columnsWithVariable.length > 0) {
                columnsWithVariable.forEach(column => {
                    if (column.variable === undefined || column.variable === "") {
                        if (!tableControl.fromVariable) {
                            validTable = false;
                            alert("You need to choose variable for all columns with type drop down.")
                        }
                    }
                });
            }

            if (!validTable)
                return;

            var columnsWithScripts = columns.filter(c => c.inputType === "Script");

            if (columnsWithScripts.length > 0) {
                columnsWithScripts.forEach(column => {
                    if (column.scriptValue === undefined || column.scriptValue === "") {
                        validTable = false;
                        alert("You need to add a script for all columns with type Script.")
                    }
                });
            }

            if (!validTable)
                return;


            //Set the state to the actual element
            tableControl.columns = columns;

            tableControl.tableSummary = tableSum;

            tableControl.checkTable = isTableCheckable;

            tableControl.variableOutputName = variableOutputName;

            saveChanges(tableControl, itemId);
        } else {
            alert("Columns widths must either be all set to empty or they must have a total amount of 100");
        }

    }

    const getCustomColumnInputs = (column, index) => {
        if (column.inputType === "Script") {

            // Render columns with script input below
            return (
                <>
                    <br></br>
                    <Input
                        type="textarea"
                        name="columnScript"
                        id={"columnScript" + index}
                        readOnly={readOnlyColumns}
                        disabled={readOnlyColumns}
                        value={column.scriptValue}
                        placeholder="E.g {sum(c3)}"
                        onChange={e => updateFieldChanged("scriptValue", index, e.target.value)}>
                    </Input>
                </>
            );

        } else if (column.inputType === "DropDown") {
            if (!readOnlyColumns) {
                return (
                    <>
                        <br></br>
                        <VariableDropDownComponent
                            selectedVariable={column.variable}
                            avaliableTableVariables={variableSpace.getColumnSources()}
                            onSelectedItem={e => updateFieldChanged("variable", index, e)}
                            dropDownSource={true}
                        />
                    </>
                )
            }
        } else {
            return null;
        }
    }

    const deleteColumnButton = (index) => {
        if (readOnlyColumns) {
            return (
                null
            );
        } else {
            return (
                <button onClick={e => deleteColumn(index)} className="btn btn-warning">x</button>
            );
        }
    }

    let getDefaultValueEditor = (tableControl, column, index) => {
        if (tableControl.variable) {
            return <Input
                type="text"
                name="defaultText"
                id="defaultText"
                value={column.defaultText}
                readOnly={tableControl.variable}
                disabled={tableControl.variable}
                onChange={e => updateFieldChanged("defaultText", index, e.target.value)}>
            </Input>
        } else {
            return <FrostScriptEditor
                id={"defaultText-" + index}
                variableSpace={variableSpace.getVariables()}
                boundProperty={column.defaultText}
                onChange={e => updateFieldChanged("defaultText", index, e)}
                maxLines={15}
                includeMethodSnippets={false}
                placeholder="E.g {username}, Hello or 5"
            />
        }
    }

    let getTableColumnData = () => {
        var tableColumns = columns.map((column, index) => {
            return (
                <tr key={index}>
                    <td>
                        {index + 1}
                    </td>
                    <td>
                        <Input
                            type="text"
                            name="columnName"
                            id="columnName"
                            value={column.columnName}
                            readOnly={tableControl.variable}
                            disabled={tableControl.variable}
                            onChange={e => updateFieldChanged("columnName", index, e.target.value)}>
                        </Input>
                    </td>
                    <td>
                        {getDefaultValueEditor(tableControl, column, index)}
                    </td>
                    <td>
                        <Input
                            type="select"
                            name="alignment"
                            id="alignment"
                            value={column.alignment}
                            readOnly={readOnlyColumns && !tableControl.variable}
                            disabled={readOnlyColumns && !tableControl.variable}
                            onChange={e => updateFieldChanged("alignment", index, e.target.value)}>
                            <option value="Left">Left</option>
                            <option value="Right">Right</option>
                            <option value="Center">Center</option>
                        </Input>
                    </td>
                    <td>
                        <Input
                            type="select"
                            name="controlInputType"
                            id="controlInputType"
                            readOnly={readOnlyColumns}
                            disabled={readOnlyColumns}
                            value={column.inputType}
                            onChange={e => updateFieldChanged("inputType", index, e.target.value)}>
                            <option value="TextInput">Text input</option>
                            <option value="NumericInput">Numeric input</option>
                            <option value="DateInput">Date input</option>
                            <option value="ImageInput">Image input</option>
                            <option value="SignatureInput">Signature input</option>
                            <option value="GpsInput">GPS Input</option>
                            <option value="DropDown">Drop down</option>
                            <option value="Script">Script</option>
                        </Input>
                        {getCustomColumnInputs(column, index)}
                    </td>
                    <td>
                        <Input
                            type="text"
                            readOnly={readOnlyColumns && !tableControl.variable}
                            className="form-control"
                            id={"coltitle-" + index}
                            placeholder="Your value"
                            value={column.text}
                            onChange={e => updateFieldChanged("text", index, e.target.value)}
                        />
                    </td>
                    <td>
                        <Input
                            type="number"
                            readOnly={readOnlyColumns && !tableControl.variable}
                            className="form-control"
                            id={"colwidth-" + index}
                            placeholder="Width"
                            value={column.width}
                            onChange={e => updateFieldChanged("width", index, e.target.value)}
                        />

                    </td>
                    <td>
                        <div className="form-group col-auto">
                            {deleteColumnButton(index)}
                        </div>
                    </td>
                </tr>
            )
        })

        return tableColumns;
    }

    const getTableSummary = () => {
        return (
            <>
                <p>Table summary</p>
                <FormGroup>

                    <Label for="tableSum.scriptValue">Script</Label>
                    <FrostScriptEditor
                        id={"tableSum.scriptValue"}
                        variableSpace={variableSpace.getVariables()}
                        boundProperty={tableSum.scriptValue}
                        onChange={e => updateTableSummary("scriptValue", e)}
                        maxLines={15}
                        includeMethodSnippets={true}
                        placeholder="E.g {sum(c3)}"
                    />
                </FormGroup >
            </>
        )
    }

    const getVariableOutputEditor = () => {
        return (
            <FormGroup>

                <Label for="variableOutputName">Variable output name (Must be unique)</Label>
                <Input
                    type="text"
                    name="variableOutputName"
                    id="variableOutputName"
                    value={variableOutputName}
                    onChange={e => updateVariableOutputName(e.target.value)}>
                </Input>

            </FormGroup >
        )
    }


    // Should this table be a list selection?
    const getTableIsCheckTable = () => {
        return (
            <FormGroup>
                <Label for="isTableCheckList">Is this table a checklist table ( you need to select elements for the to be rendered to the pdf )</Label>
                <Input
                    type="select"
                    name="isTableCheckList"
                    id="isTableCheckList"
                    value={isTableCheckable}
                    onChange={e => updateIsTableCheckable(e.target.value)}>
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                </Input>
            </FormGroup >
        )
    }

    let getTableEditor = () => {

        return (
            <>
                <p>Configure input data</p>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Default value
                            </th>
                            <th>
                                Alignment
                            </th>
                            <th>
                                Input type
                            </th>

                            <th>
                                Header
                            </th>
                            <th>
                                Width (%)
                            </th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {getTableColumnData()}
                    </tbody>
                </Table>

                {getTableSummary()}

                <p>Other properties</p>

                {getVariableOutputEditor()}

                {getTableIsCheckTable()}

            </>
        )

    }

    const addColumnButton = () => {
        if (readOnlyColumns) {
            return (
                null
            );
        } else {
            return (
                <button onClick={e => addColumn()} className="btn btn-primary">Add Column</button>
            );
        }
    }

    return (
        <>
            <ModalBody>
                <div className="form-inline ml-auto">
                    {addColumnButton(tableControl)}
                    <VariableDropDownComponent
                        selectedVariable={tableControl.variable}
                        avaliableTableVariables={variableSpace.getTableSources()}
                        onSelectedItem={setSelectedVariable}
                    />
                </div>
                <br></br>
                {getTableEditor()}
                <Label for="variableSpace">Variable space</Label>
                <ReactJson src={variableSpace} collapsed={true} />

            </ModalBody >
            <div className=" modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => save()}>
                    Save changes
                </Button>
                <Button
                    className=" ml-auto"
                    color="link"
                    onClick={() => toggle()}
                    type="button"
                >
                    Cancel
                </Button>
            </div>
        </>
    );
}

export default TableEditor;