import React, { useState } from "react";

import { v4 as uuidv4 } from 'uuid';

const elementsImageStyle = { width: "30px", height: "30px", marginBottom: "15px" };

const ChoosableListControlElements = ({ addNewElement }) => {

    const getUserStepElements = () => {
        return (
            <>
                <div className="newWidget square" gs-w="12" gs-h="2" gs-min-w="3" data-elementtype="Title">
                    <div className="text-center" onClick={() => addNewElement(
                        {
                            id: uuidv4(),
                            element: { elementType: "Title", width: 12, height: 2, minWidth: 2, alignment: "Left", textValue: "AppToPdf is Awesome", anchorLevel: 0 }
                        })}>
                        <img
                            alt="..."
                            src={
                                require("assets/img/elements-icons/title.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            TITLE
                        </small>
                    </div>
                </div>

                <div className="newWidget square" gs-w="12" gs-h="1" gs-min-w="3" data-elementtype="StaticText">
                    <div onClick={() => addNewElement(
                        {
                            id: uuidv4(), element: {
                                elementType: "StaticText",
                                anchorLevel: 0,
                                width: 6,
                                height: 2,
                                minWidth: 3,
                                alignment: "Left",
                                fontSize: 9,
                                textColor: "#000000",
                                fontStyle: "Normal",
                                textValue: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas egestas metus id arcu placerat scelerisque. Suspendisse quis sapien sagittis, tincidunt magna in, condimentum velit."
                            }
                        })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/static_text.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            STATIC TEXT
                        </small>
                    </div>
                </div>

                <div className="newWidget square" gs-w="12" gs-h="1" gs-min-w="3" data-elementtype="LabeledText">
                    <div onClick={() => addNewElement(
                        {
                            id: uuidv4(), element: {
                                elementType: "LabeledText",
                                width: 6,
                                height: 2,
                                minWidth: 3,
                                alignment: "Left",
                                label: "I'm the text label",
                                fontSize: 9,
                                textColor: "#000000",
                                fontStyle: "Normal",
                                textValue: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas egestas metus id arcu placerat scelerisque. Suspendisse quis sapien sagittis, tincidunt magna in, condimentum velit."
                            }
                        })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/labeledtext.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            LABELED TEXT
                        </small>
                    </div>
                </div>

                
                <div className="newWidget square" gs-w="12" gs-h="1" gs-min-w="3" data-elementtype="LinkControl">
                    <div onClick={() => addNewElement(
                        {
                            id: uuidv4(), element: {
                                elementType: "LinkControl",
                                width: 6,
                                height: 2,
                                minWidth: 3,
                                alignment: "Left",
                                fontSize: 9,
                                textColor: "#0000EE",
                                fontStyle: "Normal",
                                linkText: "https://apptopdf.com/",
                                androidLink: "https://apptopdf.com/",
                                iosLink: "https://apptopdf.com/",
                                pdfLink: "https://apptopdf.com/"
                            }
                        })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/link.svg")
                                .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            LINK
                        </small>
                    </div>
                </div>

                <div style={{ marginTop: "15px" }} className="newWidget square" gs-w="12" gs-h="2" gs-min-w="6" data-elementtype="StaticImage">
                    <div onClick={() => addNewElement(
                        {
                            id: uuidv4(), element: {
                                elementType: "StaticImage",
                                width: 6,
                                height: 3,
                                minWidth: 2,
                            }
                        })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/static_image.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            STATIC IMAGE
                        </small>
                    </div>
                </div>

                <div className="newWidget square" gs-w="12" gs-h="2" gs-min-w="3" data-elementtype="TextInput">
                    <div onClick={() => addNewElement({ id: uuidv4(), element: { width: 6, elementType: "TextInput", height: 2, minWidth: 3, alignment: "Left", textValue: "Enter text here", includePromptInPdf: true, multiLineTextInput: false, inputType: "text" } })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/text_input.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            TEXT INPUT
                        </small>
                    </div>
                </div>

                <div style={{ marginTop: "15px" }} className="newWidget square" gs-w="12" gs-h="3" gs-min-w="6" data-elementtype="ImageInput">
                    <div onClick={() => addNewElement({ id: uuidv4(), element: { elementType: "ImageInput", imagesPerRow: 4, width: 12, height: 3, minWidth: 2, textValue: "Add images here", behavior: "multipleImages" } })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/image_input.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            IMAGE INPUT
                        </small>
                    </div>
                </div>

                <div className="newWidget square" gs-w="6" gs-h="1" gs-min-w="3" data-elementtype="CheckBox">
                    <div onClick={() => addNewElement({ id: uuidv4(), element: { width: 6, elementType: "CheckBox", height: 2, minWidth: 2, prompt: "Check me", defaultValue: false } })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/checkbox.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            CHECK BOX
                        </small>
                    </div>
                </div>

                
                <div style={{ marginTop: "15px" }} className="newWidget square" gs-w="12" gs-h="3" gs-min-w="3" data-elementtype="TextInfoGroup">
                    <div onClick={() => addNewElement({
                        id: uuidv4(), element: {
                            elementType: "TextInfoGroup",
                            width: 6,
                            height: 2,
                            minHeight: 2,
                            minWidth: 3,
                            prompt: "Information",
                            borderColor: "#f2f2f2",
                            borderStyle: "none",
                            borderRadius: 5,
                            backgroundColor: "#f2f2f2",
                            textInfosFontSize: 9,
                            promptFontSize: 22,
                            alignment: "Left",
                            textInfos: [
                                { label: "Company", value: "Frostcode AB", labelFontStyle: "Normal", labelColor: "#000000", valueFontStyle: "Normal", valueColor: "#000000" },
                                { label: "Product", value: "AppToPdf", labelFontStyle: "Normal", labelColor: "#000000", valueFontStyle: "Normal", valueColor: "#000000" }
                            ]
                        }
                    })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/textinfogroup.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            TEXT INFO GROUP
                        </small>
                    </div>
                </div>

                {/*
                <div className="newWidget square" gs-w="6" gs-h="1" gs-min-w="3" data-elementtype="DropDown">
                    <div onClick={() => addNewElement({
                        id: uuidv4(),
                        element: {
                            width: 6,
                            elementType: "DropDown",
                            height: 2,
                            minWidth: 2,
                            prompt: "Choose something",
                            values: [
                                { largeText: "Option 1", smallText: "Select option 1", value: 1 },
                                { largeText: "Option 2", smallText: "Select option 2", value: 2 }
                            ]
                        }
                    })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/dropdown.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            DROP DOWN
                        </small>
                    </div>
                </div>

                */}

                <div style={{ marginTop: "15px" }} className="newWidget square" gs-w="12" gs-h="3" gs-min-w="6" data-elementtype="TableView">
                    <div onClick={() => addNewElement({
                        id: uuidv4(), element: {
                            elementType: "TableView",
                            checkTable: false,
                            width: 12,
                            height: 3,
                            minHeight: 3,
                            minWidth: 6,
                            columns: [
                                { columnName: "Col1", width: "", text: "Col 1", alignment: "Left", inputType: "TextInput" },
                                { columnName: "Col2", width: "", text: "Col 2", alignment: "Left", inputType: "TextInput" },
                                { columnName: "Col3", width: "", text: "Col 3", alignment: "Left", inputType: "TextInput" },
                            ]
                        }
                    })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/table.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            TABLE
                        </small>
                    </div>
                </div>

                <div style={{ marginTop: "15px" }} className="newWidget square" gs-w="12" gs-h="2" gs-min-w="6" data-elementtype="SignatureInput">
                    <div onClick={() => addNewElement({ id: uuidv4(), element: { elementType: "SignatureInput", width: 6, height: 2, minWidth: 3, alignment: "Left", textValue: "Sign below" } })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/signature.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            SIGNATURE
                        </small>
                    </div>
                </div>

                <div className="newWidget square" gs-w="12" gs-h="2" gs-min-w="3" data-elementtype="ScriptElement">
                    <div onClick={() => addNewElement({ id: uuidv4(), element: { width: 6, elementType: "ScriptElement", height: 2, minWidth: 3, alignment: "Left", textValue: "Summary", includePromptInPdf: true, scriptValue: "" } })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/script_control.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            SCRIPT CONTROL
                        </small>
                    </div>
                </div>

                <div style={{ marginTop: "15px" }} className="newWidget square" gs-w="12" gs-h="3" gs-min-w="12" data-elementtype="ListControl">
                    <div onClick={() => addNewElement({
                        id: uuidv4(), element: {
                            elementType: "ListControl",
                            largeText: "Your new list control",
                            smallText: "Can be used to iterate through a flow and create a row for each new item",
                            elements: [],
                            variable: "",
                            variableOutputName: "",
                            width: 12,
                            height: 3,
                            minHeight: 3,
                            minWidth: 12
                        }
                    })}>
                        <img
                            alt="..."
                            className="mx-auto d-block"
                            src={
                                require("assets/img/elements-icons/table.svg")
                                    .default
                            }
                            style={elementsImageStyle}
                        ></img>
                        <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                            LIST CONTROL
                        </small>
                    </div>
                </div>

            </>
        );
    }

    return (
        <>
            {getUserStepElements()}
        </>
    );
}

export default ChoosableListControlElements;