export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

 export const validateEmailAddress = (email)  => {
    if(!validateEmail(email))
      return <small>Please enter a valid email adress</small>;

      return null;
  }

  export const validatePassword = (password)  => {
    if(password.length < 6)
      return <small>Please enter a password with at least 6 characters</small>;

      return null;
  }
