import React, { useState, useCallback } from "react";
import { useHistory, useRouteMatch, Route, Switch } from 'react-router-dom';

import {
    Button,
    Row,
    Col,
    Container
} from "reactstrap";

import UserStepDesigner from "./UserStepDesigner";

import SelectTemplateTypeModal from "./SelectTemplateTypeModal";

import AssignmentStepEditor from "./AssignmentStepEditor"

import mapHantverkarForm17Variables from "./customPdfForms/hantverkarForm17/hantverkarForlularet17Variables";

//import { Draggable } from "react-drag-reorder";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HantVerkarForm17StepEditor from "./customPdfForms/hantverkarForm17/HantVerkarForm17StepEditor";

/**
 * Renders a component for managing template steps.
 * @param {Object} props - The component props.
 * @param {Array} props.templateSteps - The array of template steps.
 * @param {Function} props.updateTemplate - The function to update the template.
 * @returns {JSX.Element} - The JSX element for the TemplateSteps component.
 */
const TemplateSteps = ({ templateSteps, updateTemplate }) => {

    var defaultAssignmentStep = {
        previousSteps: [],
        step: { type: "", value: "", index: -1 }
    }

    var defaultUserStep = {
        previousSteps: [],
        step: { type: "", controls: [], index: -1 }
    }

    var defaultHantverkarForm17Step = {
        previousSteps: [],
        step: { pdfFormPath: "", fieldMappings: [], type: "", controls: [], index: -1 }
    }

    const [selectStepTypeModalOpen, setSelectStepTypeModalOpen] = useState(false);
    const toggleSelecStepTypeModal = () => setSelectStepTypeModalOpen(!selectStepTypeModalOpen);

    const [currentModalAssignmentStep, setCurrentModalAssignmentStep] = useState(defaultAssignmentStep);
    const setModalAssignmentStep = (currentModalAssignmentStep) => setCurrentModalAssignmentStep(currentModalAssignmentStep);

    const [currentModalUsertStep, setCurrentModalUserStep] = useState(defaultUserStep);
    const setModalUserStep = (currentModalUsertStep) => setCurrentModalUserStep(currentModalUsertStep);

    const [currentModalHantverkarForm17Step, setCurrentModalHantverkarForm17] = useState(defaultHantverkarForm17Step);
    const setModalHantverkarForm17Step = (currentModalHantverkarForm17Step) => setCurrentModalHantverkarForm17(currentModalHantverkarForm17Step);

    const [assignmentStepModalOpen, setAssignmentStepModalOpen] = useState(false);
    const toggleAssignmentStep = () => setAssignmentStepModalOpen(!assignmentStepModalOpen);

    const [userStepModalOpen, setUserStepModalOpen] = useState(false);
    const toggleUserStepModal = () => setUserStepModalOpen(!userStepModalOpen);

    const [hantVerkarForm17StepModalOpen, setHantVerkarForm17StepModalOpen] = useState(false);
    const toggleHantverkarForm17StepModal = () => setHantVerkarForm17StepModalOpen(!hantVerkarForm17StepModalOpen);

    const elementsImageStyle = { width: "30px", height: "30px", marginBottom: "15px" };

    let addStep = (stepType) => {

        toggleSelecStepTypeModal();

        if (stepType === "assignmentStep") {
            addAssignmentStep();

        } else if (stepType === "userStep") {
            addUserStep();
        } else if (stepType === "pdfFormStep") {
            addHantverkarFurmolar17Step();
        } else {
            alert("Could't find matching step type: " + stepType)
        }
    }

    let addAssignmentStep = () => {

        if (templateSteps === undefined) {
            templateSteps = [];
        }

        var stepData = {
            previousSteps: templateSteps.slice(0, templateSteps.length),
            step: { name: "Assignment step", type: "assignmentStep", index: templateSteps.length, value: "{}" }
        }

        // Open the assignment step editor
        setCurrentModalAssignmentStep(stepData)
        toggleAssignmentStep();
    }

    let addUserStep = () => {

        if (templateSteps === undefined) {
            templateSteps = [];
        }

        var stepData = {
            previousSteps: templateSteps.slice(0, templateSteps.length),
            step: { name: "User Step", type: "userStep", index: templateSteps.length, controls: [] }
        }

        // Open the user step editor
        setCurrentModalUserStep(stepData);
        toggleUserStepModal();
    }

    let addHantverkarFurmolar17Step = () => {

        if (templateSteps === undefined) {
            templateSteps = [];
        }

        var hantverkarFormFieldMappings = mapHantverkarForm17Variables();

        var stepData = {
            previousSteps: templateSteps.slice(0, templateSteps.length),
            step: {
                name: "Hantverkarforlumläret 17",
                pdfFormPath: "https://firebasestorage.googleapis.com/v0/b/apptopdf.appspot.com/o/customPdfForms%2Fhantverkarformularet-2017-fieldnames.pdf?alt=media&token=883de229-6239-4076-9276-f6ad9d647ac4",
                type: "pdfFormStep",
                index: templateSteps.length,
                fieldMappings: hantverkarFormFieldMappings
            }
        }

        // Open the user step editor
        setCurrentModalHantverkarForm17(stepData);
        toggleHantverkarForm17StepModal();
    }

    let getAddStepButton = () => {
        return (

            <Button
                style={{ marginLeft: '-25px' }}
                className="my-4"
                color="default"
                type="button"
                onClick={() => toggleSelecStepTypeModal()}>
                Add step
            </Button>

        )
    }

    let buildStepEditorModel = (step) => {
        return {
            previousSteps: templateSteps.slice(0, step.index),
            step: step
        }
    }

    let save = (step) => {

        if (templateSteps === undefined) {
            templateSteps = [];
        }

        if(step.type === "pdfFormStep"){
            //Need to assign field mappings as pure js object
            const fieldMappings = step.fieldMappings.map((obj)=> {return Object.assign({}, obj)}); 
            step.fieldMappings = fieldMappings;
        }

        let newSteps = [...templateSteps];

        if (newSteps.length <= step.index) {
            newSteps.push(step);
        } else {
            newSteps[step.index] = step;
        }

        updateTemplate("steps", newSteps)

        if (step.type === "assignmentStep") {
            toggleAssignmentStep()
        } else if (step.type === "userStep") {
            toggleUserStepModal();
        } else if (step.type === "pdfFormStep") {
            toggleHantverkarForm17StepModal();
        } else {
            alert("Could´t find matching step type: " + step.type)
        }
    }

    /**
     * Opens the appropriate modal for the given step type.
     * @param {Object} step - The step object to edit.
     */
    let openEditor = (step) => {
        if (step.type === "userStep") {
            setCurrentModalUserStep(buildStepEditorModel(step));
            toggleUserStepModal();
        } else if (step.type === "assignmentStep") {
            setCurrentModalAssignmentStep(buildStepEditorModel(step))
            toggleAssignmentStep();
        } else if (step.type === "pdfFormStep") {
            setCurrentModalHantverkarForm17(buildStepEditorModel(step))
            toggleHantverkarForm17StepModal();
        }
    }

    /**
     * Deletes a step from the template steps array and updates the template.
     * @param {number} index - The index of the step to be deleted.
     * @param {string} stepType - The type of the step to be deleted.
     */
    let deleteStep = (index, stepType) => {

        var step = templateSteps[index];

        if (!window.confirm("Are you sure you want to delete: " + step.name + " ? This in inreversable!")) {
            return;
        }

        if (stepType === "userStep") {
            toggleUserStepModal();
        } else if (stepType === "assignmentStep") {
            toggleAssignmentStep();
        } else if (stepType === "pdfFormStep") {
            toggleHantverkarForm17StepModal();
        } else {
            alert("Could not find matching step type: " + stepType);
            return;
        }

        let stepsCopy = [...templateSteps];

        stepsCopy.splice(index, 1);

        stepsCopy.forEach((step, index) => {
            step.index = index;
        });

        updateTemplate("steps", stepsCopy);

    }

    let getStepIcon = (stepType) => {
        if (stepType === "userStep") {
            return (
                <img
                    alt="..."
                    className="mx-auto d-block"
                    src={
                        require("assets/img/steps/user.svg")
                            .default
                    }
                    style={elementsImageStyle}
                ></img>
            )
        } else if (stepType === "assignmentStep") {
            return (
                <img
                    alt="..."
                    className="mx-auto d-block"
                    src={
                        require("assets/img/steps/assignment.svg")
                            .default
                    }
                    style={elementsImageStyle}
                ></img>
            )
        } else if (stepType === "pdfFormStep") {
            return (
                <img
                    alt="..."
                    className="mx-auto d-block"
                    src={
                        require("assets/img/mainIcons/icons8-pdf-100.png")
                            .default
                    }
                    style={elementsImageStyle}
                ></img>
            )
        }
    }

    let getStepDescription = (stepType) => {
        if (stepType === "userStep") {
            return (
                <p>A data collection step</p>
            )
        } else if (stepType === "assignmentStep") {
            return (
                <p>A step that assigns variables</p>
            )
        } else if (stepType === "pdfFormStep") {
            return (
                <p>Skapar Hantverkarformluäret-17</p>
            )
        } else {
            alert("Could not find matching step type: " + stepType);
        }
    }

    let immutableMove = (arr, from, to) => {
        return arr.reduce((prev, current, idx, self) => {
            if (from === to) {
                prev.push(current);
            }
            if (idx === from) {
                return prev;
            }
            if (from < to) {
                prev.push(current);
            }
            if (idx === to) {
                prev.push(self[from]);
            }
            if (from > to) {
                prev.push(current);
            }
            return prev;
        }, []);
    }

    let getChangedPos = (currentPos, newPos) => {

        console.log(currentPos, newPos);

        let stepsCopy = [...templateSteps];

        stepsCopy = immutableMove(stepsCopy, currentPos, newPos);

        stepsCopy.forEach((element, index) => {
            element.index = index;
        });

        updateTemplate("steps", stepsCopy);
    };

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;

        getChangedPos(droppedItem.source.index, droppedItem.destination.index);
    };

    const getReorderableTemplateSteps = () => {

        if (!templateSteps) {
            return null;
        }

        return (
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                        <div
                            className="list-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {templateSteps.map((step, index) => (

                                <Draggable key={"step-drag-" + index} draggableId={"step-drag-" + index} index={index}>
                                    {(provided) => (

                                        <div
                                            key={"step" + index}
                                            className="step-style"
                                            onClick={() => openEditor(step)}
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}>
                                            <div className="step-style-square" style={{ cursor: "pointer" }} key={"templateStep-" + index}>
                                                <div>
                                                    {getStepIcon(step.type)}
                                                    <small className="text-center d-block text-uppercase font-weight-bold mb-4">
                                                        <strong> {step.type} </strong>
                                                    </small>

                                                </div>
                                            </div>
                                            <div className="step-style-name">
                                                <strong>{step.name}</strong>
                                                {getStepDescription(step.type)}
                                            </div>
                                        </div>

                                    )}
                                </Draggable>

                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    return (
        <>

            <Container fluid>
                {getReorderableTemplateSteps()}
            </Container>
            <Container fluid>
                {getAddStepButton()}
            </Container>

            <SelectTemplateTypeModal
                isOpen={selectStepTypeModalOpen}
                toggle={toggleSelecStepTypeModal}
                selectStepType={addStep}
            />

            <UserStepDesigner
                isOpen={userStepModalOpen}
                toggle={toggleUserStepModal}
                userStep={currentModalUsertStep}
                saveChanges={save}
                deleteStep={deleteStep}
            />

            <AssignmentStepEditor
                isOpen={assignmentStepModalOpen}
                toggle={toggleAssignmentStep}
                assignmentStep={currentModalAssignmentStep}
                saveChanges={save}
                deleteStep={deleteStep}
            >
            </AssignmentStepEditor>

            <HantVerkarForm17StepEditor
                isOpen={hantVerkarForm17StepModalOpen}
                toggle={toggleHantverkarForm17StepModal}
                hantVerkarForm17Step={currentModalHantverkarForm17Step}
                saveChanges={save}
                deleteStep={deleteStep}
            >
            </HantVerkarForm17StepEditor>
        </>
    )

}

export default TemplateSteps;