// reactstrap components
import React, { useState, useEffect } from "react";

import ReactJson from 'react-json-view'


import { getCurrentVariableSpaceFromStep } from './StepVariableSpaceResolver';

import FrostScriptEditor from "./FrostScriptEditor";

import {
    Button,
    FormGroup,
    InputGroup,
    Modal,
    ModalBody,
    Label,
    Input,
    Form,
    Row,
    Col,
    ButtonGroup
} from "reactstrap";

const AssignmentStepEditor = ({ isOpen, toggle, assignmentStep, saveChanges, deleteStep }) => {

    const [currentAssignmentStepDataModel, setCurrentAssignmentStepDataModel] = useState({ ...assignmentStep });

    const [variableSpace, setVariableSpace] = useState({});

    const [resolvedVariableSpace, setResolvedVariableSpace] = useState(false);


    useEffect(() => {

        var actionRef = setCurrentAssignmentStepDataModel(assignmentStep);
        setStepName(assignmentStep.step.name);

        getCurrentVariableSpaceFromStep(assignmentStep.previousSteps)
            .then(data => {
                setResolvedVariableSpace(true);
                setVariableSpace(data);
            });

        return actionRef;
    }, [assignmentStep])

    useEffect(() => {
    }, [currentAssignmentStepDataModel])

    const [stepName, setStepName] = useState("")

    /**
     * Updates the local assignment step with the given value.
     * @param {any} value - The new value to assign to the step.
     */
    let updateLocalAssignmentStep = (value) => {
        var assigned = { ...currentAssignmentStepDataModel };
        assigned.step.value = value;
        setCurrentAssignmentStepDataModel(assigned);
    }

    /**
     * Saves the changes made to the current assignment step data model.
     * If the step index is not defined, it sets it to 0.
     * @function
     * @returns {void}
     */
    let save = () => {
        if (currentAssignmentStepDataModel.step.index === -1 || currentAssignmentStepDataModel.step.index === undefined) {
            currentAssignmentStepDataModel.step.index = 0;
        }

        currentAssignmentStepDataModel.step.name = stepName;

        saveChanges(currentAssignmentStepDataModel.step);
    }

    if (currentAssignmentStepDataModel === undefined) {
        return null;
    }

    function onChange(newValue) {
        console.log("change", newValue);
    }

    /**
     * Returns loading content to display while resolving variable space.
     * @returns {JSX.Element} Loading content JSX element.
     */
    let getLoadingCOntent = () => {
        <Row>
            <Col lg="12">
                <Label>Resolving variable space...</Label>
            </Col>
        </Row>

    }

    /**
     * Returns a React component that renders a form for editing an assignment step.
     * @returns {React.Component} A React component that renders a form for editing an assignment step.
     */
    let getScriptContent = () => {
        return (
            <Row>
                <Col lg="3">
                    <Form>
                        <FormGroup>
                            <Label for="stepName">STEP NAME</Label>
                            <Input type="name" name="name" id="stepName" placeholder="Enter name..." value={stepName} onChange={e => setStepName(e.target.value)} />
                        </FormGroup>
                    </Form>
                </Col>
                <Col lg="9">
                    <FormGroup>
                        <Label for="assignmentScript">SCRIPT VALUE</Label>

                        <FrostScriptEditor
                            id={"assignmentScript"}
                            variableSpace={{...variableSpace.Variables}}
                            boundProperty={currentAssignmentStepDataModel.step.value}
                            onChange={e => updateLocalAssignmentStep(e)}
                            includeMethodSnippets={true}
                        />

                    </FormGroup >
                    <FormGroup>
                        <Label for="variableSpace">Current variable space</Label>
                        <ReactJson src={variableSpace} collapsed={true} />
                    </FormGroup >

                </Col>

            </Row>
        );
    }

    /**
     * Returns the content of the script based on the resolved variable space.
     * If the variable space is not resolved, returns the loading content.
     * @returns {string} The content of the script.
     */
    let getContent = () => {
        if (!resolvedVariableSpace) {
            return getLoadingCOntent();
        }

        return getScriptContent();
    }

    return (
        <Modal
            className="modal-dialog-centered  modal-xl"
            isOpen={isOpen}
            toggle={toggle}>
            {
                <ModalBody>
                    {getContent()}
                </ModalBody >
            }
            <div className=" modal-footer">
                <ButtonGroup>
                    <Button
                        color="primary"
                        onClick={() => save()}>
                        SAVE CHANGES
                    </Button>
                    <Button
                        color="success"
                        onClick={() => toggle()}>
                        CANCEL
                    </Button>
                </ButtonGroup>
                <Button
                    className=" ml-auto"
                    color="link"
                    onClick={() => deleteStep(currentAssignmentStepDataModel.step.index, "assignmentStep")}
                    type="button"
                >
                    DELETE
                </Button>
            </div>
        </Modal>
    );
}

export default AssignmentStepEditor;