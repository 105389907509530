import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody
} from "reactstrap";

import VariableDropDownComponent from "./VariableDropDownComponent";
import ReactJson from 'react-json-view'

import FrostScriptEditor from "../steps/FrostScriptEditor";

const ScriptEditor = ({ scriptInputElement: scriptInputElement, updateElement, variableSpace }) => {

    const updateBooleanField = (stateName, value) => {
        if (value === "true") {
            updateElement(stateName, true);
        } else {
            updateElement(stateName, false);
        }
    }

    return (
        <>
            <FormGroup>

                <Label for="scriptInputEditorPrompt">Prompt Text</Label>
                <FrostScriptEditor
                    id={"scriptInputEditorPrompt"}
                    variableSpace={variableSpace.getVariables()}
                    boundProperty={scriptInputElement.textValue}
                    onChange={e => updateElement("textValue", e)}
                    maxLines={3}
                    includeMethodSnippets={false}
                />

            </FormGroup >
            <FormGroup>

                <Label for="alignment">Control Alignment</Label>
                <Input
                    type="select"
                    name="alignment"
                    id="alignment"
                    value={scriptInputElement.alignment}
                    onChange={e => updateElement("alignment", e.target.value)}>
                    <option value="Left">Left</option>
                    <option value="Right">Right</option>
                    <option value="Center">Center</option>
                </Input>
            </FormGroup >

            <FormGroup>

                <Label for="includePromptInPdf">Include prompt in PDF</Label>
                <Input
                    type="select"
                    name="includePromptInPdf"
                    id="includePromptInPdf"
                    value={scriptInputElement.includePromptInPdf}
                    onChange={e => updateBooleanField("includePromptInPdf", e.target.value)}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </Input>
            </FormGroup >
            <FormGroup>

                <Label for="scriptEditor">Script value</Label>
                <FrostScriptEditor
                    id={"scriptEditor"}
                    variableSpace={variableSpace.getVariables()}
                    boundProperty={scriptInputElement.scriptValue}
                    onChange={e => updateElement("scriptValue", e)}
                    maxLines={15}
                    includeMethodSnippets={false}
                />

            </FormGroup >

        </>

    );
}

export default ScriptEditor;