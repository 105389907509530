import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody,
    Table,
    UncontrolledCollapse,
    Button
} from "reactstrap";

import ReactJson from 'react-json-view'

import React, { useState, useEffect } from 'react';

import FrostScriptEditor from "../steps/FrostScriptEditor";

const TextInfoGroupEditor = ({ textInfoElement, updateElement, toggle, saveChanges, itemId, variableSpace }) => {

    const [textInfos, setTextInfos] = useState(textInfoElement.textInfos);

    React.useEffect(() => {

    }, [textInfos])

    // Continue with save. Double check so that all variables are mapped. FontSize? Bold left, right?

    const updateFieldChanged = (name, index, value) => {

        let editedColumns = [...textInfos];

        debugger;
        editedColumns[index][name] = value;

        setTextInfos(editedColumns);
    }

    const addRow = () => {
        let values = [...textInfos];

        values.push({ "label": "", "value": "" });

        setTextInfos(values);
    }

    const deleteTextInfo = (index) => {
        if (textInfos.length == 1) {
            alert("A text info control needs at least one row");
            return;
        } else {
            let editedTextInfos = [...textInfos];
            editedTextInfos.splice(index, 1);
            setTextInfos(editedTextInfos);
        }
    }

    const save = () => {
        textInfoElement.textInfos = textInfos;

        saveChanges(textInfoElement, itemId);
    }

    let getTextInfos = () => {
        return (
            textInfos.map((column, index) => {
                return (
                    <tr
                        key={"rowId-" + index}
                        className="no-border"
                    >
                        <td
                            key={"label-" + index}
                            className="text-left">
                            <FrostScriptEditor
                                id={"labelInput-" + index}
                                variableSpace={variableSpace.getVariables()}
                                boundProperty={column.label}
                                onChange={e => updateFieldChanged("label", index, e)}
                                maxLines={2}
                                includeMethodSnippets={false}
                            />
                        </td>
                        <td>
                            <Input
                                type="select"
                                name="labelFontStyle"
                                id="labelFontStyle"
                                value={column.labelFontStyle}
                                onChange={e => updateFieldChanged("labelFontStyle", index, e.target.value)}>
                                <option value="normal">Normal</option>
                                <option value="Bold">Bold</option>
                                <option value="Italic">Italic</option>
                            </Input>
                        </td>
                        <td>
                            <Input
                                type="color"
                                name="labelColor"
                                value={column.labelColor}
                                onChange={e => updateFieldChanged("labelColor", index, e.target.value)}
                                id="labelColor"
                                placeholder="color placeholder" />
                        </td>
                        <td
                            key={"value-" + index}
                            className="text-right">
                            <FrostScriptEditor
                                id={"valueInput-" + index}
                                variableSpace={variableSpace.getVariables()}
                                boundProperty={column.value}
                                onChange={e => updateFieldChanged("value", index, e)}
                                maxLines={2}
                                includeMethodSnippets={false}
                            />
                        </td>
                        <td>
                            <Input
                                type="select"
                                name="valueFontStyle"
                                id="valueFontStyle"
                                value={column.valueFontStyle}
                                onChange={e => updateFieldChanged("valueFontStyle", index, e.target.value)}>
                                <option value="normal">Normal</option>
                                <option value="Bold">Bold</option>
                                <option value="Italic">Italic</option>
                            </Input>
                        </td>
                        <td>
                            <Input
                                type="color"
                                name="valueColor"
                                value={column.valueColor}
                                onChange={e => updateFieldChanged("valueColor", index, e.target.value)}
                                id="valueColor"
                                placeholder="color placeholder" />
                        </td>
                        <td>
                            <div className="text-right">
                                <button onClick={e => deleteTextInfo(index)} className="btn btn-danger">x</button>
                            </div>
                        </td>
                    </tr>
                )
            })
        );
    }

    return (
        <>
            <ModalBody>

                <FormGroup>
                    <Label for="prompt">Prompt</Label>
                    <FrostScriptEditor
                        id={"prompt"}
                        variableSpace={variableSpace.getVariables()}
                        boundProperty={textInfoElement.prompt}
                        onChange={e => updateElement("prompt", e)}
                        maxLines={2}
                        includeMethodSnippets={false}
                    />
                </FormGroup >
                <FormGroup>

                    <Label for="alignment">Prompt Alignment</Label>
                    <Input
                        type="select"
                        name="alignment"
                        id="alignment"
                        value={textInfoElement.alignment}
                        onChange={e => updateElement("alignment", e.target.value)}>
                        <option value="Left">Left</option>
                        <option value="Right">Right</option>
                        <option value="Center">Center</option>
                    </Input>
                </FormGroup >

                <Button color="info" id="toggler" style={{ marginBottom: '2rem' }}>
                    Style options
                </Button>
                <UncontrolledCollapse toggler="#toggler">
                    <div className="col-12">
                        <FormGroup>
                            <Label for="promptFontSize">Prompt Font Size</Label>
                            <Input
                                type="select"
                                name="promptFontSize"
                                id="promptFontSize"
                                value={textInfoElement.promptFontSize}
                                onChange={e => updateElement("promptFontSize", parseInt(e.target.value))}>
                                <option value={7}>7</option>
                                <option value={9}>9</option>
                                <option value={12}>12</option>
                                <option value={16}>16</option>
                                <option value={20}>20</option>
                                <option value={24}>24</option>
                            </Input>
                        </FormGroup >
                        <FormGroup>
                            <Label for="textInfosFontSize">Text Infos Font Size</Label>
                            <Input
                                type="select"
                                name="textInfosFontSize"
                                id="textInfosFontSize"
                                value={textInfoElement.textInfosFontSize}
                                onChange={e => updateElement("textInfosFontSize", parseInt(e.target.value))}>
                                <option value={7}>7</option>
                                <option value={9}>9</option>
                                <option value={12}>12</option>
                                <option value={16}>16</option>
                                <option value={20}>20</option>
                                <option value={24}>24</option>
                            </Input>
                        </FormGroup >
                        <FormGroup>
                            <Label for="alignment">Border style</Label>
                            <Input
                                type="select"
                                name="alignment"
                                id="alignment"
                                value={textInfoElement.borderStyle}
                                onChange={e => updateElement("borderStyle", e.target.value)}>
                                <option value="none">None</option>
                                <option value="solid">Solid</option>
                                <option value="dashed">Dashed</option>
                                <option value="dotted">Dotted</option>
                            </Input>
                        </FormGroup >
                        <FormGroup>
                            <Label for="borderColor">Border Color</Label>
                            <Input
                                type="color"
                                name="borderColor"
                                value={textInfoElement.borderColor}
                                onChange={e => updateElement("borderColor", e.target.value)}
                                id="borderColor"
                                placeholder="color placeholder" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="backgroundColor">Background Color</Label>
                            <Input
                                type="color"
                                name="backgroundColor"
                                value={textInfoElement.backgroundColor}
                                onChange={e => updateElement("backgroundColor", e.target.value)}
                                id="backgroundColor"
                                placeholder="color placeholder" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="borderRadius">Border Radius</Label>
                            <Input
                                type="select"
                                name="borderRadius"
                                id="borderRadius"
                                value={textInfoElement.borderRadius}
                                onChange={e => updateElement("borderRadius", parseInt(e.target.value))}>
                                <option value="0">0</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                            </Input>
                        </FormGroup>
                    </div>
                </UncontrolledCollapse>
                <FormGroup>

                    <Label for="fontSize">Text infos</Label>
                    <Table>
                        <thead>
                            <th style={{ width: "25%" }}>
                                Label
                            </th>
                            <th style={{ width: "15%" }}>
                                Font style
                            </th>
                            <th style={{ width: "10%" }}>
                                Text color
                            </th>
                            <th style={{ width: "25%" }}>
                                Value
                            </th>
                            <th style={{ width: "15%" }}>
                                Font style
                            </th>
                            <th style={{ width: "10%" }}>
                                Text color
                            </th>
                            <th></th>
                        </thead>
                        <tbody>
                            {getTextInfos()}
                        </tbody>
                        <tfoot>
                            <th>
                                <Button color="info" onClick={e => addRow(textInfoElement)}>Add Row</Button>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tfoot>
                    </Table>
                </FormGroup >

                <Label for="variableSpace">Variable space</Label>
                <ReactJson src={variableSpace} collapsed={true} />

            </ModalBody >
            <div className=" modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => save()}>
                    Save changes
                </Button>
                <Button
                    className=" ml-auto"
                    color="link"
                    onClick={() => toggle()}
                    type="button"
                >
                    Cancel
                </Button>
            </div>

        </>
    );
}

export default TextInfoGroupEditor;