import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody
} from "reactstrap";

import VariableDropDownComponent from "./VariableDropDownComponent";

const CameraInputEditor = ({ cameraInputElement, updateElement, variableSpace }) => {


    let isSingleImageInput = (imgElement) => {
        if (imgElement.behavior && imgElement.behavior == "singleImage") {
            return false;
        }

        return true;
    }

    let getChooseImagesPerRow = (imgElement) => {
        if (isSingleImageInput(imgElement)) {
            return (
                <FormGroup>
                <Label for="imagesPerRowEditor">Images Per Row (1-4)</Label>
                <Input
                    disabled={!isSingleImageInput(imgElement)}
                    placeholder="Text"
                    min="1" max="4"
                    type="number"
                    value={imgElement.imagesPerRow}
                    id="imagesPerRowEditor"
                    onChange={e => updateElement("imagesPerRow", parseInt(e.target.value))} />
            </FormGroup >
            );
        }
        return null;
    }

    let getVariableDropDownSource = (imgElement) => {
        if (isSingleImageInput(imgElement)) {
            return (
                <FormGroup>
                    <Label for="dropDown">Images source</Label>
                    <VariableDropDownComponent
                        selectedVariable={imgElement.variable}
                        avaliableTableVariables={variableSpace.getImageInputSources()}
                        onSelectedItem={e => updateElement("variable", e)}
                        dropDownSource={true}
                    />
                </FormGroup >
            );
        }
        return null;
    }

    return (
        <>
            <FormGroup>
                <Label for="titleEditor">Prompt</Label>
                <Input
                    placeholder="Text"
                    type="text"
                    value={cameraInputElement.textValue}
                    id="titleEditor"
                    onChange={e => updateElement("textValue", e.target.value)} />
            </FormGroup >
            <FormGroup>

                <Label for="behavior">Behavior</Label>
                <Input
                    type="select"
                    name="behavior"
                    id="behavior"
                    value={cameraInputElement.behavior}
                    onChange={e => updateElement("behavior", e.target.value)}>
                    <option value="multipleImages">Multiple images</option>
                    <option value="singleImage">Single image</option>
                </Input>
            </FormGroup >
            {getChooseImagesPerRow(cameraInputElement)}
            {getVariableDropDownSource(cameraInputElement)}
            <FormGroup>

                <Label for="variableOutputName">Variable output name (Must be unique)</Label>
                <Input
                    type="text"
                    name="variableOutputName"
                    id="variableOutputName"
                    placeholder="imageInput1"
                    value={cameraInputElement.variableOutputName}
                    onChange={e => updateElement("variableOutputName", e.target.value)}>
                </Input>

            </FormGroup >

        </>


    );
}

export default CameraInputEditor;