import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-csharp";
import 'ace-builds/src-noconflict/mode-c_cpp'
import "ace-builds/src-noconflict/theme-textmate";
import langTools from "ace-builds/src-noconflict/ext-language_tools";

/**
 * A component that renders an AceEditor with FrostScript syntax highlighting and autocompletion.
 * @param {Object} props - The component props.
 * @param {Object} props.variableSpace - An object containing variables to be used in the editor's autocompletion.
 * @param {string} props.boundProperty - The value of the editor.
 * @param {function} props.onChange - A function to be called when the editor's value changes.
 * @param {number} props.maxLines - The maximum number of lines to be displayed in the editor.
 * @param {boolean} props.includeMethodSnippets - A flag indicating whether to include method snippets in the editor's autocompletion.
 * @param {string} props.id - The id of the editor.
 * @param {boolean} props.readOnly - A flag indicating whether the editor is read-only.
 * @param {boolean} props.disabled - A flag indicating whether the editor is disabled.
 * @param {string} props.placeholder - The placeholder text to be displayed in the editor.
 * @returns {JSX.Element} A JSX element representing the FrostScriptEditor component.
 */
const FrostScriptEditor = ({ variableSpace, boundProperty, onChange, maxLines, includeMethodSnippets, id, readOnly, disabled, placeholder }) => {

    /**
     * Returns an array of objects containing caption, snippet, and type properties.
     * These objects represent the available FrostScript methods.
     *
     * @returns {Array} An array of objects containing caption, snippet, and type properties.
     */
    let getFrostCodeMethods = () => {
        return [
            {
                caption: `DropDownRow(); Will create a dropdown row`,
                snippet: 'DropDownRow("largeText", "smallText", {"value": 1});',
                type: "snippet"
            }, {
                caption: `TableFromRows(); Will create default tablefrom rows`,
                snippet: 'TableFromRows([TextColumn("text")], [{"A text"}]);',
                type: "snippet"
            }, {
                caption: `TextColumn(); Will create default text column`,
                snippet: 'TextColumn("name");',
                type: "snippet"
            }, {
                caption: `NumericColumn(); Will create default numeric column`,
                snippet: 'NumericColumn("name");',
                type: "snippet"
            }, {
                caption: `ScriptColumn(); Will create a default script column`,
                snippet: 'ScriptColumn("name", @"{format(variable1*variable2, "C2")}"@);',
                type: "snippet"
            }, {
                caption: `DropDownSource(); Will create a Drop Down Source`,
                snippet: 'DropDownSource([DropDownRow("largeText", "smallText", {"value": 1})]);',
                type: "snippet"
            }, {
                caption: `ItemCount(array); will count the items of an array`,
                snippet: 'ItemCount(array)',
                type: "snippet"
            }, {
                caption: `RowCount(tableVariable); counts the items in a table`,
                snippet: 'RowCount(tableVariable)',
                type: "snippet"
            }, {
                caption: `Format(variable, "C2"); formats an int to price and prints it`,
                snippet: 'Format(variable, "C2")',
                type: "snippet"
            }, {
                caption: `Sum(variable); sums an numeric array`,
                snippet: 'Sum(variable)',
                type: "snippet"
            }, {
                caption: `Min2(numericVariable, numericVariable2); compares and returns the smallest value`,
                snippet: 'Min2(numericVariable, numericVariable2)',
                type: "snippet"
            }, {
                caption: `AddTableColumns(oldTableVar, columnsDefinitions); ads more columns to an existing table variable`,
                snippet: 'AddTableColumns(oldTableVar, columnsDefinitions)',
                type: "snippet"
            }, {
                caption: `ColumnDefinitions(); Creates a default column definition`,
                snippet: '[NumericColumn("numericColumn"), TextColumn("textColumn")]',
                type: "snippet"
            }, {
                caption: `__pausedTemplateSuggestedName: If you set this, the suggested name for resuming and generated pdf name will be this.`,
                snippet: '__pausedTemplateSuggestedName',
                type: "snippet"
            }
        ];
    }

    /**
     * Object that provides autocompletion suggestions for FrostScriptEditor.
     * @type {Object}
     * @property {Function} getCompletions - Function that returns an array of completion suggestions.
     */
    var myCompleter = {
        getCompletions: function (editor, session, pos, prefix, callback) {

            var completions = [];

            if(variableSpace) {
 
                Object.entries(variableSpace).forEach(function([key, val], idx, arr){

                    completions.push({
                        value: key,
                        meta: JSON.stringify(val),
    
                    });
                });
            }

            if (includeMethodSnippets) {
                getFrostCodeMethods().map((frostCodeFunction) => {
                    completions.push(frostCodeFunction);
                });
            }

            callback(null, completions);
        }
    }

    langTools.addCompleter(myCompleter);

    return (
        <AceEditor
            id={id}
            fontSize={15}
            mode="csharp"
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            theme="textmate"
            value={boundProperty}
            onChange={e => onChange(e)}
            name="UNIQUE_ID_OF_DIV"
            maxLines={maxLines}
            width={"100%"}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                autoScrollEditorIntoView: true,
                printMargin: false,
                showGutter: false,
                hScrollBarAlwaysVisible: false,
                vScrollBarAlwaysVisible: false
            }}
            editorProps={{ $blockScrolling: true }}
        />
    )

}

/**
 * A component for editing Frost scripts.
 *
 * @component
 */
export default FrostScriptEditor;