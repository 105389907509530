import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody
} from "reactstrap";

import VariableDropDownComponent from "./VariableDropDownComponent";


const SignatureInputEditor = ({ signatureInputElement, updateElement, variableSpace }) => {
    return (
        <>
            <FormGroup>
                <Label for="textInputEditor">Prompt Text</Label>
                <Input
                    placeholder="Text"
                    type="text"
                    value={signatureInputElement.textValue}
                    id="textInputEditor"
                    onChange={e => updateElement("textValue", e.target.value)} />

            </FormGroup >
            <FormGroup>

                <Label for="alignment">Control Alignment</Label>
                <Input
                    type="select"
                    name="alignment"
                    id="alignment"
                    value={signatureInputElement.alignment}
                    onChange={e => updateElement("alignment", e.target.value)}>
                    <option value="Left">Left</option>
                    <option value="Right">Right</option>
                    <option value="Center">Center</option>
                </Input>
            </FormGroup >
            <FormGroup>
                <Label for="dropDown">Signature source</Label>
                <VariableDropDownComponent
                    selectedVariable={signatureInputElement.variable}
                    avaliableTableVariables={variableSpace.getSignatureInputSources()}
                    onSelectedItem={e => updateElement("variable", e)}
                    dropDownSource={true}
                />
            </FormGroup >
            <FormGroup>

                <Label for="variableOutputName">Variable output name (Must be unique)</Label>
                <Input
                    type="text"
                    name="variableOutputName"
                    id="variableOutputName"
                    placeholder="signature1"
                    value={signatureInputElement.variableOutputName}
                    onChange={e => updateElement("variableOutputName", e.target.value)}>
                </Input>

            </FormGroup >
        </ >

    );
}

export default SignatureInputEditor;