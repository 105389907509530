/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Templates from "views/examples/Templates.js";
import EnterpriceAccount from "views/examples/EnterpriceAccount.js";
import ForgotPassword from "./views/examples/ForgotPassword";


var routes = [
  {
    path: "/templates",
    name: "Templates",
    icon: "ni ni-single-copy-04 text-white",
    component: Templates,
    layout: "/admin",
  },
  {
    path: "/my-account",
    name: "My Account",
    icon: "ni ni-single-02 text-white",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-white",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-lock-circle-open text-white",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Register",
    icon: "ni ni-circle-08 text-white",
    component: ForgotPassword,
    layout: "/auth",
  },
];


export default routes;
