import React, { useState, useEffect } from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table,
    Badge,
} from "reactstrap";

import ClipLoader from "react-spinners/ClipLoader";
import firebaseApp from "firebaseConfig";

const AccountsYouAreInvitedTo = ({ userId, myEmailAddress }) => {

    const [downloadingCompleted, setdownloadingCompleted] = useState(false);
    const [accountInvites, setAccountInvites] = useState([]);

    const approveInvite = (index, accountName) => {

        //TODO: Save to firebase

        accountInvites[index].data.status = "approved";

        setdownloadingCompleted(false);

        firebaseApp.firestore().collection("AccountInvites").doc(accountInvites[index].id).set(accountInvites[index].data).then(() => {

            setAccountInvites(accountInvites)

            setdownloadingCompleted(true);

        }).catch((error) => {
            alert("Error updating invite: " + error);
        });

    }

    const declineInvite = (index, accountName) => {
        var remove = window.confirm("Are you sure you don´t want see templates from: " + accountName + " anymore?");

        if (remove) {
            //TODO: Save to firebase

            var accountInvite = accountInvites[index];
            accountInvite.data.status = "declined";
          
            firebaseApp.firestore().collection("AccountInvites").doc(accountInvite.id).set(accountInvite.data).then(() => {

                accountInvites.splice(index, 1);

                setAccountInvites(accountInvites)

                setdownloadingCompleted(true);

            }).catch((error) => {
                alert("Error updating invite: " + error);
            });
        }
    }

    const getStatus = (status, index, accountName) => {

        if (status === "approved") {
            return (
                <>
                    <td>
                        <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            {status}
                        </Badge>
                    </td>
                    <td className="float-right">
                        <Button
                            className="float-right"
                            href="#"
                            onClick={(e) => declineInvite(index, accountName)}
                            size="sm"
                        >
                            Remove me from this account
                         </Button>
                    </td>
                </>
            )
        } else if (status === "pending") {
            return (
                <>
                    <td>
                        <Badge color="" className="badge-dot mr-4">
                            <i className="bg-info" />
                            {status}
                        </Badge>
                    </td>
                    <td className="text-right">
                        <Button
                            color="default"
                            href="#"
                            onClick={(e) => approveInvite(index, accountName)}
                            size="sm"
                        >
                            Approve
                        </Button>
                        <Button
                            className="btn-secondary"
                            href="#"
                            onClick={(e) => declineInvite(index, accountName)}
                            size="sm"
                        >
                            Decline
                         </Button>
                    </td>
                </>
            )

        }

        return "Something has gone wrong with this invite, please contact our support and we will fix it ASAP";
    }

    useEffect(() => {

        //var accountInvitesRef = firebaseApp.firestore().collection("AccountInvites");

        /*const myInvites = query(accountInvitesRef, where("invitedEmailAddress", "==", myEmailAddress));
        const myInvitesWIthCorrectStatus = query(accountInvitesRef, where("status", "==", ["approved", "pending"]));*/
      
        const unregisterFirebaseQuerist = firebaseApp.firestore().collection("AccountInvites")
            .where("invitedEmailAddress", "==", myEmailAddress)
            .where("status", "!=", "declined")
            .get()
            .then((querySnapshot) => {

                const data = querySnapshot.docs.map(
                    doc => {
                        let invite = {
                            data: doc.data(),
                            id: doc.id
                        }
                        return invite
                    });;

                //console.log("Incoming invites: " + JSON.stringify(data));

                setAccountInvites(data);

                setdownloadingCompleted(true);

            }).catch((error) => {
                alert("Error updating invite: " + error);
                setdownloadingCompleted(true);
            });

        return unregisterFirebaseQuerist;

    }, []);

    const mapDate = (timeStamp) => {
        var date = timeStamp.toDate().toDateString();
        return date;
    }

    if (!downloadingCompleted) {
        return (
            <Card className="">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="12">
                            <h3 className="mb-0">ACCOUNT INVITES</h3>
                            <small>Accounts you have been invited to. You can se and execute their templates but not edit them</small>

                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ClipLoader color={"#123abc"} loading={"Authenticating"} />
                </CardBody>
            </Card>
        );
    }

    if (accountInvites.length === 0) {
        return (
            <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="12">
                            <h3 className="mb-0">ACCOUNT INVITES</h3>
                            <small>Accounts you have been invited to. You can se and execute their templates but not edit them</small>

                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    When you have been invited to any account, it will show up here
        </CardBody>
            </Card>
        )
    }

    return (
        <Card className="bg-white shadow">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                    <Col xs="12">
                        <h3 className="mb-0">ACCOUNT INVITES</h3>
                        <small>Accounts you have been invited to. You can se and execute their templates but not edit them</small>

                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Table className="align-items-center table-flush" responsive>

                    <thead className="thead-light">
                        <tr id="accountInvitesHeaderTr">
                            <th scope="col">Account Name</th>
                            <th scope="col">Invited at</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {accountInvites.map((invite, index) => (
                            <tr id={invite.id} key={index}>
                                <td>{invite.data.sharedAccountDisplayName}</td>
                                <td>{mapDate(invite.data.createdAt)}</td>
                                {getStatus(invite.data.status, index, invite.data.sharedAccountDisplayName)}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default AccountsYouAreInvitedTo;