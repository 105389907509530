import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/h5/gridstack-dd-native';
/*import '../../assets/css/app-to-pdf-designer.css'*/

import GridStack from 'gridstack/dist/gridstack-h5';

import React, { useState, useEffect } from "react";

import StepVariableSpace from './StepVariableSpace';

import { DesignTimeVariableSpace } from "components/Templates/steps/DesignTimeVariableSpace";


import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  Input,
  Form,
  FormGroup,
  Button,
  Modal,
  ModalBody,
  ButtonGroup
} from "reactstrap";

import Header from "components/Headers/Header.js";

import ChoosableStepElements from "./ChoosableStepElements";

import App2PdfGridstack from "../App2PdfGridstack"

import ContextualModal from "../../Modal"

import { getCurrentVariableSpaceFromStep } from './StepVariableSpaceResolver';

import { createElements, mapRecursiveElement } from "../../../helpers/recursiveElementMapper";

const UserStepDesigner = ({ isOpen, toggle, userStep, saveChanges, deleteStep }) => {

  const [currentUserStepDataModel, setCurrentUserStepDataModel] = useState({ ...userStep });

  const [currentUserStepControls, setCurrentUserStepControls] = useState([])

  const [stepName, setStepName] = useState("")

  const [currentVariableSpace, setCurrentVariableSpace] = useState(new DesignTimeVariableSpace(""));

  const [modalOpen, setModalOpen] = useState(false);
  const toggleItemModal = () => setModalOpen(!modalOpen);

  const [currentModalItem, setCurrentModalItem] = useState({ elementType: "", textValue: "", pdfPosition: "" });
  const setModalElement = (currentModalElement) => setCurrentModalItem(currentModalElement);

  let mapCurrentVariableSpace = (previousSteps) => {
    getCurrentVariableSpaceFromStep(previousSteps)
      .then(data => {
        var designTimeVariableSpace = new DesignTimeVariableSpace(data);
        setCurrentVariableSpace(designTimeVariableSpace);
        // console.log("Variable space: " + JSON.stringify(designTimeVariableSpace));
      });
  }

  useEffect(() => {
    var actionRef = setCurrentUserStepDataModel(userStep);
    setCurrentUserStepControls(createElements(userStep.step.controls));
    mapCurrentVariableSpace(userStep.previousSteps);

    setStepName(userStep.step.name);
    return actionRef;
  }, [userStep])

  useEffect(() => {
  }, [currentUserStepDataModel])

  let save = () => {

    if (currentUserStepDataModel.step.index === -1 || currentUserStepDataModel.step.index === undefined) {
      currentUserStepDataModel.step.index = 0;
    }

    var controlsCopy = [...currentUserStepControls];

    if (controlsCopy.length === 0) {
      window.alert("You need to at least one control in your step!");
      return;
    }

    var controls = controlsCopy.map((control) => {
      // return control.element;
      return mapRecursiveElement(control);
    });

    currentUserStepDataModel.step.controls = controls;

    currentUserStepDataModel.step.name = stepName;

    saveChanges(currentUserStepDataModel.step);
  }

  var currentSubControls = [];

  const updateSubControls = (item, add) => {

    const listControlsCopy = [...currentSubControls];

    if (add) {

      listControlsCopy.push(item);

      currentSubControls = listControlsCopy;

      return listControlsCopy;
    } else {

      let filteredControl = listControlsCopy.filter(x => x.id !== item.id);

      currentSubControls = filteredControl;

      return filteredControl;
    }
  }

  let addNewControl = (newElement) => {

    var controlsCopy = [...currentUserStepControls];

    var indexedControls = controlsCopy.filter(c => c.element.elementType === newElement.element.elementType);

    newElement.element.variableOutputName = newElement.element.elementType + (indexedControls.length + 1);

    controlsCopy.push(newElement);

    // console.log("Control added: " + newElement);

    setCurrentUserStepControls(controlsCopy);
  }

  let updateElements = (item, id) => {

    var controlsCopy = [...currentUserStepControls];

    var indexToUpdate = controlsCopy.findIndex(c => c.id === id);

    controlsCopy[indexToUpdate].element = item;

    setCurrentUserStepControls(controlsCopy);

    toggleItemModal();
  }

  const updateHeaderElementWidhtHeightAndPosition = (changedItems) => {

    let items = [...currentUserStepControls];

    updateHeightAndPosition(items, changedItems);
  }

  const updateHeightAndPosition = (items, changedItems) => {
    if (items.length === 0) {
      //console.log("Items are empty...");
      return;
    }

    let newItems = [];
    items.map((item, index) => {

      newItems.push(item);

      changedItems.map((changed, changedIndex) => {

        if (item.id === changed.el.id) {
          let toEdit = newItems[index];

          toEdit.element.width = changed.w;
          toEdit.element.height = changed.h;
          toEdit.element.posX = changed.x;
          toEdit.element.posY = changed.y;

          newItems[index].element = toEdit.element;
        }
      })
    });

    items = newItems;

    return items;
  }

  const deleteGridItem = (itemId) => {

    //For now this is an item in the main grid
    let items = [...currentUserStepControls];

    deleteFromGrid(items, itemId);

    setCurrentUserStepControls(items);
  }

  const deleteFromGrid = (items, itemId) => {

    var indexToUpdate = 0;

    items.map((val, index) => {
      if (val.id === itemId) {
        indexToUpdate = index;
      }
    });

    var removed = items.splice(indexToUpdate, 1);

    console.log("Control deleted: " + removed);
  }

  return (
    <Modal
      className="modal-dialog-centered  modal-xl"
      isOpen={isOpen}
      toggle={toggle}>
      {
        <ModalBody>
          <Row>
            <Col lg="3">
              <Form>
                <FormGroup>
                  <Label for="stepName">STEP NAME</Label>
                  <Input type="name" name="name" id="stepName" placeholder="Enter name..." value={stepName} onChange={e => setStepName(e.target.value)} />
                </FormGroup>
              </Form>
              <Label>CHOOSABLE CONTROLS</Label>
              <Row>
                <ChoosableStepElements
                  addNewControl={addNewControl}
                />
              </Row>
            </Col>
            <Col lg="9">
              <Label>STEP CONTROLS</Label>
              <App2PdfGridstack
                gridElementId="userStepGrid"
                items={currentUserStepControls}
                toggleModal={toggleItemModal}
                setModalElement={setModalElement}
                onGridItemChanged={updateHeaderElementWidhtHeightAndPosition}
                onDeleteItem={deleteGridItem}
              />
            </Col>

          </Row>

          <ContextualModal
            isOpen={modalOpen}
            toggle={toggleItemModal}
            item={currentModalItem}
            onSaveChanges={updateElements}
            variableSpace={currentVariableSpace}
            currentSubControls={currentSubControls}
            updateSubControls={updateSubControls}
            previousSteps={userStep.previousSteps}>

          </ContextualModal>

        </ModalBody >
      }
      <div className=" modal-footer">
        <ButtonGroup>
          <Button
            color="primary"
            onClick={() => save()}>
            SAVE CHANGES
          </Button>
          <Button
            color="success"
            onClick={() => toggle()}>
            CANCEL
          </Button>
        </ButtonGroup>
        <Button
          className=" ml-auto"
          color="link"
          onClick={() => deleteStep(currentUserStepDataModel.step.index, "userStep")}
          type="button"
        >
          DELETE
        </Button>
      </div>
    </Modal>
  )
}

export default UserStepDesigner;