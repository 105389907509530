/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

import firebaseApp from "firebaseConfig";
import firebase from "firebase";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Badge,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import InvitedUsers from "components/AccountInvites/InvitedUsers";
import AccountsYouAreInvitedTo from "components/AccountInvites/AccountsYouHaveBeenInvitedTo";
import UserGroups from "../../components/Groups/UserGroups";
import ReactMediaLibraryWrapper from "../../components/MediaGallery/ReactMediaLabraryWrapper"


import ClipLoader from "react-spinners/ClipLoader";
const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

const profileModel = {
  UserProfile: { firstName: "", emailAdress: "", lastName: "", displayName: "", phoneNumber: "", accountId: "" },
  License: { numberOfUsers: 1, expiresAt: "", isTrial: true, numberOfTemplates: 1 },
  Organization: { name: "", adress: "", vatNumber: "", city: "", country: "", postalCode: "" },
  AccountsYouAreInvitedTo: [],
  UsersInvitedToYourAccount: []
}

const renewButton = (expiresAt) => {
  var date = Date.parse(expiresAt);

  if (date !== NaN) {
    if (date > Date.now())
      return (
        <div className="h5 mt-4">
          <Button
            color="default"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
            size="sm"
          >
            Switch to trial (test)
          </Button>
        </div>
      )
  }
}

const getLicenseView = (paidLicense) => {
  if (paidLicense) {
    return (
      <>
        <div className="h5 mt-4">
          <i className="ni business_briefcase-24 mr-2" />
        </div>
      </>
    )
  } else {
    return null;
  }
}

const getAccountName = (displayName, organizationName) => {
  if (organizationName === "")
    return displayName;

  return organizationName;
}

const Profile = () => {

  const [maximumUsers, setMaximumUsers] = useState(0);
  const [isEnterpriceLicense, setIsEnterpriceLicense] = useState(false);

  const [profile, setProfile] = useState(profileModel);

  const [downloadingCompleted, setdownloadingCompleted] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));

  const [showSaveButton, setShowSaveButton] = useState(false);

  const [isMediaShown, setIsMediaShown] = useState(false);

  const updateUserProfile = (name, value) => {

    var editedProfile = Object.assign({}, profile);
    editedProfile.UserProfile[name] = value;

    setProfile(editedProfile);

    setShowSaveButton(true);
  }

  const updateOrganization = (name, value) => {

    var editedProfile = Object.assign({}, profile);
    editedProfile.Organization[name] = value;

    setProfile(editedProfile);

    setShowSaveButton(true);
  }

  const saveProfile = () => {

    setdownloadingCompleted(false);

    var path = "Accounts/" + user.uid;


    firebaseApp.firestore().doc(path).set(profile)
      .then(function () {

        setdownloadingCompleted(true);

        setShowSaveButton(false);

      }).catch((error) => {

        setdownloadingCompleted(true);

        alert(error);

      });;

  }

  const saveButton = () => {

    if (showSaveButton) {
      return (<Button style={{ marginLeft: '10px' }} className="my-4" color="primary" type="button"
        onClick={() => saveProfile()}>
        SAVE INFORMATION
      </Button>)
    }
  };

  const donwloadFromFireBase = (path, count) => {

    const unregisterFirebaseQuerist = firebaseApp.firestore().doc(path)
      .get()
      .then((querySnapshot) => {

        if (querySnapshot.exists) {

          var firebaseProfile = querySnapshot.data();
          // console.log("UserProfile: " + JSON.stringify(firebaseProfile));

          setProfile(firebaseProfile);

          setdownloadingCompleted(true);

        } else {
          if (parseInt(count) === 10) {
            alert("Account setup failed, please reload the page again");
            return;
          }

          //If the table in firebase hasen´t been created yet, set a timeout and try again
          setTimeout(function () { donwloadFromFireBase(path, count + 1) }, 200);

        }
      });

    return unregisterFirebaseQuerist;
  }

  useEffect(() => {

    var path = "Accounts/" + user.uid;

    const unregisterFirebaseQuerist = donwloadFromFireBase(path, 1);

    return unregisterFirebaseQuerist;

  }, []);

  useEffect(() => {

    const unregisterFirebaseQuerist = firebaseApp.firestore().collection("enterpriceAccounts").where(firebase.firestore.FieldPath.documentId(), "==", user.uid)
      .get()
      .then((querySnapshot) => {

        const userInviteLicense = querySnapshot.docs.map(
          doc => {
            let license = {
              data: doc.data(),
              id: doc.id
            }
            return license.data
          });;


        if (userInviteLicense[0]) {
          setMaximumUsers(userInviteLicense[0].maximumUsers);
          setIsEnterpriceLicense(true);
        }

        setdownloadingCompleted(true);

      });

    return unregisterFirebaseQuerist;

  }, []);

  useEffect(() => {


  }, [profile]);

  const GetLicenseText = (enterpriceLicense) => {

    if (isEnterpriceLicense) {
      return "Enterprise";
    }

    return "Basic";
  }

  const openMediaLibrary = () => {
    setIsMediaShown(true);
  }

  if (!downloadingCompleted) {
    return (
      <>
        <UserHeader />
        <Container className="mt--7 bg-gradient-primary" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <div style={style}>
                <ClipLoader color={"#123abc"} loading={"Authenticating"} />
              </div>
            </div>
          </Row>

        </Container>
      </>
    );
  }

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7 bg-gradient-secondary" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={user.photoUrl}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">

                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>
                    {user.displayName}
                  </h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {profile.Organization.name}
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    License
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    <strong>{GetLicenseText(isEnterpriceLicense)}</strong>
                  </div>

                  <div>
                    <hr className="my-4" />
                    <h5>Basic</h5>
                    <p>
                      Create unlimited templates with our canvas designer and use our app to its full extent. Templates are watermarked and restricted to one user. Does not include support from the AppToPDF team.<br></br>
                    </p>
                    <h5>Enterprise</h5>
                    <p><strong>Enhanced service for corporate entities:</strong></p>
                    <p>- We adapt your templates or design new ones based on your needs <br></br>
                      - Technical support for the duration of your subscription<br></br>
                      - Company-wide access to mobile app</p>
                    <br></br>
                    <h5>If you want an enterprise license or if you need to invite more users</h5>

                    <p> Click the button below, choose "License" in the dropdown and enter <strong>"{profile.UserProfile.accountId}"</strong> in the message and we will contact you with a quote and help you set it up.  </p>

                  </div>
                  <Button style={{ marginLeft: '10px' }} className="my-4" color="primary" type="button"
                    onClick={() => window.open("https://apptopdf.com/contact-us/", "_blank")}>
                    REQUEST A QUOTE
                  </Button>

                  <br></br>
                  <br></br>

                  <a href="https://apptopdf.com" onClick={(e) => e.preventDefault()}>
                    More information
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col className="order-xl-1" xl="8">
            <Card className="bg-white shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Display Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={profile.UserProfile.displayName}
                            readOnly="readOnly"
                            id="input-username"
                            placeholder="Username"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            readOnly="readOnly"
                            id="input-email"
                            defaultValue={profile.UserProfile.emailAdress}
                            placeholder="jesse@example.com"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={profile.UserProfile.firstName}
                            onChange={(e) => updateUserProfile("firstName", e.target.value)}
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={profile.UserProfile.lastName}
                            onChange={(e) => updateUserProfile("lastName", e.target.value)}
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>

                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Organization information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-org-address"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={profile.Organization.adress}
                            onChange={(e) => updateOrganization("adress", e.target.value)}
                            id="input-org-address"
                            placeholder="Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-org-name"
                          >
                            Organization Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={profile.Organization.name}
                            onChange={(e) => updateOrganization("name", e.target.value)}
                            id="input-org-name"
                            placeholder="Org name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-org-vat"
                          >
                            VAT Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={profile.Organization.vatNumber}
                            onChange={(e) => updateOrganization("vatNumber", e.target.value)}
                            id="input-org-vat"
                            placeholder="Eg. SEXXXXXXXXXX01"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Country
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={profile.Organization.country}
                            onChange={(e) => updateOrganization("country", e.target.value)}
                            id="input-country"
                            placeholder="Country"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={profile.Organization.city}
                            onChange={(e) => updateOrganization("city", e.target.value)}
                            id="input-city"
                            placeholder="City"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-postal-code"
                          >
                            Zip code
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-postal-code"
                            placeholder="Postal code"
                            type="number"
                            value={profile.Organization.postalCode}
                            onChange={(e) => updateOrganization("postalCode", e.target.value)}
                          />
                        </FormGroup>

                        <br></br>


                      </Col>
                    </Row>
                  </div>
                  <Col>
                    {saveButton()}
                  </Col>
                </Form>


              </CardBody>
            </Card>

            <br></br>

            <InvitedUsers
              user={user}
              myAccountName={getAccountName(profile.UserProfile.emailAdress, profile.Organization.name)}
              maximumUsers={maximumUsers}
              isEnterpriceLicense={isEnterpriceLicense}
            />

            <br></br>

            <AccountsYouAreInvitedTo
              userId={user.uid}
              myEmailAddress={profile.UserProfile.emailAdress}
            />

            <br></br>

            <UserGroups
              userId={user.uid}
            />

          </Col>
        </Row>
      </Container>

    </>
  );
};

export default Profile;
