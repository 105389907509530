import TitleElement from "../DesignElements/TitleElement"
import StaticTextElement from "../DesignElements/StaticTextElement"
import TableElement from "../DesignElements/TableElement"
import StaticImageElement from "../DesignElements/StaticImageElement"
import ImageInputElement from "../DesignElements/ImageInputElement"
import TextInputElement from "../DesignElements/TextInputElement"
import PageBreakElement from "../DesignElements/PageBreakElement"
import SignatureInputElement from "../DesignElements/SignatureInputElement"
import ScriptElement  from "../DesignElements/ScriptElement"
import ElementGroup from "../DesignElements/ElementGroup"
import CheckBoxInputElement from "../DesignElements/CheckBoxInputElement"
import DropDownElement from "./DropDownElement"
import LabeledTextElement from "./LabeledTextElement"
import TextInfoGroup from "./TextInfoGroupElement"
import LinkElement from "./LinkElement"
import TableOfContentsElement from "./TableOfContentsElement"
import ListControlElement from "./ListControlElement"

const GetTypedTemplate = (element) => {

  //console.log(element);

  if (element.elementType === "Title") {
    return (
      <TitleElement element={element} />
    );
  } else if (element.elementType === "StaticText") {
    return (
      <StaticTextElement element={element} />
    );
  } else if (element.elementType === "LabeledText") {
    return (
      <LabeledTextElement element={element} />
    );
  } else if (element.elementType === "TableView") {
    return (
      <TableElement element={element} />
    );
  } else if (element.elementType === "StaticImage") {
    return (
      <StaticImageElement element={element} />
    );
  } else if (element.elementType === "ImageInput") {
    return (
      <ImageInputElement element={element} />
    );
  } else if (element.elementType === "TextInput") {
    return (
      <TextInputElement element={element} />
    );
  } else if (element.elementType === "PageBreak") {
    return (
      <PageBreakElement element={element} />
    );
  } else if (element.elementType === "SignatureInput") {
    return (
      <SignatureInputElement element={element} />
    );
  }
  else if (element.elementType === "ScriptElement") {
    return (
      <ScriptElement element={element} />
    );
  }
  else if (element.elementType === "ElementGroup") {
    return (
      <ElementGroup element={element} />
    );
  }
  else if (element.elementType === "CheckBox") {
    return (
      <CheckBoxInputElement element={element} />
    );
  }
  else if (element.elementType === "DropDown") {
    return (
      <DropDownElement element={element} />
    );
  }
  else if (element.elementType === "TextInfoGroup") {
    return (
      <TextInfoGroup element={element} />
    );
  }
  else if(element.elementType === "LinkControl") {
    return (
      <LinkElement element={element} />
    );
  }
  else if(element.elementType === "TableOfContents") {
    return (
      <TableOfContentsElement element={element} />
    );
  }
  else if(element.elementType === "ListControl") {
    return (
      <ListControlElement element={element} />
    );
  }

  

  return (
    <div>
      <div className="text-center">
        <h2 className="editable-label clickable-label">Missing element mapper for {element.elementType}</h2>
      </div></div>
  );
}

export default GetTypedTemplate;