import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody,
    Row,
    Col,
    Form,
    Button
} from "reactstrap";

import React, { useState, useEffect } from "react";

import VariableDropDownComponent from "../VariableDropDownComponent";
import ReactJson from 'react-json-view'

import ChoosableListControlElements from './ChoosableListControlElements'

import App2PdfGridstack from "../../App2PdfGridstack"

import ContextualModal from "../../../Modal"

import FrostScriptEditor from "../../steps/FrostScriptEditor";

import ReactMediaLibraryWrapper from "../../../MediaGallery/ReactMediaLabraryWrapper";

import { getVariableSubSpace } from "./getVariableSubSpace";

import { DesignTimeVariableSpace } from "../../steps/DesignTimeVariableSpace";

import { v4 as uuidv4 } from 'uuid';


const ListControlEditor = ({ listControl, updateElement, toggle, saveChanges, itemId, variableSpace, currentSubControls, updateSubControls, previousSteps }) => {

    const [currentListControl, setCurrentListControl] = useState({ ...listControl });

    const [currentUserStepControls, setCurrentUserStepControls] = useState([...listControl.elements])

    const [modalOpen, setModalOpen] = useState(false);
    const toggleItemModal = () => setModalOpen(!modalOpen);

    const [currentModalItem, setCurrentModalItem] = useState({ elementType: "", textValue: "", pdfPosition: "" });
    const setModalElement = (currentModalElement) => setCurrentModalItem(currentModalElement);

    const [variableSubSpace, setVariableSubSpace] = useState(new DesignTimeVariableSpace(""));

    const [prevSteps, setPrevSteps] = useState([...previousSteps]);

    const [currentListVariable, setCurrentListVariable] = useState({
        variable: "",
        id: ""
    })

    const updateInputVariable = (variable) => {
        var lstCopy = {...currentListControl};
        lstCopy.variable = variable;
        setCurrentListControl(lstCopy);

        mapCurrentVariableSpace(prevSteps, variable);
    }

    const [isMediaShown, setIsMediaShown] = useState(false);

    let mapCurrentVariableSpace = (prevSteps, variable) => {
        
        let inputVariables = [];

        // Pop the previous subcontrol item
        inputVariables = updateSubControls(currentListVariable, false);

        if (variable === undefined) {
            variable = "";
        }

        if (variable !== "") { 
            var tempCurrentListVariable = {
                variable: variable,
                id: uuidv4()
            };

            // Add the list control item
            setCurrentListVariable(tempCurrentListVariable)

            inputVariables = updateSubControls(tempCurrentListVariable, true);
        } 

        var prueVariables = [];
        
        if (inputVariables.length > 0) {
            prueVariables = inputVariables.map((lst) => {
                return lst.variable;
            })
        }

        getVariableSubSpace(prevSteps, prueVariables)
        .then(data => {
            var designTimeVariableSpace = new DesignTimeVariableSpace(data);
            setVariableSubSpace(designTimeVariableSpace);
            // console.log("Variable space: " + JSON.stringify(designTimeVariableSpace));
        });
    }

    React.useEffect(() => {
        setPrevSteps(previousSteps)
        var actionRef = mapCurrentVariableSpace(prevSteps, listControl.variable);
        return actionRef;
    }, [previousSteps])

    React.useEffect(() => {

    }, [currentListControl, currentUserStepControls])

    let save = () => {

        var groupToSave = { ...currentListControl };

        groupToSave.elements = [...currentUserStepControls];

        saveChanges(groupToSave, itemId);
    }

    const staticImageSelected = (image, staticImageControl) => {
        if (staticImageControl.element.elementType === "StaticImage") {
            staticImageControl.element.imagePath = image.thumbnailUrl;
            toggleItemModal();
        }
    }

    let updateCurrentControlGroup = (propertyName, value) => {
        var toEdit = { ...currentListControl };

        toEdit[propertyName] = value;

        setCurrentListControl(toEdit);
    }

    let addNewControl = (newElement) => {

        var controlsCopy = [...currentUserStepControls];

        var indexedControls = controlsCopy.filter(c => c.element.elementType === newElement.element.elementType);

        newElement.element.variableOutputName = newElement.element.elementType + (indexedControls.length + 1);

        controlsCopy.push(newElement);
        setCurrentUserStepControls(controlsCopy);
    }

    let updateElements = (item, id) => {

        var controlsCopy = [...currentUserStepControls];

        var indexToUpdate = controlsCopy.findIndex(c => c.id === id);

        controlsCopy[indexToUpdate].element = item;

        setCurrentUserStepControls(controlsCopy);

        toggleItemModal();
    }

    const updateHeaderElementWidhtHeightAndPosition = (changedItems) => {

        let items = [...currentUserStepControls];

        updateHeightAndPosition(items, changedItems);
    }

    const updateHeightAndPosition = (items, changedItems) => {
        if (items.length === 0) {
            //console.log("Items are empty...");
            return;
        }

        let newItems = [];
        items.map((item, index) => {

            newItems.push(item);

            changedItems.map((changed, changedIndex) => {

                if (item.id === changed.el.id) {
                    let toEdit = newItems[index];

                    toEdit.element.width = changed.w;
                    toEdit.element.height = changed.h;
                    toEdit.element.posX = changed.x;
                    toEdit.element.posY = changed.y;

                    newItems[index].element = toEdit.element;
                }
            })
        });

        items = newItems;

        return items;
    }

    const deleteGridItem = (itemId) => {

        //For now this is an item in the main grid
        let items = [...currentUserStepControls];

        deleteFromGrid(items, itemId);

        setCurrentUserStepControls(items);
    }

    const deleteFromGrid = (items, itemId) => {

        var indexToUpdate = 0;

        items.map((val, index) => {
            if (val.id === itemId) {
                indexToUpdate = index;
            }
        });

        var removed = items.splice(indexToUpdate, 1);
    }

    return (
        <>
            <ModalBody>
                <Row>
                    <Col lg="3">
                        <Form>
                            <FormGroup>

                                <Label for="largeText">LARGE TEXT</Label>
                                <Input
                                    placeholder="Text"
                                    type="text"
                                    value={currentListControl.largeText}
                                    id="largeText"
                                    onChange={e => updateCurrentControlGroup("largeText", e.target.value)} />

                            </FormGroup >

                            <FormGroup>

                                <Label for="smallText">SMALL TEXT</Label>
                                <Input
                                    placeholder="Text"
                                    type="text"
                                    value={currentListControl.smallText}
                                    id="listControlName"
                                    onChange={e => updateCurrentControlGroup("smallText", e.target.value)} />

                            </FormGroup >

                            <FormGroup>

                                <Label for="smallText">VARIABLE OUTPUT NAME</Label>
                                <Input
                                    placeholder="Text"
                                    type="text"
                                    value={currentListControl.variableOutputName}
                                    id="listControlName"
                                    onChange={e => updateCurrentControlGroup("variableOutputName", e.target.value)} />

                            </FormGroup >

                            <FormGroup>

                                <Label for="smallText">VARIABLE</Label>
                                <VariableDropDownComponent
                                    selectedVariable={currentListControl.variable}
                                    avaliableTableVariables={variableSpace.getListControlSources()}
                                    onSelectedItem={e => updateInputVariable(e)}
                                    dropDownSource={true} />

                            </FormGroup >

                        </Form>
                        <Label>CHOOSABLE CONTROLS</Label>
                        <Row>
                            <ChoosableListControlElements
                                addNewElement={addNewControl}
                            />
                        </Row>
                    </Col>
                    <Col lg="9">
                        <Label>CONTROLS</Label>
                        <App2PdfGridstack
                            gridElementId={uuidv4()}
                            items={currentUserStepControls}
                            toggleModal={toggleItemModal}
                            setModalElement={setModalElement}
                            onGridItemChanged={updateHeaderElementWidhtHeightAndPosition}
                            onDeleteItem={deleteGridItem}
                        />
                    </Col>

                </Row>

                <br></br>

                <Label for="variableSpace">Variable space</Label>
                <ReactJson src={variableSubSpace} collapsed={true} />

                <ContextualModal
                    isOpen={modalOpen}
                    toggle={toggleItemModal}
                    item={currentModalItem}
                    onSaveChanges={updateElements}
                    variableSpace={variableSubSpace}
                    showGallery={() => setIsMediaShown(true)}
                    currentSubControls={currentSubControls}
                    updateSubControls={updateSubControls}
                    previousSteps={prevSteps}>

                </ContextualModal>

            </ModalBody >
            <div className=" modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => {
                        save();
                        updateSubControls(currentListVariable, false);
                    }}>
                    Save changes
                </Button>
                <Button
                    className=" ml-auto"
                    color="link"
                    onClick={() => {
                        toggle();
                        updateSubControls(currentListVariable, false);
                    }}
                    type="button"
                >
                    Cancel
                </Button>
            </div>

            <ReactMediaLibraryWrapper
                imageSelected={(image) => staticImageSelected(image, currentModalItem)}
                isVisible={isMediaShown}
                onHidden={() => setIsMediaShown(false)}
            />
        </>
    );
}

export default ListControlEditor;