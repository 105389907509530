import React, { useState, useEffect } from "react";
import TitleEditor from "../components/Templates/ElementEditors/TitleEditor"
import StaticTextEditor from "../components/Templates/ElementEditors/StaticTextEditor"
import LabeledTextEditor from "../components/Templates/ElementEditors/LabeledTextEditor"
import TextInputEditor from "../components/Templates/ElementEditors/TextInputEditor"
import CameraInputEditor from "../components/Templates/ElementEditors/CameraInputEditor"
import TableEditor from "../components/Templates/ElementEditors/TableEditor"
import StaticImageEditor from "../components/Templates/ElementEditors/StaticImageEditor"
import SignatureInputEditor from "../components/Templates/ElementEditors/SignatureInputEditor"
import ScriptEditor from "../components/Templates/ElementEditors/ScriptEditor"
import ElementGroupEditor from "./Templates/ElementEditors/groupControl/ElementGroupEditor"
import CheckBoxEditor from "./Templates/ElementEditors/CheckBoxEditor"
import DropdownEditor from "./Templates/ElementEditors/DropDownEditor"
import TextInfoGroupEditor from "./Templates/ElementEditors/TextInfoGroupEditor"
import TableOfContentsEditor from "./Templates/ElementEditors/TableOfContentsEditor"
import ListControlEditor  from "./Templates/ElementEditors/listControl/ListControlEditor"

import ReactJson from 'react-json-view'

// reactstrap components
import {
    Button,
    Dropdown,
    FormGroup,
    InputGroup,
    Modal,
    ModalBody,
    Label
} from "reactstrap";
import LinkControlEditor from "./Templates/ElementEditors/LinkControlEditor";


const ContextualModal = ({ 
    children, 
    isOpen, 
    toggle,
    item, 
    onSaveChanges, 
    variableSpace, 
    showGallery, 
    currentSubControls, 
    updateSubControls, 
    previousSteps }) => {

    const [currentElement, setCurrentElement] = useState({ ...item.element });

    const updateElement = (stateName, newValue) => {
        setCurrentElement({ ...currentElement, [stateName]: newValue });
    }

    useEffect(() => {
        var actionRef = setCurrentElement(item.element);
        return actionRef;
    }, [item.element])

    useEffect(() => {

    }, [currentElement])

    const getModalContent = (elementType) => {

        if (elementType === "Title") {
            return <TitleEditor
                titleElement={currentElement}
                updateElement={updateElement}
                variableSpace={variableSpace}
            />
        } else if (elementType === "LabeledText") {
            return <LabeledTextEditor
                labeledTextElement={currentElement}
                updateElement={updateElement}
                variableSpace={variableSpace}
            />
        } else if (elementType === "StaticText") {
            return <StaticTextEditor
                staticTextElement={currentElement}
                updateElement={updateElement}
                variableSpace={variableSpace}
            />
        } else if (elementType === "TextInput") {
            return <TextInputEditor
                textInputElement={currentElement}
                updateElement={updateElement}
            />
        } else if (elementType === "ImageInput") {
            return <CameraInputEditor
                cameraInputElement={currentElement}
                updateElement={updateElement}
                variableSpace={variableSpace}
            />
        } else if (elementType === "SignatureInput") {
            return <SignatureInputEditor
                signatureInputElement={currentElement}
                updateElement={updateElement}
                variableSpace={variableSpace}
            />
        } else if (elementType === "ScriptElement") {
            return <ScriptEditor
                scriptInputElement={currentElement}
                updateElement={updateElement}
                variableSpace={variableSpace}
            />
        }
        else if (elementType === "CheckBox") {
            return <CheckBoxEditor
                checkBoxElement={currentElement}
                updateElement={updateElement}
            />
        } else if (elementType === "DropDown") {
            return <DropdownEditor
                dropDownElement={currentElement}
                updateElement={updateElement}
                variableSpace={variableSpace}
            />
        } else if (elementType === "LinkControl") {
            return <LinkControlEditor
                linkControlElement={currentElement}
                updateElement={updateElement}
                variableSpace={variableSpace}
            />
        } else if (elementType === "TableOfContents") {
            return <TableOfContentsEditor
                tableOfContentsElement={currentElement}
                updateElement={updateElement}
                variableSpace={variableSpace}
            />
        } 


        return (
            <ModalBody>
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <h5>Missing editor for control</h5>
                    </InputGroup>
                </FormGroup>
            </ModalBody >
        )
    }

    if (!currentElement) {
        return (
            <Modal
                className="modal-dialog-centered modal-xl"
                isOpen={isOpen}
                toggle={toggle}>

            </Modal>
        )
    }

    const getMatchingModal = (currentElement) => {
        if (currentElement.elementType === "TableView" || currentElement.elementType === "StaticImage" || currentElement.elementType === "ElementGroup" || currentElement.elementType === "TextInfoGroup" || currentElement.elementType === "ListControl") {
            return (
                <Modal
                    isOpen={isOpen}
                    toggle={toggle}
                    className="modal-dialog-centered modal-xl">
                    <div className=" modal-header">
                        <h5 className=" modal-title" id="modal-title-default">
                            Edit {currentElement.elementType} element
                        </h5>
                        <button
                            aria-label="Close"
                            className=" close"
                            onClick={() => { toggle(); }}
                            type="button"
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    {currentElement.elementType === "TableView" &&
                        <TableEditor
                            tableControl={currentElement}
                            updateElement={updateElement}
                            toggle={toggle}
                            saveChanges={onSaveChanges}
                            itemId={item.id}
                            variableSpace={variableSpace}
                        />
                    }
                    {currentElement.elementType === "StaticImage" &&
                        <StaticImageEditor
                            staticImageElement={currentElement}
                            updateElement={updateElement}
                            toggle={toggle}
                            saveChanges={onSaveChanges}
                            itemId={item.id}
                            showGallery={showGallery}
                        />
                    }
                    {currentElement.elementType === "ElementGroup" &&
                        <ElementGroupEditor
                            elementGroup={currentElement}
                            updateElement={updateElement}
                            toggle={toggle}
                            saveChanges={onSaveChanges}
                            itemId={item.id}
                            variableSpace={variableSpace}
                            currentSubControls={currentSubControls}
                            updateSubControls={updateSubControls}
                            previousSteps={previousSteps}
                        />
                    }
                    {currentElement.elementType === "ListControl" && 
                        <ListControlEditor
                            listControl={currentElement}
                            updateElement={updateElement}
                            toggle={toggle}
                            saveChanges={onSaveChanges}
                            itemId={item.id}
                            variableSpace={variableSpace}
                            currentSubControls={currentSubControls}
                            updateSubControls={updateSubControls}
                            previousSteps={previousSteps}
                        />
                    }
                    {currentElement.elementType === "TextInfoGroup" &&
                        <TextInfoGroupEditor
                            textInfoElement={currentElement}
                            updateElement={updateElement}
                            toggle={toggle}
                            saveChanges={onSaveChanges}
                            itemId={item.id}
                            variableSpace={variableSpace}
                        />
                    }
                </Modal>

            );
        } else {
            return (
                <div>
                    <Modal
                        className="modal-dialog-centered modal-xl"
                        isOpen={isOpen}
                        toggle={toggle}>
                        <div className=" modal-header">
                            <h5 className=" modal-title" id="modal-title-default">
                                Edit {currentElement.elementType} element
                            </h5>
                            <button
                                aria-label="Close"
                                className=" close"
                                onClick={() => toggle()}
                                type="button"
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>

                        <ModalBody>
                            {getModalContent(currentElement.elementType)}

                            <Label for="variableSpace">Variable space</Label>
                            <ReactJson src={variableSpace} collapsed={true} />

                        </ModalBody>
                        <div className=" modal-footer">

                            <Button
                                color="primary"
                                type="button"
                                onClick={() => onSaveChanges(currentElement, item.id)}>
                                Save changes
                            </Button>
                            <Button
                                className=" ml-auto"
                                color="link"
                                onClick={() => toggle()}
                                type="button"
                            >
                                Cancel
                            </Button>
                        </div>
                    </Modal>
                </div>
            );
        }
    }

    return (
        getMatchingModal(currentElement)
    );
}

export default ContextualModal;