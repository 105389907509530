// reactstrap components
import React, { useState, useEffect } from "react";

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; //Example style, you can use another

import {
    Button,
    FormGroup,
    Modal,
    ModalBody,
    Label,
    Input,
    Table,
    Media
} from "reactstrap";

import firebaseApp from "firebaseConfig";

import ClipLoader from "react-spinners/ClipLoader";

const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

const ImportDefaultTemplatesModal = ({ isOpen, toggle }) => {

    const [templates, setTemplates] = useState([]);

    const [importedTemplate, setImportedTemplate] = useState("");
    const [choosenTemplateName, setChoosenTemplateName] = useState("");
    const [downloadingCompleted, setdownloadingCompleted] = useState(false);

    const user = JSON.parse(localStorage.getItem('user'));
    var path = "Accounts/" + user.uid + "/Templates";

    useEffect(() => {

        const unsubscribe = firebaseApp.firestore()
            .collection("defaultTemplates")
            .onSnapshot({
                includeMetadataChanges: true
            },
                snapshot => {

                    const data = snapshot.docs.map(
                        doc => {
                            let template = {
                                data: doc.data(),
                                id: doc.id,
                                import: false,
                                importName: doc.data().templateName
                            }
                            return template
                        });

                    //console.log(data); // array of templates
                    setTemplates(data);
                    setdownloadingCompleted(true);

                });

        return () => {
            unsubscribe()
        }

    }, [firebaseApp.firestore()])


    const importTemplates = () => {

        if (window.confirm("Are you sure you want to import the selected templates?")) {

            setdownloadingCompleted(false);

            var templatesToAdd = templates.filter((t) => t.import === true);

            var promises = templatesToAdd.map((template) => {

                var toAdd = template.data;
                toAdd.templateName = template.importName;

                return firebaseApp.firestore().collection(path).add(toAdd)
                    .then((docRef) => {

                        return true;

                    }).catch((error) => {

                        console.log(error);
                        return false;

                    });
            })

            Promise.all(promises).then(() => {
                setdownloadingCompleted(false);
                toggle();
            });

        }
    }


    let validateTemplate = (template) => {
        if (!template.hasOwnProperty("templateName"))
            return false;


        if (!template.hasOwnProperty("createdAt"))
            return false;


        if (!template.hasOwnProperty("lastUpdatedAt"))
            return false;


        if (!template.hasOwnProperty("mainGrid"))
            return false;


        if (!template.mainGrid.hasOwnProperty("elements"))
            return false;

        if (!template.hasOwnProperty("pageFooter"))
            return false;


        if (!template.pageFooter.hasOwnProperty("elements"))
            return false;

        if (!template.hasOwnProperty("pageHeader"))
            return false;

        if (!template.pageHeader.hasOwnProperty("elements"))
            return false;

        return true;
    }

    let toggleTemplateToImport = (index, included) => {

        var toEdit = [...templates];

        toEdit[index].import = included;

        setTemplates(toEdit);
    }

    let templateToImportName = (index, importName) => {

        var toEdit = [...templates];

        toEdit[index].importName = importName;

        setTemplates(toEdit);
    }

    let getModalContent = () => {
        if (!downloadingCompleted) {
            return (
                <>
                    <ModalBody>
                        <div className="col">
                            <div style={style}>
                                <ClipLoader color={"#123abc"} loading={"Syncing with cloud..."} />
                            </div>
                        </div>
                    </ModalBody>
                </>
            );
        } else {
            return <ModalBody>
                <i>The templates you import will be copied to your account where you can edit them to suit your needs</i>
                <p>If you need help with custimzation, don't hesitate to <b><a href="https://apptopdf.com/kontakt/">contact us</a></b>. We can help you to streamline your business process. We have helped alot of customers to reach success! </p>
                <FormGroup>
                    <Table className="align-items-center table-flush" responsive>

                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Template name</th>
                                <th>Import template with name</th>
                                <th className="text-right">Check to import</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templates.map((template, index) => (
                                <tr
                                    key={template.id}
                                >
                                    <th scope="row">
                                        <Media className="align-items-center">
                                            <a
                                            >
                                                <img
                                                    alt="..."
                                                    className="rounded-circle"
                                                    src={
                                                        require("../../../assets/img/mainIcons/icons8-pdf-100.png")
                                                            .default
                                                    }
                                                />
                                            </a>
                                            <Media>
                                                <span className="mb-0 text-sm">
                                                    {template.data.templateName}
                                                </span>
                                            </Media>
                                        </Media>
                                    </th>
                                    <td>
                                        <Input
                                            type="text"
                                            name="importedTemplateName"
                                            id="importedTemplateName"
                                            value={template.importName}
                                            onChange={e => templateToImportName(index, e.target.value)}>
                                        </Input>
                                    </td>

                                    <td className="text-right">
                                        <div className="custom-control custom-checkbox mb-3">
                                            <input
                                                className="custom-control-input"
                                                id={"id_" + index}
                                                checked={templates[index].import}
                                                onChange={e => toggleTemplateToImport(index, e.target.checked)}
                                                type="checkbox"
                                            />
                                            <label className="custom-control-label" htmlFor={"id_" + index}>
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </Table>
                </FormGroup >
            </ModalBody >
        }
    }

    return (
        <Modal
            className="modal-dialog-centered modal-xl"
            isOpen={isOpen}
            toggle={toggle}>
            <div className=" modal-header">
                <h5 className=" modal-title" id="modal-title-default">
                    IMPORT DEFAULT TEMPLATES
                </h5>
                <button
                    aria-label="Close"
                    className=" close"
                    onClick={() => toggle()}
                    type="button"
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            {
                getModalContent()
            }
            <div className=" modal-footer">
                <Button
                    color="primary"
                    onClick={() => importTemplates()}
                    disabled={!templates.some((t) => t.import === true)}
                >
                    IMPORT TEMPLATES
                </Button>

                <Button
                    className=" ml-auto"
                    color="link"
                    onClick={() => toggle()}
                    type="button"
                >
                    CANCEL
                </Button>
            </div>
        </Modal>
    );
}

export default ImportDefaultTemplatesModal;