import { Table, FormGroup, Label, Input } from 'reactstrap';


const getTableColumnClass = (column) => {

    if (column.alignment === "Center")
        return "text-center";
    if (column.alignment === "Right")
        return "text-right";

    return "text-left";
}

const getTableColumnContent = (column) => {

    if(column.defaultText != undefined && column.defaultText !== "") {
        return <strong>{column.defaultText}</strong>;
    }
    switch (column.inputType) {
        case 'TextInput':
        case 'textcolumn':
            return <strong> Text Input </strong>;
        case 'NumericInput':
            return <strong> Numeric input </strong>;
        case 'ImageInput':
            return <strong> Image input </strong>;
        case 'SignatureInput':
             return <strong> Signature input </strong>;   
        case 'GpsInput':
            return <strong> Gps input </strong>;         
        case 'DropDown':
            return <strong> Drop down </strong>;
        case 'Script':
            return <strong> Script </strong>;
        case 'DateInput':
            return <strong> Date input </strong>;
        default:
            return 'Property not mapped';
    }
}

const getTableColumns = (columns) => {
    {
        return columns.map((column, index) => {
            return <>
                <td
                    key={"coltdId-" + index}
                    style={{ width: column.width + "%" }}
                    className={getTableColumnClass(column)}>
                    {getTableColumnContent(column)}
                </td>
            </>
        })
    }
}

const getTableRowSum = (element) => {
    if (element.rowSummary != undefined && element.rowSummary.scriptValue != undefined && element.rowSummary.scriptValue !== "") {
        return (
            <td className='text-right'>{element.rowSummary.scriptValue}</td>
        )
    }

    return null;
}

const getTableHeaderRowSumCol = (element) => {

    if (element.rowSummary != undefined && element.rowSummary.scriptValue != undefined && element.rowSummary.scriptValue !== "") {
        return (
            <th className='text-right'>Row Sum</th>
        )
    }

    return null;
}

const getTableSummary = (element) => {
    if (element.tableSummary != undefined && element.tableSummary.scriptValue != undefined && element.tableSummary.scriptValue !== "") {
        return (
            <p className='text-right' style={{ color: "black" }}>Table summary: <strong>{element.tableSummary.scriptValue}</strong></p>
        )
    }

    return null;
}

const getTableOutputVariableNamme = (element) => {
    if (element.variableOutputName != undefined && element.variableOutputName !== "") {
        return (
            <h5>Variable output name: <strong>{element.variableOutputName}</strong></h5>
        )
    }

    return null;
}

const getVariableSource = (element) => {
    if (element.variable !== undefined && element.variable !== "") {
        return (
            <h5>Variable source: <strong>{element.variable}</strong></h5>
        )
    }

    return null;
}

const getIsCheckTable = (element) => {
    if (element.checkTable !== undefined && element.checkTable) {
        return (
            <h5>Is table check list: <strong>Yes</strong></h5>
        )
    }

    return null;
}

const TableElement = ({ element }) => {

    var columns = element.columns;

    return (
        <>
            {getTableOutputVariableNamme(element)}
            {getVariableSource(element)}
            {getIsCheckTable(element)}
            <Table>
                <thead>
                    <tr>
                        {columns.map((column, index) => {
                            return (
                                <th
                                    key={"colId-" + index}
                                    style={{ width: column.width + "%" }}
                                    className={getTableColumnClass(column)}>
                                    {column.text}
                                </th>
                            )
                        })}
                        {getTableHeaderRowSumCol(element)}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {getTableColumns(columns)}
                        {getTableRowSum(element)}
                    </tr>
                    <tr>
                        {getTableColumns(columns)}
                        {getTableRowSum(element)}
                    </tr>

                </tbody>
            </Table>
            {getTableSummary(element)}

        </>
    );
}

export default TableElement