import {
    ModalBody,
    Button,
    Table,
    Input,
    Label,
    FormGroup
} from "reactstrap";

import React, { useState, useEffect } from 'react';

const VariableDropDownComponent = ({ selectedVariable, avaliableVariables, onSelectedItem, dropDownSource }) => {

    let nonSelectedVariable = { name: "No variable selected" };

    const [currentVariable, setCurrentVariable] = useState(nonSelectedVariable);
    const [selectableVariables, setSelectableVariables] = useState([nonSelectedVariable]);
    const [style, setStyle] = useState({marginLeft: "10px"});


    React.useEffect(() => {
        if (selectedVariable !== undefined) {
            setCurrentVariable(getMatchingVariable(selectedVariable));
        } else {
            setCurrentVariable(nonSelectedVariable);
        }

    }, [selectedVariable])

    React.useEffect(() => {
        if(dropDownSource){
            setStyle({marginLeft: "0px"})
        }
        if (avaliableVariables !== undefined && avaliableVariables.length > 0) {
            setSelectableVariables(getAvaliableVariables());
        } else {
            setSelectableVariables([nonSelectedVariable]);
        }

    }, [avaliableVariables])

    React.useEffect(() => {

    }, [currentVariable])

    const getMatchingVariable = (name) => {

        var matchingVariable = avaliableVariables.find(d => d.name === name);

        if (matchingVariable === undefined) {
            return nonSelectedVariable;
        }

        return matchingVariable;
    }

    const getAvaliableVariables = () => {

        if (avaliableVariables === undefined || avaliableVariables.length === 0) {
            alert("You need to have at least one drop down data source to be able to use the column type.")
            return null;
        }

        var selectableVariables = [];

        selectableVariables.push(nonSelectedVariable);

        if (avaliableVariables !== undefined && avaliableVariables.length > 0) {

            avaliableVariables.map((variable) => {
                selectableVariables.push({ name: variable.name });
            })
        }

        return selectableVariables;
    }

    const chooseVariable = (variable) => {

        if (variable !== "No variable selected") {
 
            if (!dropDownSource && window.confirm("If you choose a variable your table will be dropped and re-rendered. Are you sure?") == true) {
                // Regenerate table from variable.
                setCurrentVariable(variable);
                onSelectedItem(variable);

            }
            else {
                setCurrentVariable(variable);
                onSelectedItem(variable);
            }


        } else {
            setCurrentVariable(nonSelectedVariable);
            onSelectedItem("");
        }
    }

    return (
        <Input
            type="select"
            name="variable"
            id="variable"
            style={style}
            value={currentVariable.name}
            onChange={e => (chooseVariable(e.target.value))}>
            {selectableVariables.map((variable) => <option value={variable.name}>{variable.name}</option>)}
        </Input>
    )

}

export default VariableDropDownComponent;