import React, { useState, useEffect } from "react";

import {
    Button,
    Card,
    CardHeader,
    Media,
    Table,
    Container,
    Row,
    CardBody,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    UncontrolledTooltip,
    Modal,
    ModalBody,
    FormGroup
} from "reactstrap";

import firebaseApp from "firebaseConfig";

const ShareTemplateModal = ({ isOpen, toggle, template, user }) => {    

    const [userGroups, setUserGroups] = useState([]);

    const [currentTemplate, setCurrentTemplate] = useState(template);

    const [downloadingCompleted, setdownloadingCompleted] = useState(false);

    const getFirestorePath = () => {
        const user = JSON.parse(localStorage.getItem('user'));

        var path = "Accounts/" + user.uid + "/UserGroups";

        return path;
    }

    useEffect(() => {
    }, [userGroups])

    useEffect(() => {
        var actionRef = setCurrentTemplate(template);
        return actionRef;
    }, [template])

    useEffect(() => {

        setdownloadingCompleted(false);

        var path = getFirestorePath();

        const unsubscribe = firebaseApp.firestore().collection(path)
            .onSnapshot({
                includeMetadataChanges: true
            },
                snapshot => {

                    const userGroups = snapshot.docs.map(
                        doc => {
                            let userGroup = {
                                name: doc.data().name,
                                displayName: doc.data().displayName,
                                templates: doc.data().templates,
                                users: doc.data().users,
                                id: doc.id
                            }
                            return userGroup
                        });

                    setUserGroups(userGroups);
                    setdownloadingCompleted(true);

                });

        return () => {
            unsubscribe()
        }

    }, [firebaseApp.firestore()]);

    const save = (templateId) => {
        
        setdownloadingCompleted(false);

        var path = getFirestorePath();

        var batch = firebaseApp.firestore().batch();


        userGroups.map((group) => {
            const docRef = firebaseApp.firestore().collection(path).doc(group.id)
            batch.set(docRef, { name: group.name, displayName: group.displayName, users: group.users, templates: group.templates })
        });

        batch.commit().then(function () {
            setdownloadingCompleted(true);
        });

        toggle();
    }

    let isTemplateSharedToGroup = (groupIndex) => {
        var currentGroup = userGroups[groupIndex];

        return currentGroup.templates.includes(currentTemplate.templateId);
    }

    let toggleGroupHaveTemplateAccess = (groupIndex, included) => {

        var toEdit = [...userGroups];

        var currentGroup = toEdit[groupIndex];

        if(included) {
            currentGroup.templates.push(currentTemplate.templateId);
        } else {
            var indexToRemove = currentGroup.templates.indexOf(currentTemplate.templateId);
            currentGroup.templates.splice(indexToRemove, 1);
        }

        setUserGroups(toEdit);
    }


    return (
        <div>
            <Modal
                className="modal-dialog-centered"
                isOpen={isOpen}
                toggle={toggle}>
                <div className=" modal-header">
                    <h5 className=" modal-title" id="modal-title-default">
                        Edit: <strong>{currentTemplate.templateName}</strong>
                    </h5>
                    <button
                        aria-label="Close"
                        className=" close"
                        onClick={() => toggle()}
                        type="button"
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                {
                    <ModalBody>
                        <FormGroup>
                            <Table className="align-items-center table-flush" responsive>

                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Group name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userGroups.map((group, index) => (
                                        <tr
                                            key={group.id}
                                        >
                                            <th scope="row"
                                                style={{ cursor: "pointer" }}>
                                                <Media className="align-items-center">
                                                    <a
                                                        className="avatar rounded-circle mr-3"
                                                    >
                                                        <img
                                                            alt="..."
                                                            className="rounded-circle"
                                                            src={
                                                                require("../../../assets/img/brand/sample-user-icon.png")
                                                                    .default
                                                            }
                                                        />
                                                    </a>
                                                    <Media>
                                                        <span className="mb-0 text-sm">
                                                            {group.displayName}
                                                        </span>
                                                    </Media>
                                                </Media>
                                            </th>

                                            <td className="text-right">
                                                <div className="custom-control custom-checkbox mb-3">
                                                    <input
                                                        className="custom-control-input"
                                                        id={"id_" + index}
                                                        checked={isTemplateSharedToGroup(index)}
                                                        onChange={e => toggleGroupHaveTemplateAccess(index, e.target.checked)}
                                                        type="checkbox"
                                                    />
                                                    <label className="custom-control-label" htmlFor={"id_" + index}>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>
                        </FormGroup >
                    </ModalBody >
                }
                <div className=" modal-footer">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => save(currentTemplate.templateId)}>
                        Save changes
                    </Button>
                    <Button
                        className=" ml-auto"
                        color="link"
                        onClick={() => toggle()}
                        type="button"
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
        </div>
    );

}

export default ShareTemplateModal;