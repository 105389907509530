/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import { connect } from "react-redux";
import { resetPasswordAction } from "actions/resetPasswordAction";
import { validateEmail, validateEmailAddress, validatePassword } from "helpers/formValidators";
import { useHistory } from 'react-router-dom';

const ForgotPassword = (props) => {

  const history = useHistory();

  const [email, setEmail] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false);


  const resetPassword = () => {
    props.resetPasswordAction(
      email
    )

    setResetEmailSent(true);
  }

  const getResetButton = () => {

    if (!validateEmail(email)) {
      return (
        <Button className="mt-4" color="primary" type="button" disabled
          onClick={() => { }}>
          Send reset link
        </Button>
      )
    } else {
      return (
        <Button className="mt-4" color="primary" type="button"
          onClick={() => resetPassword()}>
          Send reset link
        </Button>
      )
    }

  }


  if (resetEmailSent) {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-white shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>A reset mail was sent, please check your email and follow the link to reset your password.</small>
              </div>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="button"
                  onClick={() => history.push("/auth/login")}>
                  Click here to login
              </Button>
              </div>

            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="/auth/login"
              >
                <small>Login</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/auth/register"
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }


  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Reset password</small>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </InputGroup>
                {validateEmailAddress(email)}
              </FormGroup>
              <div className="text-center">
                {getResetButton()}
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="/auth/login"
            >
              <small>Login</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="/auth/register"
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};



const mapDispatchToProps = dispatch => ({
  resetPasswordAction: (email) => dispatch(resetPasswordAction(email)),
});
export default connect(
  null,
  mapDispatchToProps
)(ForgotPassword);