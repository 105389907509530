const getTextAlignmentClass = (element) => {

  if (element.alignment === "Center")
    return "text-center";
  if (element.alignment === "Right")
    return "text-right";

  return "text-left";
}

let getLabelTextStyle = (element) => {
  var style = element.fontStyle;

  if (style === "Bold") {
    return <b>{element.textValue}</b>
  } else if (style === "Italic") {
    return <i>{element.textValue}</i>
  } else if (style === "BoldItalic") {
    return <b><i>{element.textValue}</i></b>
  }

  return element.textValue;
}

const StaticTextElement = ({ element }) => {
  return (
    <div className={getTextAlignmentClass(element)}>
      <h5 style={{fontSize: element.fontSize + "px", color: element.textColor}} className="editable-label clickable-label">{getLabelTextStyle(element)}</h5>
    </div>
  );
}

export default StaticTextElement