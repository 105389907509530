import { FormGroup, Label, Input } from 'reactstrap';

const getTextAlignmentClass = (element) => {

  if (element.alignment === "Center")
    return "text-center";
  if (element.alignment === "Right")
    return "text-right";

  return "text-left";
}

const getVariableOutputName = (element) => {
  if (element.variableOutputName != undefined && element.variableOutputName !== "") {
    return (
      <h5>Variable output name: <strong>{element.variableOutputName}</strong></h5>
    )
  }

  return null;
}

const getLultiLineTextInput = (element) => {
  if (element.multiLineTextInput) {
    return (
      <h5>Multiline: <strong>Yes</strong></h5>
    )
  }
  return (
    <h5>Multiline: <strong>No</strong></h5>
  )
}

const getIncludePromptInPdf = (element) => {
  if (element.includePromptInPdf) {
    return (
      <h5>Include prompt in PDf: <strong>Yes</strong></h5>
    )
  }
  return (
    <h5>Include prompt in PDf: <strong>No</strong></h5>
  )
}

const geDefaultValue = (element) => {
  if (element.defaultText != undefined && element.defaultText) {
    return (
      element.defaultText
    )
  }

  return "This will be entered in App2pdf app";
}

const getInputType = (element) => {
  if (element.inputType === "text") {
    return (
      <h5>Iput type: <strong>Text</strong></h5>
    )
  } else if (element.inputType === "numeric") {
    return (
      <h5>Iput type: <strong>Numeric</strong></h5>
    )
  } else if (element.inputType === "email") {
    return (
      <h5>Iput type: <strong>Email</strong></h5>
    )
  } else if (element.inputType === "date") {
    return (
      <h5>Iput type: <strong>Date</strong></h5>
    )
  }

  return (
    <h5>Iput type: <strong>Not mapped</strong></h5>
  )
}

const TextInputElement = ({ element }) => {
  return (
    <div className={getTextAlignmentClass(element)}>
      <FormGroup>
        <h5>Prompt: <strong>{element.textValue}</strong></h5>
        {getVariableOutputName(element)}
        {getInputType(element)}
        {getLultiLineTextInput(element)}
        {getIncludePromptInPdf(element)}

        <Input type="textarea" name="text" readOnly="readOnly" style={{ color: 'black' }} placeholder={geDefaultValue(element)} />
      </FormGroup>
    </div>
  );
}

export default TextInputElement