import { v4 as uuidv4 } from 'uuid';

export const createRecursiveElement = (element) => {
    var elementCopy = { ...element };

    if (Array.isArray(element.elements)) {
        elementCopy.elements = element.elements.map(el => {
            return createRecursiveElement(el);
        })

        return {
            id: uuidv4(),
            element: elementCopy
        };

    } else {
        return {
            id: uuidv4(),
            element: elementCopy
        };
    }
}

export const createElements = (elements) => {
    var designTimeElements = elements.map((element, i) => {
        return createRecursiveElement(element);
    });

    return designTimeElements;
}

export const mapRecursiveElement = (control) => {

    var copiedControl = { ...control };

    if (Array.isArray(control.element.elements)) {
        copiedControl.element.elements = copiedControl.element.elements.map((el) => {
            return mapRecursiveElement(el);
        });
        return copiedControl.element;
    } else {
        return copiedControl.element;
    }
}


