
import nonSelectedImagePath from "../../../assets/img/theme/profile-cover.png"

const StaticImageElement = ({ element }) => {
  
  // console.log(element.imagePath);

  if (!element.imagePath)
    element.imagePath = nonSelectedImagePath;


  return (
    <div>
      <img
        alt="..."
        className="img-fluid"
        src={
          element.imagePath
        }
      />
    </div>
  );
}

export default StaticImageElement