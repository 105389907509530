import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody
} from "reactstrap";

const TextInputEditor = ({ textInputElement, updateElement }) => {

    const updateBooleanField = (stateName, value) => {
        if (value === "true") {
            updateElement(stateName, true);
        } else {
            updateElement(stateName, false);
        }
    }

    return (
        <>
            <FormGroup>
                <Label for="textInputEditor">Prompt Text</Label>
                <Input
                    placeholder="Text"
                    type="text"
                    value={textInputElement.textValue}
                    id="textInputEditor"
                    onChange={e => updateElement("textValue", e.target.value)} />

            </FormGroup >

            <FormGroup>
                <Label for="defaultText">Default value</Label>
                <Input
                    placeholder="Eg: Hello or 2023-05-22 or 5"
                    type="text"
                    value={textInputElement.defaultText}
                    id="defaultText"
                    onChange={e => updateElement("defaultText", e.target.value)} />

            </FormGroup >

            <FormGroup>

                <Label for="alignment">Control Alignment</Label>
                <Input
                    type="select"
                    name="alignment"
                    id="alignment"
                    value={textInputElement.alignment}
                    onChange={e => updateElement("alignment", e.target.value)}>
                    <option value="Left">Left</option>
                    <option value="Right">Right</option>
                    <option value="Center">Center</option>
                </Input>
            </FormGroup >
            <FormGroup>

                <Label for="inputType">Input type</Label>
                <Input
                    type="select"
                    name="inputType"
                    id="inputType"
                    value={textInputElement.inputType}
                    onChange={e => updateElement("inputType", e.target.value)}>
                    <option value="text">Text</option>
                    <option value="numeric">Numeric</option>
                    <option value="email">E-mail</option>
                    <option value="date">Date</option>
                </Input>
            </FormGroup >
            <FormGroup>

                <Label for="multiLineTextInput">Multiline text input (overrides Input type)</Label>
                <Input
                    type="select"
                    name="multiLineTextInput"
                    id="multiLineTextInput"
                    value={textInputElement.multiLineTextInput}
                    onChange={e => updateBooleanField("multiLineTextInput", e.target.value)}>
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                </Input>
            </FormGroup >
            <FormGroup>

                <Label for="includePromptInPdf">Include prompt in PDF</Label>
                <Input
                    type="select"
                    name="includePromptInPdf"
                    id="includePromptInPdf"
                    value={textInputElement.includePromptInPdf}
                    onChange={e => updateBooleanField("includePromptInPdf", e.target.value)}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </Input>

            </FormGroup >

            <FormGroup>

                <Label for="variableOutputName">Variable output name (Must be unique)</Label>
                <Input
                    type="text"
                    name="variableOutputName"
                    id="variableOutputName"
                    value={textInputElement.variableOutputName}
                    onChange={e => updateElement("variableOutputName", e.target.value)}>
                </Input>

            </FormGroup >

        </ >

    );
}

export default TextInputEditor;