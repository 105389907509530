import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody
} from "reactstrap";

const CheckBoxEditor = ({ checkBoxElement, updateElement }) => {

    const updateBooleanField = (stateName, value) => {
        if (value === "true") {
            updateElement(stateName, true);
        } else {
            updateElement(stateName, false);
        }
    }

    return (
        <>
            <FormGroup>
                <Label for="titleEditor">Prompt</Label>
                <Input
                    placeholder="Text"
                    type="text"
                    value={checkBoxElement.prompt}
                    id="titleEditor"
                    onChange={e => updateElement("prompt", e.target.value)} />
            </FormGroup >
            <FormGroup>
                <Label for="alignment">Text Alignment</Label>
                <Input
                    type="select"
                    name="alignment"
                    id="alignment"
                    value={checkBoxElement.alignment}
                    onChange={e => updateElement("alignment", e.target.value)}>
                    <option value="Left">Left</option>
                    <option value="Right">Right</option>
                    <option value="Center">Center</option>
                </Input>
            </FormGroup >
            <FormGroup>
                <Label for="defaultValue">Default value</Label>
                <Input
                    type="select"
                    name="defaultValue"
                    id="defaultValue"
                    value={checkBoxElement.defaultValue}
                    onChange={e => updateBooleanField("defaultValue", e.target.value)}>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                </Input>
            </FormGroup >
            <FormGroup>
                <Label for="scriptCheckEditor">Script ( will override default value )</Label>
                <Input
                    placeholder="{true}"
                    type="textarea"
                    value={checkBoxElement.script}
                    id="scriptCheckEditor"
                    onChange={e => updateElement("script", e.target.value)} />
            </FormGroup >
            <FormGroup>
                <Label for="variableEditor">Variable output name (must be unique)</Label>
                <Input
                    placeholder="Checkbox1"
                    type="text"
                    value={checkBoxElement.variableOutputName}
                    name="variableEditor"
                    id="variableEditor"
                    onChange={e => updateElement("variableOutputName", e.target.value)} />
            </FormGroup >
        </>
    );
}

export default CheckBoxEditor;