/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";

import { connect } from "react-redux";

import firebase from "firebase";

import ClipLoader from "react-spinners/ClipLoader";

const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  const [user, setUser] = useState(false)
  const [authWasListened, setAuthWasListened] = useState(false);

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  React.useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(authUser => {
      if (authUser) {
        setUser(true);
        setAuthWasListened(true);
      }
      else {
        setUser(false);
        setAuthWasListened(true);
      }
    })
    return unregisterAuthObserver;

  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //mainContent.current.scrollTop = 0;
  }, [location]);

  if (user)
    return <Redirect to="/admin/my-account" />;


  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  /*if (props.authState.loggedIn) {
    return <Redirect to="/admin/user-profile" />;
  }*/

  if (!authWasListened) {
    return (
      <div style={style}>
        <ClipLoader color={"#123abc"} loading={"Authenticating"} />
      </div>
    );
  }
  if (authWasListened && user) {
    return <Redirect to="/admin/my-account" />;
  }

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <div className="header bg-green py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Welcome to Apptopdf</h1>
                  <p className="text-lead text-light">
                    Login in with an existing account, create a new account or sign in with Apple or Google. It´s free to get started.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

//export default Auth;

const mapStateToProps = state => ({
  ...state
});
export default connect(
  mapStateToProps,
  {}
)(Auth);
