import React, { useState, useEffect } from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table,
    Badge,
    Input,
    FormGroup,
} from "reactstrap";

import ClipLoader from "react-spinners/ClipLoader";
import firebaseApp from "firebaseConfig";


const UserGroups = ({ userId }) => {

    const [avaliableUsers, setAvaliableUsers] = useState([]);

    const [newGroupName, setNewGroupName] = useState();
    const [newGroupDisplayName, setNewGroupDisplayName] = useState();

    const [downloadingCompleted, setdownloadingCompleted] = useState(false);

    const getFirestorePath = () => {
        const user = JSON.parse(localStorage.getItem('user'));

        var path = "Accounts/" + user.uid + "/UserGroups";

        return path;
    }

    //To keep track of the original value to be able to cancel and batch save.
    const [originalUserGroups, setOriginalUserGroups] = useState([]);

    const [userGroups, setUserGroups] = useState([
        {
            id: "1",
            name: "Lorné Mark",
            displayName: "Säljare",
            templates: ["123", "223"],
            users: ["stefan.aberg@outlook.com"]
        },
        {
            id: "2",
            name: "Lorné Mark o",
            displayName: "Dränerare",
            templates: ["123", "223"],
            users: ["stefan.aberg@outlook.com", "svedmanp@gmail.com"]
        }
    ]);

    useEffect(() => {

        setdownloadingCompleted(false);

        const user = JSON.parse(localStorage.getItem('user'));

        const unsubscribe = firebaseApp.firestore().collection("AccountInvites").where("sharedAccountId", "==", user.uid)
            .onSnapshot({
                includeMetadataChanges: true
            },
                snapshot => {

                    const avaliableUsers = snapshot.docs.map(
                        doc => {
                            return doc.data().invitedEmailAddress
                        });;

                    setAvaliableUsers(avaliableUsers);

                    setdownloadingCompleted(true);

                });

        return () => {
            unsubscribe()
        }

    }, [firebaseApp.firestore()]);

    useEffect(() => {

        setdownloadingCompleted(false);

        var path = getFirestorePath();

        const unsubscribe = firebaseApp.firestore().collection(path)
            .onSnapshot({
                includeMetadataChanges: true
            },
                snapshot => {

                    const userGroups = snapshot.docs.map(
                        doc => {
                            let userGroup = {
                                name: doc.data().name,
                                displayName: doc.data().displayName,
                                templates: doc.data().templates,
                                users: doc.data().users,
                                id: doc.id
                            }
                            return userGroup
                        });

                    setUserGroups(userGroups);
                    setOriginalUserGroups(userGroups);
                    setdownloadingCompleted(true);

                });

        return () => {
            unsubscribe()
        }

    }, [firebaseApp.firestore()]);

    useEffect(() => {

        var path = getFirestorePath();

        const unsubscribe = firebaseApp.firestore().collection(path)
            .onSnapshot({
                includeMetadataChanges: true
            },
                snapshot => {

                    const userGroups = snapshot.docs.map(
                        doc => {
                            let userGroup = {
                                name: doc.data().name,
                                displayName: doc.data().displayName,
                                templates: doc.data().templates,
                                users: doc.data().users,
                                id: doc.id
                            }
                            return userGroup
                        });

                    setUserGroups(userGroups);
                    setdownloadingCompleted(true);

                });

        return () => {
            unsubscribe()
        }

    }, [firebaseApp.firestore()]);

    useEffect(() => {

    }, [userGroups], [newGroupName], [newGroupDisplayName])

    let saveGroups = () => {

        setdownloadingCompleted(false);

        // Save groups to firebase 

        // Iterate through what should be rwmoved

        var toDelete = originalUserGroups.filter(function (group) {
            return !userGroups.includes(group);
        });

        if (toDelete.length > 0) {
            deleteUserGroupsFromFirebase(toDelete);
        }

        addOrUpdateFirebaseUserGroups();
    }

    let addOrUpdateFirebaseUserGroups = () => {

        setdownloadingCompleted(false);

        var path = getFirestorePath();

        var batch = firebaseApp.firestore().batch();

        userGroups.map((group) => {
            const docRef = firebaseApp.firestore().collection(path).doc(group.id)
            batch.set(docRef, { name: group.name, displayName: group.displayName, users: group.users, templates: group.templates })
        });

        batch.commit().then(function () {
            setdownloadingCompleted(true);
        });

    }

    let deleteUserGroupsFromFirebase = (groupsToDelete) => {

        setdownloadingCompleted(false);

        var path = getFirestorePath();

        var batch = firebaseApp.firestore().batch();

        // Delete from firebase
        groupsToDelete.map((group) => {
            const docRef = firebaseApp.firestore().collection(path).doc(group.id)
            batch.delete(docRef);
        });

        batch.commit().then(function () {
            setdownloadingCompleted(true);
        });
    }

    let cancel = () => {
        var cancel = window.confirm("Are you sure you want to cancel? This will be irreversable!");

        if (cancel) {
            setUserGroups(originalUserGroups);
        }

    }

    let getSaveAndCancelButton = () => {

        if (originalUserGroups !== userGroups) {
            return (
                <Row>
                    <Col md="12">
                        <Button color="primary" type="button" onClick={() => saveGroups()}>SAVE GROUPS</Button>
                        <Button color="warning" type="button" onClick={() => cancel()}>CANCEL</Button>
                    </Col>
                </Row>
            );
        }

    }

    let isUserInGroup = (group, user) => {
        return group.users.includes(user);
    }

    let toggleUserInGroup = (groupIndex, user, isIncluded) => {

        var copiedGroups = [...userGroups];

        var groupToEdit = copiedGroups[groupIndex];

        if (isIncluded) {
            groupToEdit.users.push(user);
        } else {
            var toRemove = groupToEdit.users.indexOf(user);

            groupToEdit.users.splice(toRemove, 1);
        }

        copiedGroups[groupIndex] = groupToEdit;

        setUserGroups(copiedGroups);
    }

    let addNewGroup = () => {

        var newUserGroupRef = firebaseApp.firestore().collection(getFirestorePath()).doc();

        var newUserGroupList = [
            ...userGroups,
            { id: newUserGroupRef.id, name: newGroupName, displayName: newGroupDisplayName, templates: [], users: [] }
        ];

        setUserGroups(newUserGroupList)

        setNewGroupName("");

        setNewGroupDisplayName("");
    }

    let deleteGroup = (groupIndex) => {

        var copiedGroups = [...userGroups];

        copiedGroups.splice(groupIndex, 1);

        setUserGroups(copiedGroups);
    }

    let getAddGroupButton = () => {
        if (newGroupName === undefined || newGroupName === "" || newGroupDisplayName === undefined || newGroupDisplayName === "") {
            return null;
        }

        return (
            <Button onClick={() => addNewGroup()}>Add Group</Button>
        )
    }

    if (!downloadingCompleted) {
        return (
            <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="12">
                            <h3 className="mb-0">USER GROUPS</h3>
                            <small>When you want to have better control over which templates are shared to which users. If you have at least one group you need to actively share your templates to specific groups for them to see your selected templates.</small>

                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ClipLoader color={"#123abc"} loading={"Authenticating"} />
                </CardBody>
            </Card>
        );
    }

    return (
        <Card className="bg-white shadow">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                    <Col xs="12">
                        <h3 className="mb-0">USER GROUPS</h3>
                        <small>When you want to have better control over which templates are shared to which users. If you have at least one group you need to actively share your templates to specific groups for them to see your selected templates.</small>

                    </Col>
                </Row>
            </CardHeader>
            <CardBody>

                {userGroups.map((group, groupIndex) => {
                    return <>
                        <Row className="align-items-center">
                            <Col md="10">
                                <h3>{group.name} ({group.displayName})</h3>
                            </Col>
                            <Col md="2" className="text-right">
                                <Button onClick={() => deleteGroup(groupIndex)}>Delete</Button>
                            </Col>
                        </Row>
                        <br></br>

                        <Row>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr id="accountInvitesHeaderTr">
                                        <th scope="col">Email</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {avaliableUsers.map((user, index) => (
                                        <tr id={user} key={index}>
                                            <td>{user}</td>
                                            <td className="text-right">
                                                <div className="custom-control custom-checkbox mb-3">
                                                    <input
                                                        className="custom-control-input"
                                                        id={group.id + "." + user}
                                                        checked={isUserInGroup(group, user)}
                                                        onChange={e => toggleUserInGroup(groupIndex, user, e.target.checked)}
                                                        type="checkbox"
                                                    />
                                                    <label className="custom-control-label" htmlFor={group.id + "." + user}>

                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Row>
                        <br></br>
                    </>
                })}

                <Row>
                    <Col md="5">
                        <FormGroup>
                            <Input
                                id="newGroupName"
                                placeholder="New group name"
                                value={newGroupName}
                                onChange={e => setNewGroupName(e.target.value)}
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="5">
                        <FormGroup>
                            <Input
                                id="newGroupDisplayName"
                                placeholder="New group display name"
                                value={newGroupDisplayName}
                                onChange={e => setNewGroupDisplayName(e.target.value)}
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        {getAddGroupButton()}
                    </Col>
                </Row>

                <br></br>
                {getSaveAndCancelButton()}

            </CardBody>
        </Card >
    );
};

export default UserGroups;