import {
    FormGroup,
    Input,
    InputGroup,
    Label,
    ModalBody
} from "reactstrap";

import FrostScriptEditor from "../steps/FrostScriptEditor";

const LinkControlEditor = ({ linkControlElement, updateElement, variableSpace }) => {
    return (
        <>
            <FormGroup>
                <Label for="linkText">Link text</Label>
                <FrostScriptEditor
                    id={"linkText"}
                    variableSpace={variableSpace.getVariables()}
                    boundProperty={linkControlElement.linkText}
                    onChange={e => updateElement("linkText", e)}
                    maxLines={15}
                    includeMethodSnippets={false}
                />
            </FormGroup >
            <FormGroup>
                <Label for="pdfLink">Pdf-Link</Label>
                <FrostScriptEditor
                    id={"pdfLink"}
                    variableSpace={variableSpace.getVariables()}
                    boundProperty={linkControlElement.pdfLink}
                    onChange={e => updateElement("pdfLink", e)}
                    maxLines={15}
                    includeMethodSnippets={false}
                />
            </FormGroup >
            <FormGroup>
                <Label for="iosLink">iOS-Link</Label>
                <FrostScriptEditor
                    id={"iosLink"}
                    variableSpace={variableSpace.getVariables()}
                    boundProperty={linkControlElement.iosLink}
                    onChange={e => updateElement("iosLink", e)}
                    maxLines={15}
                    includeMethodSnippets={false}
                />
            </FormGroup >
            <FormGroup>
                <Label for="androidLink">Android-Link</Label>
                <FrostScriptEditor
                    id={"androidLink"}
                    variableSpace={variableSpace.getVariables()}
                    boundProperty={linkControlElement.androidLink}
                    onChange={e => updateElement("androidLink", e)}
                    maxLines={15}
                    includeMethodSnippets={false}
                />
            </FormGroup >
            <FormGroup>

                <Label for="alignment">Text Alignment</Label>
                <Input
                    type="select"
                    name="alignment"
                    id="alignment"
                    value={linkControlElement.alignment}
                    onChange={e => updateElement("alignment", e.target.value)}>
                    <option value="Left">Left</option>
                    <option value="Right">Right</option>
                    <option value="Center">Center</option>
                </Input>
            </FormGroup >
            <FormGroup>
                <Label for="fontSize">Font Size: <b><i>Standard 9, Title 18</i></b></Label>
                <Input
                    type="select"
                    name="fontSize"
                    id="fontSize"
                    value={linkControlElement.fontSize}
                    onChange={e => updateElement("fontSize", parseInt(e.target.value))}>
                    <option value={7}>7</option>
                    <option value={9}>9</option>
                    <option value={12}>12</option>
                    <option value={16}>16</option>
                    <option value={20}>20</option>
                    <option value={24}>24</option>
                </Input>
            </FormGroup >
            <FormGroup>
                <Label for="fontStyle">Font Style</Label>
                <Input
                    type="select"
                    name="fontStyle"
                    id="fontStyle"
                    value={linkControlElement.fontStyle}
                    onChange={e => updateElement("fontStyle", e.target.value)}>
                    <option value="normal">Normal</option>
                    <option value="Bold">Bold</option>
                    <option value="Italic">Italic</option>
                </Input>
            </FormGroup >
            <FormGroup>
                <Label for="textColor">Text Color</Label>
                <Input
                    type="color"
                    name="textColor"
                    value={linkControlElement.textColor}
                    onChange={e => updateElement("textColor", e.target.value)}
                    id="textColor"
                    placeholder="color placeholder" />
            </FormGroup >
        </ >

    );
}

export default LinkControlEditor;