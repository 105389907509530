import { FormGroup, Label, Input } from 'reactstrap';

const ElementGroup = ({ element }) => {

  let getControlName = (control) => {

    var safeControl = control;
    if (!control.hasOwnProperty("elementType")) {
      safeControl = control.element;
    }

    if (safeControl.elementType === "Title") {
      return "Title element"
    } else if (safeControl.elementType === "StaticText") {
      return "Static text"
    } else if (safeControl.elementType === "TableView") {
      return "Table"
    } else if (safeControl.elementType === "StaticImage") {
      return "Static image"
    } else if (safeControl.elementType === "ImageInput") {
      return "Image input"
    } else if (safeControl.elementType === "TextInput") {
      return "Text input"
    } else if (safeControl.elementType === "PageBreak") {
      return "Page Break"
    } else if (safeControl.elementType === "SignatureInput") {
      return "Signature"
    }
    else if (safeControl.elementType === "ScriptElement") {
      return "Script element"
    }
    else if (safeControl.elementType === "ElementGroup") {
      return "Element group"
    }
    else if (safeControl.elementType === "DropDown") {
      return "Drop down"
    } else if (safeControl.elementType === "CheckBox") {
      return "Check box"
    } else if (safeControl.elementType === "LabeledText") {
      return "Labeled text"
    }
    else if (safeControl.elementType === "TextInfoGroup") {
      return "Text info group"
    }
  }

  let getControlsInGroup = (controls) => {
    if (controls.length === 0) {
      return (
        <h5>NO CONTROLS IN THIS GROUP, CLICK TO ADD</h5>
      );
    }

    return controls.map((control) => {
      return (
        <h5 style={{ fontStyle: "italic" }} className="editable-label clickable-label">{getControlName(control)}</h5>
      );
    });

  }

  let getGroupName = (name) => {
    if (name) {
      return name;
    }

    return "CONTROL GROUP";
  }

  return (
    <div className="text-center">
      <h3 style={{ fontStyle: "italic" }} className="editable-label clickable-label">{getGroupName(element.name)}</h3>
      {getControlsInGroup(element.elements)}
    </div>
  );
}

export default ElementGroup