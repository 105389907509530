import firebase from "firebase";
require('firebase/app')
require('firebase')

var config = {
    apiKey: "AIzaSyDj_BjgQTtzDkqbsEyCwDZ5YU4WPTTilk4",
    authDomain: "apptopdf.firebaseapp.com",
    projectId: "apptopdf",
    storageBucket: "apptopdf.appspot.com",
    messagingSenderId: "305434564258",
    appId: "1:305434564258:web:af9b55e9773efaa1b9e9fb",
    measurementId: "G-P3PW7N1DYK"
};

let firebaseConfig = firebase.initializeApp(config);

export default firebaseConfig;