import pdfFieldMapping from "../pdfFieldMapping";

export default function mapHantverkarForm17Variables (){
        return [
            new pdfFieldMapping(0, "Namn 1", "kundNamn1", "String", false, null, null),
            new pdfFieldMapping(0, "Namn 2", "kundNamn2", "String", false, null, null),
            new pdfFieldMapping(0, "Personnummer 2", "kundPersonNummer1", "String", false, null, null),
            new pdfFieldMapping(0, "Namn 4", "kundPersonNummer2", "String", false, null, null),
            new pdfFieldMapping(0, "Adress beställare", "kundAdress", "String", false, null, null),
            new pdfFieldMapping(0, "Telefonnummer beställare", "kundTelefonNummer", "String", false, null, null),
            new pdfFieldMapping(0, "Epost beställare", "kundEpost", "String", false, null, null),
            new pdfFieldMapping(0, "Namn/firma", "hantverkareFirmaNamn", "String", false, null, null),
            new pdfFieldMapping(0, "Organisations-/personnummer", "hantverkareOrgNummer", "String", false, null, null),
            new pdfFieldMapping(0, "Adress hantverkare", "hantverkareAdress", "String", false, null, null),
            new pdfFieldMapping(0, "Telefonnummer hantverkare", "hantverkareTelefonNummer", "String", false, null, null),
            new pdfFieldMapping(0, "E-post hantverkare", "hantverkareEpost", "String", false, null, null),
            new pdfFieldMapping(0, "Försäkringsbolag", "hantverkareForsakringsbolag", "String", false, null, null),
            new pdfFieldMapping(0, "Adress där arbete ska utföras", "utforandeAdress", "String", false, null, null),
            new pdfFieldMapping(0, "Beskriv tydligt det arbete hantverkaren ska utföra", "arbetsBeskrivning", "String", false, null, null),
            new pdfFieldMapping(0, "Sammanfattning - beskrivning bilaga nr", "arbetsBeskrivningBilagaNr", "String", false, null, null),
            new pdfFieldMapping(0, "Sammanfattning - offert", "offertBilagaNr", "String", false, null, null),
            new pdfFieldMapping(0, "Sammanfattning - ritning", "ritningBilagaNr", "String", false, null, null),
            new pdfFieldMapping(0, "Sammanfattning - övrigt", "ovrigtBilagaNr", "String", false, null, null),
            new pdfFieldMapping(0, "Arbete, material och övrigt som inte ingår", "ejInkluderatArbeteMaterialOvrigt", "String", false, null, null),
            new pdfFieldMapping(0, "Sammanfattning - forts. bilaga nr", "fortsattningEjInkluderatArbeteMaterialOvrigtBilagaNr", "String", false, null, null),
            new pdfFieldMapping(0, "Godkänd för F-skatt", "godkandFSkatt", "Boolean", false, null, null),
            new pdfFieldMapping(0, "Har allrisk- och ansvarsförsäkring", "harAllriskOchAnsvarsforsakring", "Boolean", false, null, null),
            new pdfFieldMapping(0, "beskrivning, bilaga nr", "harBilagaBeskrivning", "Boolean", false, null, null),
            new pdfFieldMapping(0, "offert, bilaga nr", "harBilagaOffert", "Boolean", false, null, null),
            new pdfFieldMapping(0, "ritning, bilaga nr", "harBilagaRitning", "Boolean", false, null, null),
            new pdfFieldMapping(0, "övrigt, bilaga nr", "harBilagaOvrigt", "Boolean", false, null, null),
            new pdfFieldMapping(0, "Arbete, material och övrigt som inte ingår - forts. bilaga nr", "harBilagaEjInkluderatArbeteMaterialOvrigt", "Boolean", false, null, null),

            new pdfFieldMapping(1, "Arbete som beställaren vill ha utfört trots att hantverkaren har avrått", "arbeteTrotsAvradan", "String", false, null, null),
            new pdfFieldMapping(1, "forts. bilaga nr", "arbeteTrotsAvradanBilagaNr", "String", false, null, null),
            new pdfFieldMapping(1, "Orsak till avrådande", "orsakAvradan", "String", false, null, null),
            new pdfFieldMapping(1, "Orsak - forts. bilaga nr", "orsakAvradanBilagaNr", "String", false, null, null),
            new pdfFieldMapping(1, "Fast pris - Arbete, material och resor  (kr inklusive moms)", "fastPrisArbeteMaterialResor", "String", false, null, null),
            new pdfFieldMapping(1, "Fast pris - Arbete och material (kr inklusive moms)", "fastPrisArbeteMaterial", "String", false, null, null),
            new pdfFieldMapping(1, "Fast pris - Arbete (kr inklusive moms)", "fastPrisArbete", "String", false, null, null),
            new pdfFieldMapping(1, "Fast pris - Material (kr inklusive moms)", "fastPrisMaterial", "String", false, null, null),
            new pdfFieldMapping(1, "Löpande räkning - Arbete  (kr/tim inklusive entreprenörarvode och moms)", "lopandePrisArbete", "String", false, null, null),
            new pdfFieldMapping(1, "Löpande räkning - Arbetsledning   (kr/tim inklusive entreprenörarvode och moms)", "lopandePrisArbetsledning", "String", false, null, null),
            new pdfFieldMapping(1, "Löpande räkning - Överenskomna underentreprenader debiteras med (%. Moms tillkommer)", "underEntreprenadTillaggProcent", "String", false, null, null),
            new pdfFieldMapping(1, "Löpande räkning - Material, varor, hjälpmedel och övriga kostnader debiteras (%. Moms tillkommer)", "materialVarorHjalpmedelTillaggProcent", "String", false, null, null),
            new pdfFieldMapping(1, "Ungefärlig prisuppgift", "uppskattadPrisuppgift", "String", false, null, null),
            new pdfFieldMapping(1, "Resor", "resorDebitering", "String", false, null, null),
            new pdfFieldMapping(1, "Fastighets-/bostadsrätts-/ägarlägenhetsbeteckning", "fastighetBostadsrattAgarlagenhetsBeteckning", "String", false, null, null),
            new pdfFieldMapping(1, "Bostadsrättsföreningens organisationsnummer", "bostadsrattOrgNummer", "String", false, null, null),
            new pdfFieldMapping(1, "Vilken/vilka beställare ska ROT-avdrag sökas för", "bestallareForROT", "String", false, null, null),
            new pdfFieldMapping(1, "Arbete, enligt punkt B, ska påbörjas den ", "startDatumArbete", "String", false, null, null),
            new pdfFieldMapping(1, "och ska senast vara klart den", "slutDatumArbete", "String", false, null, null),
            new pdfFieldMapping(1, "Arbete som beställaren vill ha utfört trots att hantverkaren har avrått - forts. bilaga nr", "harBilagaArbeteTrotsAvradan", "Boolean", false, null, null),
            new pdfFieldMapping(1, "Orsak till avrådande - forts. bilaga nr", "harBilagaOrsakAvradan", "Boolean", false, null, null),
            new pdfFieldMapping(1, "ROT-avdrag - Ja", "medROTAvdrag", "Boolean", false, null, null),
            new pdfFieldMapping(1, "ROT-avdrag - Nej", "ingetROTAvdrag", "Boolean", false, null, null),

            new pdfFieldMapping(2, "Ändringar och tilläggsarbeten - Arbete", "lopandePrisAndringTillagsArbete", "String", false, null, null),
            new pdfFieldMapping(2, "Ändringar och tilläggsarbeten - Arbetsledning", "lopandePrisAndringTillagsArbetsledning", "String", false, null, null),
            new pdfFieldMapping(2, "Ändringar och tilläggsarbeten - Entreprenörarvode %", "andringUnderEntreprenadTillaggProcent", "String", false, null, null),
            new pdfFieldMapping(2, "Ändringar och tilläggsarbeten - Tillägg entreprenörarvode %", "andringMaterialVarorHjalpmedelTillaggProcent", "String", false, null, null),
            new pdfFieldMapping(2, "Ändringar och tilläggsarbeten - Resor", "andringResorDebitering", "String", false, null, null),
            new pdfFieldMapping(2, "Betalning - Se bilaga nr", "betalningBilagaNr", "String", false, null, null),
            new pdfFieldMapping(2, "Övriga överenskommelser", "ovrigaOverenskommelser", "String", false, null, null),
            new pdfFieldMapping(2, "Ort och datum - Firma 2", "hantverkareOrtDatum", "String", false, null, null),
            new pdfFieldMapping(2, "Firma 2", "hantverkareFirmaNamn2", "String", false, null, null),
            new pdfFieldMapping(2, "Namnförtydligande - Hantverkare (näringsidkare) 2", "hantverkareNamnFortydligande", "String", false, null, null),
            new pdfFieldMapping(2, "Ort och datum - Beställare 2", "kundOrtDatum", "String", false, null, null),
            new pdfFieldMapping(2, "Namnförtydligande - Beställare 3", "kundNamnFortydligande1", "String", false, null, null),
            new pdfFieldMapping(2, "Namnförtydligande - Beställare 4", "kundNamnFortydligande2", "String", false, null, null),
            new pdfFieldMapping(2, "Betalning - Ändringar- och tilläggsarbeten", "andringarSkallBetalasLopande", "Boolean", false, null, null),
            new pdfFieldMapping(2, "Hela beloppet betalas när arbetet är utfört", "helaBeloppetBetalasVidSlutfortArbete", "Boolean", false, null, null),
            new pdfFieldMapping(2, "Sker enligt betalningsplan", "betalningEnligtPlan", "Boolean", false, null, null),
            new pdfFieldMapping(2, "Specificerad faktura", "specificeradFaktura", "Boolean", false, null, null),

            new pdfFieldMapping(2, "Signatur kund 1", "signaturKund1", "Signature", false, null, null),
            new pdfFieldMapping(2, "Signatur kund 2", "signaturKund2", "Signature", false, null, null),
            new pdfFieldMapping(2, "Signatur firma", "signaturFirma", "Signature", false, null, null),

        ];
    }


