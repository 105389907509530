import firebaseConfig from "firebaseConfig";
import firebase from "firebase";



function setUser(user){

  let googleUser = {
    displayName: user.user.displayName,
    email: user.user.email,
    uid: user.user.uid,
    photoUrl: user.user.photoURL
  }
  localStorage.setItem('user', JSON.stringify(googleUser));
}

export const loginWithUserNameAndPassowrd = (email, password) => async dispatch => {
  firebaseConfig
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(function(user) {

      setUser(user);

      dispatch({ type: "login", payload: "true" });
    })
    .catch(function(error) {
      alert(error);
    });
};

export const loginWithGoogle = () => async dispatch => {
  var provider = new firebase.auth.GoogleAuthProvider();
  /*firebase.auth().signInWithRedirect(provider);*/
  firebaseConfig.auth().signInWithPopup(provider)
  .then(function(user) {

    setUser(user);

    dispatch({ type: "login", payload: "true" });
  })
  .catch(function(error) {
    alert(error);
  });
};

export const loginWithApple = () => async dispatch => {
  var provider = new firebase.auth.OAuthProvider('apple.com');
  /*firebase.auth().signInWithRedirect(provider);*/
  firebaseConfig.auth().signInWithPopup(provider)
  .then(function(user) {

    setUser(user);

    dispatch({ type: "login", payload: "true" });
  })
  .catch(function(error) {
    alert(error);
  });
};

export const logOut = () => async dispatch => {
  firebaseConfig
    .auth()
    .signOut()
    .then(() => {
      localStorage.setItem('user', null); 
    })
    .catch(function(error) {
      alert(error);
    });
};