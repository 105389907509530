import firebaseConfig from "firebaseConfig";
import firebase from "firebase";

export const registerAction = (email, password) => async dispatch => {
  firebaseConfig
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(function (result) {
        dispatch({ type: "register", payload: true });
    })
    .catch(function (error) {
      alert(error);
    });
};

export const loginWithGoogle = () => {
  var provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithRedirect(provider);
  /*firebaseConfig.auth().signInWithPopup(provider)
    .then(function (user) {
      dispatch({ type: "login", payload: "true" });
    })
    .catch(function (error) {
      alert(error);
    });*/
};

export const loginWithApple = () => {
  var provider = new firebase.auth.OAuthProvider('apple.com');
  firebase.auth().signInWithRedirect(provider);
  /*firebaseConfig.auth().signInWithPopup(provider)
    .then(function (user) {
      dispatch({ type: "login", payload: "true" });
    })
    .catch(function (error) {
      alert(error);
    });*/
};
