const ImageInputElement = ({ element }) => {

    const getColSpan = (imagePerRow) => {
        if (parseInt(imagePerRow) === 1)
            return "col-lg-12";
        else if (parseInt(imagePerRow) === 2)
            return "col-lg-6";
        else if (parseInt(imagePerRow) === 3)
            return "col-lg-4";
        else if (parseInt(imagePerRow) === 4)
            return "col-lg-3";
    }

    const getImage = (index) => {
        return (
            <div key={"imageInput-" + index} className={getColSpan(getImagePerRow())}>
                <img
                    style={{ padding: "20px" }}
                    alt="..."
                    className="img-fluid"
                    src={
                        require("../../../assets/img/elements-icons/image_input.svg")
                            .default
                    }
                />
            </div>

        )
    }

    const getImages = () => {

        var imageColumns = [];

        var imagesPerRow = getImagePerRow();

        for (var index = 0; index < imagesPerRow; index++) {
            imageColumns.push(getImage(index))
        }

        return imageColumns;
    }

    const isSingleImageMode = () => {
        if(element.behavior === "singleImage"){
            return true;
        }

        return false;
    }

    const getImagePerRow = () => {
        var imagesPerRow = element.imagesPerRow;

        if(isSingleImageMode()){
            imagesPerRow = 1;
        }

        return imagesPerRow;
    }

    const getModeText = () => {
        if(isSingleImageMode()) {
            return "Single image";
        }

        return "Multiple images";
    }

    const imageColumns = getImages();


    const getVariableOutputName = (element) => {
        if (element.variableOutputName != undefined && element.variableOutputName !== "") {
            return (
                <h5>Variable output name: <strong>{element.variableOutputName}</strong></h5>
            )
        }

        return null;
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <h5>Prompt: <strong>{element.textValue}</strong></h5>
                    <h5>Mode: <strong>{getModeText()}</strong></h5>
                    {getVariableOutputName(element)}
                </div>

                {imageColumns}

            </div>
        </>
    );
}

export default ImageInputElement