/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  CardBody,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  ButtonGroup,
  UncontrolledTooltip,
  Input
} from "reactstrap";

import { useHistory, useRouteMatch, Route, Switch } from 'react-router-dom';

import ShareTemplateModal from "components/Templates/shareTemplate/shareTemplateModal.js";

import ImportTemplateModal from "components/Templates/importTemplates/importTemplateModal.js";

import ImportDefaultTemplatesModal from "components/Templates/importTemplates/importDefaultTemplatesModal.js"

// core components
import Header from "components/Headers/Header.js";

import firebaseApp from "firebaseConfig";

import ClipLoader from "react-spinners/ClipLoader";

import TemplataeDesigner from "./TemplateDesigner"

const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

const Templates = () => {

  const history = useHistory();

  const [templates, setTemplates] = useState([]);

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [downloadingCompleted, setdownloadingCompleted] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));

  const [modalOpen, setModalOpen] = useState(false);
  const toggle = () => setModalOpen(!modalOpen);

  const [importModalOpen, setImportModalOpen] = useState(false);
  const toggleImportModal = () => setImportModalOpen(!importModalOpen);

  const [importDefaultTemplateModalOpen, setImportDefaultTemplateModalOpen] = useState(false);
  const toggleImportDefaultTemplateModal = () => setImportDefaultTemplateModalOpen(!importDefaultTemplateModalOpen);

  const [currentInviteTemplateId, setCurrentInviteTemplateId] = useState({ templateName: "Loading...", templateId: "" });

  var path = "Accounts/" + user.uid + "/Templates";

  let match = useRouteMatch();

  useEffect(() => {

    const unsubscribe = firebaseApp.firestore()
      .collection(path)
      .orderBy('lastUpdatedAt', 'desc')
 
      .limit(5)
      .onSnapshot({
        includeMetadataChanges: true
      },
        snapshot => {

          const data = snapshot.docs.map(
            doc => {
              let template = {
                data: doc.data(),
                id: doc.id
              }
              return template
            });

          //console.log(data); // array of templates
          setTemplates(data);
          setdownloadingCompleted(true);

        });

    return () => {
      unsubscribe()
    }

  }, [firebaseApp.firestore(), searchTerm])

  const addTemplateButton = () => {
    return (
      <Button className="my-4" color="primary" type="button" onClick={() => history.push('/admin/templates/new')}>
        Add new
      </Button>
    );
  }

  const addImportButton = () => {
    return (
      <Button className="my-4" color="primary" type="button" onClick={() => toggleImportModal()}>
        Import template
      </Button>
    );
  }

  const addImportDefaultTemplateButton = () => {
    return (
      <Button className="my-4" color="primary" type="button" onClick={() => toggleImportDefaultTemplateModal()}>
        Import default templates
      </Button>
    );
  }

  let getExportTemplateButton = (template) => {
    if (user.email === "stefan.aberg84@gmail.com" || user.email === "svedmanp@gmail.com" || user.email === "dj@jagefeldt.com") {
      return (
        <DropdownItem
          onClick={((e) => exportTemplate(template))}
        >
          Export template
        </DropdownItem>
      );
    }

    return null;
  }

  let saveAsDefaultTemplate = (template, templateId) => {

    if (!window.confirm("Are you sure you want to save: " + template.templateName + " as default template?")) {
      return;
    }

    setdownloadingCompleted(false);

    firebaseApp.firestore().collection("defaultTemplates")
      .doc(templateId)
      .set(template)
      .then(function () {

        setdownloadingCompleted(true);

      }).catch((error) => {

        setdownloadingCompleted(true);

        alert(error);

      });
  }

  let getSaveAsDefaultTemplateButton = (template, templateId) => {
    if (user.email === "stefan.aberg84@gmail.com" ||
      user.email === "svedmanp@gmail.com" ||
      user.email === "dj@jagefeldt.com") {
      return (
        <DropdownItem
          onClick={((e) => saveAsDefaultTemplate(template, templateId))}
        >
          Save as default template
        </DropdownItem>
      );
    }

    return null;
  }

  const deleteTemplate = (index) => {

    var template = templates[index];

    if (window.confirm("Are you sure you want to delete " + template.data.templateName + "?")) {
      setdownloadingCompleted(false);

      firebaseApp.firestore().collection(path).doc(template.id).delete().then(() => {
        //templates.splice(index);
        //setTemplates(templates);
        //setdownloadingCompleted(true);
      });
    }
  }

  const editInvitesForTemplate = (templatename, templateId) => {
    setCurrentInviteTemplateId({ templateName: templatename, templateId: templateId });
    toggle();
  }

  if (!downloadingCompleted) {
    return (
      <>
        <Header />
        <Container className="mt--7 bg-secondary" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <div style={style}>
                <ClipLoader color={"#123abc"} loading={"Authenticating"} />
              </div>
            </div>
          </Row>

        </Container>
      </>
    );
  }

  const showNext = ({ item }) => {
    if (templates.length === 0) {
      alert("Thats all we have for now !")
    } else {
      const fetchNextData = async () => {
        await firebaseApp.firestore().collection(path)
          .orderBy('lastUpdatedAt', 'desc')
          .limit(5)
          .startAfter(item.data.lastUpdatedAt)
          .onSnapshot(function (querySnapshot) {
            const items = [];

            querySnapshot.forEach(function (doc) {
              let template = {
                data: doc.data(),
                id: doc.id
              }
              items.push(template);
            });

            setTemplates(items);
            setPage(page + 1)
          })
      };
      fetchNextData();
    }
  };

  const showPrevious = ({ item }) => {
    const fetchPreviousData = async () => {
      await firebaseApp.firestore().collection(path)
        .orderBy('lastUpdatedAt', 'desc')
        .endBefore(item.data.lastUpdatedAt)
        .limitToLast(5)
        .onSnapshot(function (querySnapshot) {

          const items = [];

          querySnapshot.forEach(function (doc) {
            let template = {
              data: doc.data(),
              id: doc.id
            }
            items.push(template);
          });

          setTemplates(items);
          setPage(page - 1)
        })
    };
    fetchPreviousData();
  };

  const searchTemplates = (searcTerm) => {
    setSearchTerm(searcTerm);
  }

  const getDate = (date) => {
    if (date) {
      return new Date(date).toISOString().substring(0, 10);
    }

    return new Date().toISOString().substring(0, 10);
  }

  const importTemplate = (template, originalName) => {

    if (window.confirm("Are you sure you want to import: " + originalName + " as: " + template.templateName + "?")) {

      setdownloadingCompleted(false);

      firebaseApp.firestore().collection(path).add(template)
        .then((docRef) => {

          setdownloadingCompleted(true);

        }).catch((error) => {

          setdownloadingCompleted(true);

          alert(error);

        });

      toggleImportModal();
    }
  }

  const exportTemplate = (template) => {
    const blob = new Blob([JSON.stringify(template.data)], { type: "json" })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = template.data.templateName + ".apptopdf";
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const getTemplates = () => {
    if (templates.length === 0)
      return (
        <CardBody>
          <h3>No data to show, click "Add new" or import a template to get started.</h3>
        </CardBody>
      )

    return (
      <Table className="align-items-center table-flush" responsive>

        <thead className="thead-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Created at</th>
            <th scope="col">Last updated at</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>

          {templates.map((template, index) => (
            <tr
              key={template.id}
            >
              <th scope="row"
                onClick={() => history.push('/admin/templates/' + template.id)}
                style={{ cursor: "pointer" }}>
                <Media className="align-items-center">
                  <a
                    //className="avatar rounded-circle mr-3"
                    href="/edit/"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/mainIcons/icons8-pdf-100.png")
                          .default
                      }
                    />
                  </a>
                  <Media>
                    <span className="mb-0 text-sm">
                      {template.data.templateName}
                    </span>
                  </Media>
                </Media>
              </th>
              <td onClick={() => history.push('/admin/templates/' + template.id)}
                style={{ cursor: "pointer" }}>
                {getDate(template.data.createdAt)}
              </td>
              <td onClick={() => history.push('/admin/templates/' + template.id)}
                style={{ cursor: "pointer" }}>
                {getDate(template.data.lastUpdatedAt)}</td>
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      onClick={((e) => editInvitesForTemplate(template.data.templateName, template.id))}
                    >
                      Share template
                    </DropdownItem>
                    {getExportTemplateButton(template)}
                    {getSaveAsDefaultTemplateButton(template.data, template.id)}
                    <DropdownItem
                      onClick={() => deleteTemplate(index)}
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}

        </tbody>

      </Table>

    )
  }

  return (

    <Switch>
      <Route path={`${match.path}/:templateId`}>
        <TemplataeDesigner />
      </Route>
      <Route path={match.path}>
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--7 bg-secondary" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">PDF Templates</h3>
                    {addTemplateButton()}
                    {addImportButton()}
                    {addImportDefaultTemplateButton()}
  
                  </CardHeader>

                  {getTemplates()}

                  <br></br>

                  <ButtonGroup>
                    {
                      //show previous button only when we have items
                      page === 1 ? '' :
                        <Button variant="primary" size="lg" onClick={() => showPrevious({ item: templates[0] })}>Previous</Button>
                    }

                    {
                      //show next button only when we have items
                      templates.length < 5 ? '' :
                        <Button variant="primary" size="lg" onClick={() => showNext({ item: templates[templates.length - 1] })}>Next</Button>
                    }
                  </ButtonGroup>

                </Card>
              </div>
            </Row>
          </Container>

        </>

        <ShareTemplateModal
          isOpen={modalOpen}
          toggle={toggle}
          template={currentInviteTemplateId}
          user={user}>

        </ShareTemplateModal>

        <ImportTemplateModal
          isOpen={importModalOpen}
          toggle={toggleImportModal}
          addTemplate={importTemplate}
        />

        <ImportDefaultTemplatesModal
          isOpen={importDefaultTemplateModalOpen}
          toggle={toggleImportDefaultTemplateModal}
        />

      </Route>
    </Switch>
  );
};



export default Templates;
