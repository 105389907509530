import React, { useEffect, useState } from 'react';
import { ReactMediaLibrary } from 'react-media-library';
import { Button } from "reactstrap";
import firebaseApp from "firebaseConfig";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const ReactMediaLibraryWrapper = ({imageSelected, isVisible, onHidden}) => {

    //const [display, setDisplay] = useState(isVisible);
    const [fileLibraryList, setFileLibraryList] = useState([]);
    const [haveDownloadedData, setHaveDownloadedData] = useState(false);

    const loadingCss = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `

    const loadImages = async () => {
        console.log("Listing all files in gallery");

        var imageList = await ListFiles();
        setFileLibraryList(imageList);

        setHaveDownloadedData(true);
    }

    useEffect(() => {
        if (!haveDownloadedData && isVisible) {
            loadImages();
        }
    }, [isVisible]);

    const getFirebaseUserStoragePath = () => {
        var storageRef = firebaseApp.storage().ref();

        const user = JSON.parse(localStorage.getItem('user'));

        var listRef = storageRef.child("templateImages").child(user.uid);

        return listRef;
    }

    const getFileModel = async (item) => {

        var downloadUrl = await item.getDownloadURL();

        var metadata = await item.getMetadata();

        return {
            "_id": metadata.name,
            "size": metadata.size,
            "fileName": metadata.name,
            "createdAt": new Date(metadata.timeCreated),
            "thumbnailUrl": downloadUrl,
        };
    }

    async function ListFiles() {
        // Reference Firebase Container
        var filesInStorageList = [];

        var listRef = getFirebaseUserStoragePath();

        console.log("Listing Files");

        // List all files in container
        try {
            const res = await listRef.listAll();

            for (const imageItem of res.items) {

                var imageModel = await getFileModel(imageItem);

                filesInStorageList.push(imageModel)
            }

            //...do your forEach loops
            return filesInStorageList;
        } catch (err) {
            //handle your error
        }
    }

    async function uploadTaskPromise(fileBase64, fileMeta) {
        return new Promise(function (resolve, reject) {

            var metadata = {
                contentType: fileMeta.type,
            };

            var imageRef = getFirebaseUserStoragePath().child(fileMeta.fileName);

            imageRef.getDownloadURL()
                .then(url => {
                    alert("The file you selected allready exist. If you want to replace it, delete the old one and upload the new you want. Just make sure to resync your templates.");
                    reject();
                })
                .catch(error => {
                    const uploadTask = imageRef.putString(fileBase64, 'data_url');

                    uploadTask.on('state_changed',
                        function (snapshot) {
                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            console.log('Upload is ' + progress + '% done')
                        },
                        function error(err) {
                            alert(err);
                            reject();
                        },
                        function complete() {
                            resolve(imageRef)
                        }
                    )
                });

        })
    }

    async function uploadCallback(fileBase64, fileMeta) {
        try {

            if (!fileMeta.fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
                alert('You can only upload images, please select another file and try again.');
                return;
            }

            var fileSizeInMb = fileMeta.size / 1024 / 1024;
            if(fileSizeInMb > 1){
                alert("We the free version of AppToPdf you can only upload images smaller than 1mb");
                return;
            }
            debugger;
            console.log("Uploading file: " + JSON.stringify(fileMeta));

            var newFile = await uploadTaskPromise(fileBase64, fileMeta);

            var imageModel = await getFileModel(newFile);

            var images = [...fileLibraryList];

            images.unshift(imageModel);

            setFileLibraryList(images);

            return true;
        } catch (error) {
            return false;
        }
    }

    function selectCallback(item) {
        // Hide modal
        //setDisplay(false);
        onHidden();
        imageSelected(item);
    }

    async function deleteFromFirebase(filePath) {
        return new Promise(function (resolve, reject) {
            var imageRef = getFirebaseUserStoragePath().child(filePath);

            // Delete the file
            imageRef.delete().then(() => {
                // File deleted successfully
                resolve();
            }).catch((error) => {
                // Uh-oh, an error occurred!
                reject();
            });

        })
    }

    async function deleteCallback(item) {
        if (window.confirm("Are you sure you wan´t to delete: " + item.fileName + "? If you use it it any template it won´t be displayed in the PDF result.")) {
            try {
                await deleteFromFirebase(item.fileName);

                var filteredArray = fileLibraryList.filter(function (e) { return e !== item })

                setFileLibraryList(filteredArray);

                return true;
            } catch (error) {
                return false;
            }
        }
    }

    if (!isVisible) {
        return null; //<Button className="my-4" color="primary" type="button" onClick={() => setDisplay(true)}>{buttonText}</Button>;
    } else if (!haveDownloadedData) {
         return  null;
    } else {
        return (
            <React.Fragment>
                <ReactMediaLibrary
                    show={isVisible}
                    onHide={() => onHidden()}
                    fileUploadCallback={uploadCallback}
                    fileLibraryList={fileLibraryList}
                    fileSelectCallback={selectCallback}
                    fileDeleteCallback={deleteCallback}
                />
            </React.Fragment>
        );
    }


};

export default ReactMediaLibraryWrapper;