const getTextAlignmentClass = (element) => {

  if (element.alignment === "Center")
      return "text-center";
  if (element.alignment === "Right")
      return "text-right";

  return "text-left";
}

let getTextStyle = (element) => {
  var style = element.fontStyle;

  if (style === "Bold") {
    return <b>{element.textValue}</b>
  } else if (style === "Italic") {
    return <i>{element.textValue}</i>
  } else if (style === "BoldItalic") {
    return <b><i>{element.textValue}</i></b>
  }

  return element.textValue;
}

const LabeledTextElement = ({element}) => {
    return (
        <div className={getTextAlignmentClass(element)}>
          <h6 style={{fontSize: (element.fontSize - 2) + "px", color: element.textColor}} className="editable-label clickable-label">{element.label}</h6>
          <h5 style={{fontSize: element.fontSize + "px", color: element.textColor}} className="editable-label clickable-label">{getTextStyle(element)}</h5>
        </div>
    );
}

export default LabeledTextElement